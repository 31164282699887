/** @format */

/** @format */

import React, { useState } from "react";
import {
  Row,
  Col,
  List,
  Input,
  Form,
  Button,
  Tooltip,
  Popconfirm,
  Typography,
  Spin,
} from "antd";

import { toast } from "react-toastify";
import { EditOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  useCreateStatusMutation,
  //useDeleteStatusMutation,
  useGetStatusQuery,
  useUpdateStatusMutation,
} from "../../../../redux/services/status/statusApi";
import formValidations from "../../../../utils/formValidations";

//default statuses are also used at backend
let defaultStatuses = [
  { _id: "open", name: "open" },
  { _id: "closed", name: "closed" },
  { _id: "in progress", name: "in progress" },
  { _id: "resolved", name: "resolved" },
];
const Status = () => {
  const [form] = Form.useForm();
  const { data: allStatus, isSuccess: isGetAllStatusSuccess ,isLoading:isLoadingAllStatus} =
    useGetStatusQuery();

  const [createStatusApi] = useCreateStatusMutation();
  const [updateStatusApi] = useUpdateStatusMutation();
  // const [deleteStatusApi] = useDeleteStatusMutation();

  const [updateButton, setUpdateButton] = useState({});
  const [editButton, setEditButton] = useState({});
  const [openUpdatePopConfirm, setOpenUpdatePopConfirm] = useState(false);
  // const [openDeletePopConfirm, setOpenDeletePopConfirm] = useState({});

  const [editedStatusName, setEditedStatusName] = useState(null);

  const createStatus = async (values) => {
    let test = values.name;
    console.log("create", test.trim());

    try {
      if (
        defaultStatuses.find((value) => {
          return value.name.toLowerCase() === values.name.toLowerCase();
        })
      ) {
        form.resetFields();
        return toast.error("Status Name already exists", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
      const response = await toast.promise(
        createStatusApi({
          ...values,
        }).unwrap(),
        {
          pending: { render: "Please wait...", autoClose: 2000 },
          success: {
            render: "Status Created SuccessFully",
            autoClose: 3000,
          },
          error: {
            render({ data }) {
              if (data?.data?.msg) {
                return data.data.msg;
              } else {
                return "some error occurred";
              }
            },
            autoClose: 5000,
          },
        }
      );
      if (response) {
        form.resetFields();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const editStatusButton = async (rowKey) => {
    setEditedStatusName(null);
    setOpenUpdatePopConfirm(false);
    setUpdateButton({
      key: rowKey,
      visible: true,
    });
    setEditButton({
      key: rowKey,
      visible: false,
    });
  };
  const statusNameValidationChecks = (status) => {
    let testValue = new RegExp(
      /^[^~`!@#$%^&*()_+=[\]{}\\|;:'",.<>?]*[a-zA-Z0-9\s]+$/
    );
    if (editedStatusName.name.length <= 2) {
      setOpenUpdatePopConfirm(false);
      return toast.error("Name must be greater than 2 characters", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    if (!testValue.test(editedStatusName.name)) {
      setOpenUpdatePopConfirm(false);
      return toast.error("Status name should not contain special Characters", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    if (status.name.toLowerCase() === editedStatusName.name.toLowerCase()) {
      setOpenUpdatePopConfirm(false);
      return toast.error("Choose different name to update", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }

    if (
      allStatus.find((value) => {
        return value.name.toLowerCase() === editedStatusName.name.toLowerCase();
      })
    ) {
      setOpenUpdatePopConfirm(false);
      return toast.error("Status Name already exists", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    return setOpenUpdatePopConfirm(true);
  };
  const updateStatus = async (status) => {
    try {
      const response = await toast.promise(
        updateStatusApi({
          name: editedStatusName.name,
          _id: status._id,
        }).unwrap(),
        {
          //pending: { render: "Please wait...", autoClose: 2000 },
          success: {
            render: "status Updated SuccessFully",
            autoClose: 3000,
          },
          error: {
            render({ data }) {
              if (data?.data?.msg) {
                return data.data.msg;
              } else {
                return "some error occurred";
              }
            },
            autoClose: 5000,
          },
        }
      );

      if (response) {
        setOpenUpdatePopConfirm(false);
        closeUpdateOptions();
      }
    } catch (error) {
      console.log("error", error);
    }
    closeUpdateOptions();
  };
  //! Not being used as of now
  // const deleteStatus = async (status) => {
  //   try {
  //     const response = await toast.promise(
  //       deleteStatusApi({
  //         _id: status._id,
  //       }).unwrap(),
  //       {
  //         //pending: { render: "Please wait...", autoClose: 2000 },
  //         success: {
  //           render: "status Updated SuccessFully",
  //           autoClose: 3000,
  //         },
  //         error: {
  //           render({ data }) {
  //             if (data?.data?.msg) {
  //               return data.data.msg;
  //             } else {
  //               return "some error occurred";
  //             }
  //           },
  //           autoClose: 5000,
  //         },
  //       }
  //     );
  //     console.log("response", response);
  //     if (response) {
  //       setOpenDeletePopConfirm({});
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const onFinishFailed = (errorInfo, error) => {
    toast.error(errorInfo?.errorFields[0]?.errors[0], {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };
  const closeUpdateOptions = () => {
    setUpdateButton({});
    setEditButton({});
    setEditedStatusName(null);
  };

  if (isLoadingAllStatus)
  { return <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
      width: "100%",
    }}
  >
    <Spin size="default"/>
  </div>}
  else
    return (
      <>
        <Row className="capitalizeText" gutter={16}>
          <Col
            style={{ marginBottom: "20px" }}
            // span={8}
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 10 }}
            lg={{ span: 8 }}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <div>
                  <List
                    header={
                      <div style={{ fontWeight: "bolder" }}>Create Status</div>
                    }
                    onClick={closeUpdateOptions}
                    bordered>
                    <Form
                      form={form}
                      autoComplete="off"
                      onFinish={createStatus}
                      onFinishFailed={onFinishFailed}>
                      <List.Item>
                        <Form.Item
                          style={{ marginBottom: "0px" }}
                          name="name"
                          label="Status Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Status name",
                            },
                            {
                              pattern: new RegExp(
                                /^[^~`!@#$%^&*()_+=[\]{}\\|;:'",.<>?]*[a-zA-Z0-9\s]+$/
                              ),
                              message:
                                "-Should Not contain special Characters ",
                            },
                            {
                              max: 30,
                              message: "-Status Name can contain 30 Characters",
                            },
                            {
                              whitespace: true,
                              message: "enter characters",
                            },
                            {
                          validator: formValidations.checkForDuplicateSpaces
                            ,
                            },
                          ]}>
                          <Input
                            placeholder="enter Status name"
                            maxLength={35}
                          />
                        </Form.Item>
                      </List.Item>

                      <List.Item>
                        <Form.Item style={{ marginBottom: "0px" }}>
                          <Button type="primary" htmlType="submit">
                            Create
                          </Button>
                        </Form.Item>
                      </List.Item>
                    </Form>
                  </List>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            //span={6}
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
            xxl={{ span: 6 }}>
            <div>
              <List
                header={
                  <Row>
                    <Col
                      xs={{ span: 20 }}
                      sm={{ span: 14 }}
                      md={{ span: 16 }}
                      lg={{ span: 16 }}
                      xl={{ span: 18 }}
                      xxl={{ span: 22 }}>
                      <div style={{ fontWeight: "bolder" }}>Edit Status</div>
                    </Col>
                    <Col
                      xs={{ span: 4 }}
                      sm={{ span: 10 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 2 }}>
                      {updateButton.visible && (
                        <Typography.Link onClick={closeUpdateOptions}>
                          Clear
                        </Typography.Link>
                      )}
                    </Col>
                  </Row>
                }
                dataSource={[...allStatus]}
                renderItem={(status, rowKey) => (
                  <List.Item style={{ width: "100%" }}>
                    <Row
                      justify="center"
                      gutter={[16, 16]}
                      style={{ width: "100%" }}>
                      <Col span={12}>
                        <div style={{ marginTop: "5px" }}>
                          <Typography.Text
                            key={status}
                            editable={
                              updateButton.key === rowKey &&
                              updateButton.visible
                                ? {
                                    editing: true,
                                    maxLength: 30,
                                    onChange: (value) => {
                                      setEditedStatusName({
                                        name: value,
                                        key: rowKey,
                                      });
                                    },
                                  }
                                : false
                            }>
                            {editedStatusName && rowKey === editedStatusName.key
                              ? editedStatusName.name
                              : status.name}
                            {/* {editedStatusName
                                  ? editedStatusName
                                  : status.name} */}
                          </Typography.Text>
                        </div>
                      </Col>

                      {defaultStatuses.find((value) => {
                        return value.name === status.name;
                      }) ? (
                        <div>
                          <Col span={4}>
                            {
                              <Tooltip
                                placement="top"
                                size="small"
                                title="Can't Edit Default Status">
                                <Button
                                  disabled={true}
                                  onClick={() => {
                                    editStatusButton(rowKey);
                                  }}
                                  icon={<EditOutlined />}
                                  type="primary"></Button>
                              </Tooltip>
                            }
                          </Col>
                        </div>
                      ) : (
                        <Col span={4}>
                          {editButton.key === rowKey && !editButton.visible ? (
                            <Button
                              icon={<ReloadOutlined />}
                              onClick={closeUpdateOptions}
                              type="blue"></Button>
                          ) : (
                            <Button
                              // disabled={
                              //   editButton.key === rowKey && !editButton.visible
                              // }
                              onClick={() => {
                                editStatusButton(rowKey);
                              }}
                              icon={<EditOutlined />}
                              type="primary"></Button>
                          )}
                        </Col>
                      )}
                      {/* Delete */}
                      {/* {!updateButton.visible && (
                            <Col
                              offset={2}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              md={{ span: 4 }}
                              lg={{ span: 6 }}
                              xl={{ span: 6 }}
                              xxl={{ span: 4 }}>
                              {!updateButton.visible && (
                                <Popconfirm
                                  title="Would You like to Delete"
                                  open={
                                    openDeletePopConfirm.visible &&
                                    openDeletePopConfirm.key === rowKey
                                  }
                                  onConfirm={() => deleteStatus(status)}
                                  onCancel={() => setOpenDeletePopConfirm({})}
                                  okButtonProps={{}}>
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      nonDeleteAbleStatus.includes(status.name)
                                        ? "Cannot delete this status"
                                        : "Delete Status"
                                    }>
                                    <Button
                                      disabled={nonDeleteAbleStatus.includes(
                                        status.name
                                      )}
                                      icon={<DeleteOutlined />}
                                      onClick={() =>
                                        setOpenDeletePopConfirm({
                                          key: rowKey,
                                          visible: true,
                                        })
                                      }
                                      type="danger"></Button>
                                  </Tooltip>
                                </Popconfirm>
                              )}
                            </Col>
                          )} */}
                      {/* Update */}
                      {updateButton.key === rowKey &&
                        updateButton.visible &&
                        !defaultStatuses.includes(status.name) && (
                          <Col
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            md={{ span: 4 }}
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 4 }}>
                            {updateButton.key === rowKey &&
                              updateButton.visible && (
                                <div>
                                  <Popconfirm
                                    title="Would You like to Update Changes"
                                    open={openUpdatePopConfirm}
                                    onConfirm={() => updateStatus(status)}
                                    onCancel={() =>
                                      setOpenUpdatePopConfirm(false)
                                    }
                                    okButtonProps={{}}>
                                    <Button
                                      onClick={() =>
                                        statusNameValidationChecks(status)
                                      }
                                      type="danger">
                                      Update
                                    </Button>
                                  </Popconfirm>
                                </div>
                              )}
                          </Col>
                        )}
                    </Row>
                  </List.Item>
                )}
                bordered></List>
            </div>
          </Col>
        </Row>
      </>
    );
};

export default Status;

/** @format */

import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;
const FooterBar = () => {
  return (
    <div>
      <Footer
        style={{
          textAlign: "center",
        }}>
        © Posist Technologies 2017
      </Footer>
    </div>
  );
};

export default FooterBar;

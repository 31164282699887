/** @format */

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { ToastContainer } from "react-toastify";
import { useGetMeQuery } from "./redux/services/user/userApi";
import Spinner from "./component/spinner/generalSpinner/Spinner";
import { Cookies } from "react-cookie";
import { setLogOut } from "./redux/features/auth/authSlice";
import { apiSlice } from "./redux/apiSlice";
import { useLogOutMutation } from "./redux/services/authApi";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [logOutApi] = useLogOutMutation()
  const logOut = async() =>
  {
    await logOutApi({logOut:true});
    dispatch(setLogOut());
    cookies.remove("token", { path: "/" });
    localStorage.clear();
    navigate("/login", { replace: true });
    sessionStorage.clear();
    dispatch({ type: "RESET_STORE" });
    dispatch(apiSlice.util.resetApiState());
    //window.location.reload();
  };
  const { data, isLoading, isSuccess, isError } = useGetMeQuery();

  // if (error?.status === "FETCH_ERROR") {
  //   window.location.replace(window.location.origin + "/login");
  //   logOut();
  // }

  if (isLoading) {
    return <Spinner />;
  }
  if (isSuccess || isError) {
    return (
      <div>
        <AppRoutes data={data} logOut={logOut} />
        <ToastContainer />
      </div>
    );
  }
};

export default App;

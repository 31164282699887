import React, { useState } from "react";
import AnalysisTable from "../../../../component/tables/analysisTable/AnalysisTable";
import { DatePicker, Space, Spin, Typography } from "antd";
import { useGetTypeWiseMutation } from "../../../../redux/services/analysis/AnalysisApi";

const { RangePicker } = DatePicker;
export default function TicketTypeWise() {
  const [typeWise] = useGetTypeWiseMutation();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [spin, setspin] = useState(false);
  let dataSource = [];
  let cols = [];
  const getReport = async (e) => {
    // console.log(e[0], "date");
    //console.log(e[1], "date");
    setspin(true);
    let res = await typeWise({ fromDate: e[0], toDate: e[1] });
    if (res?.data) {
      setDataSource(res.data);
    }
    setspin(false);
  };
  const getDate = (date) => {
    let newDate = date.split("T");
    return newDate[0];
  };
  const setDataSource = (data) => {
    console.log(data, "report");
    dataSource = data.map((x) => {
      return {
        TotalTickets: x.count,
        Assignee: x.assignee ? x.assignee.firstName : "NA",
        CreatedOn: getDate(x.createdOn),
        Status: x.status,
        Type: x.type[0],
        AVGResolutionTime: msToTime(x.resolutionTime),
      };
    });
    cols = [
      {
        title: "No of Tickets",
        dataIndex: "TotalTickets",
        key: "1",
      },
      {
        title: "Assignee",
        dataIndex: "Assignee",
        key: "2",
      },
      {
        title: "Type",
        dataIndex: "Type",
        key: "3",
      },
      // {
      //   title: "Date",
      //   dataIndex: "createdOn",
      //   key: "4",
      // },
      {
        title: "Status",
        dataIndex: "Status",
        key: "5",
      },
      {
        title: "AVG Resolution Time",
        dataIndex: "AVGResolutionTime",

        key: "6",
      },
    ];
    setData({ columns: cols, dataSource: dataSource });

    console.log(data, "in typewise.js");
    setShow(true);
  };
  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }
  const disabledDate = (current) => {
    // Disable dates after the current date
    return current && current >= new Date();
  };
  
  return (
    <div>
    
      <div>
      <Typography.Title  level={3}>Ticket Type Wise</Typography.Title>

        <h3>Enter Date Range</h3>
        <Space direction="vertical" size={12} style={{ marginBottom: "20px" }}>
          <RangePicker          
            disabledDate={disabledDate}
            onChange={async (e, date) => {
              if (!e?.length) return;
              console.log(new Date(e[0]),new Date(e[1].endOf('day')),"date of typewise");
              getReport([new Date(e[0].startOf('day')),new Date(e[1].endOf('day'))]);
            }}
          />
        </Space>
        {show ? (
          <AnalysisTable
            fileName="Typewise.csv"
            data={data}
            setData={setData}
            setShow={setShow}
            show={show}
            columns={data?.columns}
            dataSource={data?.dataSource}
          />
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {spin ?      <Spin size="default"/>: ""}
      </div>
    </div>
  );
}

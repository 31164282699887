import
{
  Button,
  Card,
  Cascader,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  TimePicker,
} from "antd";

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import SlaEditor from "../../component/appComponents/editor/SlaEditor";
import { toast } from "react-toastify";
import
{
  useCreateSLAMutation,
  useUpdateSLAMutation,
} from "../../redux/services/settings/SLA/SLAapi";
import { useGetStatusQuery } from "../../redux/services/status/statusApi";
import { useGetTypesQuery } from "../../redux/services/types/typesApi";
import
{
  useGetAllAgentQuery,
  useGetDivisionsQuery,
} from "../../redux/services/agent/Agent";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
import debounce from "lodash/debounce";
import RuleCardComponent from "./RuleCardComponent";
import SLAFormDetails from "./SLAFormDetails";
import SLAFormCriteria from "./SLAFormCriteria";

dayjs.extend(customParseFormat);

const isEmailBodyEmpty = (emailBody) =>
{
  if (emailBody?.replace(/<(.|\n)*?>/g, '').trim().length === 0)
  {
    return true
  }
  else
  {
    return false
  }
}
let validateMessages = {
  required: "${label} is required!",
  whitespace: "Enter Some Values!",
};
const SLAform = ({
  slaForm,
  setSlaForm,
  setEmailBody,
  emailBody,
  editSla,
  setEditSla,
  refetch,
  fields,
  setSelectedTemplate,
  nameAlreadyExists,
  clearSlaValues,
  setClearSlaValues
}) =>
{
  const [form] = Form.useForm();

  const rule1 = Form.useWatch("rule1", form);
  const rule2 = Form.useWatch("rule2", form);
  const rule3 = Form.useWatch("rule3", form);

  const quillRef = React.useRef(null);
  const [disableButtons, setDisableButtons] = useState(true)
  const format = "HH:mm:ss";
  const footerOfTimePicker = () =>( <div className="timePickerFooter">
        <div>Days</div>
        <div>Hours</div>
        <div>Minutes</div>
      </div>)
 //Minutes are treated as hours for UI 
 const hideDays = () => {
  const minutes = [];
  for (let i = 24; i <= 60; i++) {
    minutes.push(i);
  }
  return minutes;
 };
  //Seconds are treated as Minutes for UI
 const hideMinutes = () => {
  const minutes = [];
  for (let i = 1; i <= 60; i++) {
    if(i%5!==0) minutes.push(i);
  }
  return minutes;
  }; 
  
  const timePickerProps = {
    renderExtraFooter:footerOfTimePicker,
    disabledMinutes:hideDays,
    disabledSeconds:hideMinutes,
    hideDisabledOptions:true,
  }
  
  const [isOpen, setIsOpen] = useState(true);
  const [createSlaApi, { isLoading: isCreateSlaApiLoading }] = useCreateSLAMutation();
  const [updateSlaApi, { isLoading: isUpdateSlaApiLoading }] = useUpdateSLAMutation();
  const { data: statusList, isSuccess: statusSuccess } = useGetStatusQuery();
  const { data: typesList } = useGetTypesQuery();
  const { data: agentList, isError: isErrorInGetAllAgents } = useGetAllAgentQuery();
  let data = [];
  const { data: divisionData, isError: isErrorInGetDivisionQuery } = useGetDivisionsQuery();

  let division = [], groups = [];
  if (divisionData)
  {
    if (agentList?.length)
    {
      data = agentList?.map((x) =>
      {
        return { label: x.firstname, value: x._id };
      });
    }
    divisionData?.forEach((x) =>
    {
      let data = [];
      agentList?.forEach((agent) =>
      {
        if (agent.enterpriseTicketingDetails.division?._id == x._id)
          data.push({ label: agent.firstname, value: agent._id });
      });
      if (data.length !== 0)
      {
        division.push({
          label: x.name,
          value: x._id,
          children: data,
        });
      }
      groups.push({
        label: x.name,
        value: x._id
      })
    });
    //division.push({ label: "None", value: "None" });
  }



  let status = [];
  if (statusSuccess)
  {
    status.push({
      value: "all",
     label:"All" 
  },)
    statusList?.forEach((x) =>
    {
      if (x.name === "closed") return;
      status.push({
        value: x._id,
        label: x.name,
      });

      return status;
    });
   
  }

  const updateEmailForm = (e) =>
  {
    setEmailBody(e);
    setIsOpen(false);
  };
  const clearCompleteFormAndResetAllStates = () =>
  {
    form.resetFields()
    setEditSla(false);
    setEmailBody(null);
    setSlaForm({});
    setSelectedTemplate(null)
  }
  const createSlaSetting = async () =>
  {

    if (isEmailBodyEmpty(emailBody)) return toast.error("Enter mail format");
    let values = form.getFieldsValue()
    console.log("valuesssss before", values)
    let formValues = formatSlAFormValuesForCreation(values)
    if (nameAlreadyExists(formValues.name)) return toast.error("SLA name already exists!!");
    let response = await toast.promise(
      createSlaApi({ ...formValues, emailBody: emailBody }).unwrap,
      {
        pending: { render: "Please wait...", autoClose: 2000 },
        success: {
          render: "Created Successfully",
          autoClose: 3000,
        },
        error: {
          render({ data })
          {
            if (data?.data?.msg)
            {
              return data.data.msg;
            } else
            {
              return "some error occurred";
            }
          },
          autoClose: 5000,
        },
      }
    );
    if (response)
    {
      //refetch();
      setEmailBody({});
      form.resetFields();
    }

  };

  const updateSLA = async () =>
  {

    if (isEmailBodyEmpty(emailBody)) return toast.error("Enter mail format");
    let values = form.getFieldsValue()
    let formValues = formatSlAFormValuesForCreation(values)
    // if()
    if(formValues.duration.split(":")[0] === '00'&& formValues.duration.split(":")[1] === '00' && (formValues.duration.split(":")[2] < formValues.warningDuration)){
      return toast.error("Warning duration cannot be greater than SLA duration")
    }
    console.log(formValues, formValues.duration.split(":"))
    let response = await toast.promise(
      updateSlaApi({ ...formValues, emailBody: emailBody, _id: slaForm?._id, tenantId: slaForm?.tenantId }).unwrap,
      {
        pending: { render: "Please wait...", autoClose: 2000 },
        success: {
          render: "Updated Successfully",
          autoClose: 3000,
        },
        error: {
          render({ data })
          {
            if (data?.data?.msg)
            {
              return data.data.msg;
            } else
            {
              return "some error occurred";
            }
          },
          autoClose: 5000,
        },
      }
    );

    if (response)
    {
      //setEditSla(false)
      //form.resetFields()
      //setEmailBody(null);
      //setSlaForm({});
      //setSelectedTemplate(null)
    }


  };

  const getAgent = (id) =>
  {
    let agent = {};
    agentList.find((x) =>
    {
      if (x._id === id) return (agent = x);
    });
    return agent;
  };

  const assigneeGroup = (assignTo) =>
  {
    let selectedOption = []
    selectedOption.push(assignTo?.enterpriseTicketingDetails?.division?._id)
    selectedOption.push(assignTo?._id)
    return selectedOption
  };

  const formatSLAFormValuesForView = (slaForm) =>
  {
    let values = JSON.parse(JSON.stringify(slaForm))

    values.status = values?.status?._id;
    values.type = values?.type?._id;
    values.duration = dayjs(values?.duration, format)
    values.warningDuration = dayjs(values?.warningDuration, "mm")
    if (values?.rule1?.isActive)
    {
      values.rule1.duration = dayjs(values.rule1.duration, format)
      if (values?.rule1.typeOfAssignee === "agent" || values?.rule1.assignTo)
      {
        values.rule1.assignTo = assigneeGroup(values?.rule1?.assignTo)
      }
      if (values?.rule2?.isActive)
      {
        values.rule2.duration = dayjs(values?.rule2?.duration, format)
        if (values?.rule2.typeOfAssignee === "agent" || values?.rule2.assignTo)
        {
          values.rule2.assignTo = assigneeGroup(values?.rule2?.assignTo)
        }
        if (values?.rule3?.isActive)
        {
          values.rule3.duration = dayjs(values?.rule3?.duration, format)

          if (values?.rule3.typeOfAssignee === "agent" || values?.rule3.assignTo)
          {
            values.rule3.assignTo = assigneeGroup(values?.rule3?.assignTo)
          }
        }
      }
    }
    if(values?.rule3?.finalSLABreach)
          values.rule3.finalSLABreach = dayjs(values?.rule3?.finalSLABreach, format)

    return values
  }
  const formatSlAFormValuesForCreation = (values) =>
  {
    values.name = values.name.trim();
    const selectedStatus = status.find(
      (status) => status.value === values.status
    );
   
    if (values.type === "all")
    {
      values.type = { _id: "all", name: "All" } 
    } else
    {
      const selectedType = typesList.find(
        (type) => type._id === values.type
      );
      values.type = { _id: selectedType?._id, name: selectedType?.name } 
    }
    
    values.status = { _id: selectedStatus?.value, name: selectedStatus?.label }
    if (typeof values.duration === "object") values.duration = dayjs(values?.duration).format(format)
    if (typeof values.warningDuration === "object") values.warningDuration = dayjs(values?.warningDuration).format("mm")
    //values.duration = dayjs(values.duration)


    if (values?.rule1?.isActive)
    {
      if (typeof values.rule1.duration === "object") values.rule1.duration = dayjs(values.rule1.duration).format(format)
      if (values?.rule1.typeOfAssignee === "agent")
      {
        values.rule1.assignTo = getAgent(values?.rule1.assignTo[1])
      }
      if (values?.rule2?.isActive)
      {
        if (typeof values.rule2.duration === "object") values.rule2.duration = dayjs(values.rule2.duration).format(format)
        if (values?.rule2.typeOfAssignee === "agent")
        {
          values.rule2.assignTo = getAgent(values?.rule2.assignTo[1])
        }
        if (values?.rule3?.isActive)
        {
          if (typeof values.rule3.duration === "object") values.rule3.duration = dayjs(values.rule3.duration).format(format)
          
          if (values?.rule3.typeOfAssignee === "agent")
          {
            values.rule3.assignTo = getAgent(values?.rule3.assignTo[1])
          }

        }
      }
    }
    if (typeof values.rule3?.finalSLABreach === "object") values.rule3.finalSLABreach = dayjs(values?.rule3?.finalSLABreach).format(format)


    return values
  }
  const handleInsertString = (value) =>
  {
    if (quillRef.current)
    {
      const quill = quillRef.current.getEditor();
      const selection = quill.getSelection();
      if (selection)
      {
        const cursorPosition = selection.index;
        quill.insertText(cursorPosition, value);
      }
    }
  };
  const validateEmail = (rule, value, callback) =>
  {
    let flag = false;
    value.forEach((val) =>
    {
      if (val && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(val) && !flag)
      {
        callback('Please enter a valid email address!');
        flag = true;
      }
    })
    if (!flag)
    {
      callback();
    }
  };
  useEffect(() =>
  {
    if (slaForm?.name)
    {
      form.resetFields()
      form.setFieldsValue(formatSLAFormValuesForView(slaForm))
    }

    console.log("edit", formatSLAFormValuesForView(slaForm))
  }, [slaForm])

  useEffect(() =>
  {
    if (clearSlaValues)
    {
      clearCompleteFormAndResetAllStates()
      setClearSlaValues(false)
    }
  }, [clearSlaValues])
  return (
    <div
      style={{ width: '100%' }}
      id="slaId"
    >
      <Form
        layout="vertical"
        form={form}
        name="slaForm"
        onFinish={debounce((values) =>
        {

          if (editSla)
          {
            updateSLA(values);
          } else
          {
            createSlaSetting(values);
          }

        }, 1000)}
        onFinishFailed={() =>
        {
          toast.error("Fill Complete Details ...")
        }}
        validateMessages={validateMessages}
        autoComplete="off"
      >
        <Card
          className="example-card"
          title={<Row gutter={[16]} justify="center">
            <Col style={{ fontWeight: "bolder", paddingTop: "4px" }} span={2}>Rule Info</Col>
            <Col span={20} >
              <Form.Item
                name="isActive"
                style={{ marginBottom: "0px" }}
                valuePropName="checked"
              >
                <Switch
                  style={{
                    marginLeft: "15px",
                    //  backgroundColor: slaForm.isActive ? "#389e0d" : "#ff7875" 
                  }}
                />

              </Form.Item>

            </Col>
            <Col span={2}>
              <Button onClick={() => { clearCompleteFormAndResetAllStates() }}>Clear</Button>
            </Col>
          </Row>}
          style={{
            border: "1px solid #d4d6d8",
            width: '100%',
            background: "#d4d6d8",
            boxShadow: "0 0 40px rgba(0, 0, 0, 0.16)"
          }}
          bodyStyle={{ backgroundColor: "white", color: "black" }}
        >


          <Row gutter={[16]}>
            <SLAFormDetails />
            <SLAFormCriteria
              timePickerProps={timePickerProps}
              status={status}
              typesList={typesList}
              format={format}
              validateEmail={validateEmail}
            />
            <Col span={24}>
              <Divider orientation="left" orientationMargin="0" >
                Email Format
              </Divider>
              {fields?.map((x, key) =>
              {
                return (
                  <Button
                    key={key}
                    onClick={() =>
                    {
                      handleInsertString(x.value);
                    }} style={{ marginRight: "3px", marginBottom: "3px" }}
                    disabled={disableButtons}
                  >
                    {x.name}
                  </Button>
                );
              })}
            </Col>
            <Col span={24}>
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <SlaEditor
                  setDisableButtons={setDisableButtons}
                  fields={fields}
                  emailBody={emailBody}
                  setEmailBody={setEmailBody}
                  updateEmailForm={updateEmailForm}
                  isOpen={isOpen}
                  createSlaSetting={createSlaSetting}
                  quillRef={quillRef}
                />
              </div>
            </Col>
            <Col span={24}>
              <Divider orientation="left" orientationMargin="0">
                Sub Rules
              </Divider>
            </Col>
            {/* Rule 1 */}
            <Col span={24}>
              <RuleCardComponent
                timePickerProps={timePickerProps}
                ruleName="Rule 1"
                format={format}
                disabled={true}
                rule={rule1}
                division={division}
                groups={groups}
                objectName="rule1"
                form={form}
              />
            </Col>
            <Col span={24}>
              <RuleCardComponent
                timePickerProps={timePickerProps}
                ruleName="Rule 2"
                format={format}
                disabled={rule1?.isActive}
                rule={rule2}
                division={division}
                groups={groups}
                objectName="rule2"
                form={form}
              />
            </Col>
            <Col span={24}>
              <RuleCardComponent
           timePickerProps={timePickerProps}
                ruleName="Rule 3"
                format={format}
                disabled={rule2?.isActive}
                rule={rule3}
                division={division}
                groups={groups}
                objectName="rule3"
                form={form}
              />
            </Col>
            {
               (<Col span={24}>
                <Form.Item
                  label="Final SLA Breach"
                  name={["rule3", "finalSLABreach"]}
                  rules={[{ required: true }]}
                >
                  <TimePicker
                    
                    showNow={false}
                    style={{
                      width: 200,
                    }}
                    {...timePickerProps}
                
                    format={format}
                  />
                </Form.Item>
              </Col>)
            }
            {
               (<Col span={12}>
                <Form.Item
                    label="Final SLA Breach Emails"
                    name={["rule3", "finalSLABreachEmails"]}
                    rules={[
                        {
                            required: true,
                            message: 'Enter at least one Email Id !',
                        },
                        {
                            validator: validateEmail,
                        }

                    ]}
                >
                    <Select
                        mode="tags"
                        placeholder="Please enter email addresses"
                    />
                </Form.Item>
              </Col>)
            }
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                {editSla ? (
                  <div>
                    <Button
                      disabled={isUpdateSlaApiLoading}
                      style={{
                        marginRight: "5px",
                      }}
                      type="primary"
                      form="slaForm"
                      htmlType="submit"

                    >
                      Update
                    </Button>
                    <Button
                      type="danger"
                      onClick={() =>
                      {
                        clearCompleteFormAndResetAllStates()
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <Button
                    disabled={isOpen || isCreateSlaApiLoading}
                    type="primary"
                    form="slaForm"
                    htmlType="submit"
                  >
                    Create
                  </Button>
                )}
              </div>
            </Col>
          </Row>

        </Card>
      </Form>
    </div >
  );
}


export default SLAform;
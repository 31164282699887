/** @format */

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/features/auth/authSlice";
import { reportsPermissions, settingsPermissions } from "../../permissions/permissionVariable";
export default function useUserPermissions()
{
  var user = useSelector(selectCurrentUser);


  const hasPermissionForTicket = (permissionValue, ticketData) =>
  {

    let newPermissionValue= permissionValue + "_self";
  
    let permissionStatus = user?.enterpriseTicketingDetails?.permissions?.some((userPermission) =>
    {
      return userPermission.name === newPermissionValue
    });
    return permissionStatus
  };

  const userBelongsToDefaultDivision = () =>
  {
    if(user) return user.enterpriseTicketingDetails.division.isDefault
  }
  const hasPermissionToUpdateOwner = () =>
  {

    let permissionStatus = user?.enterpriseTicketingDetails?.permissions?.some((userPermission) =>
    {
      return userPermission.name === "transfer_to_new_owner"
    });
    console.log("hasPermissionToUpdateOwner", permissionStatus)
    return permissionStatus

  }
  const hasPermissionToUpdateAssignee = (permissionValue, ticketData) =>
  {
    if (ticketData?.owner._id === user._id)
    {
      return true;
    } else
    {
      let permissionStatus = user?.enterpriseTicketingDetails?.permissions?.some((userPermission) =>
      {
        return userPermission.name === "transfer_to_new_assignee"
      });
      return permissionStatus
    }

  }
  const hasPermission = (permissionValue) =>
  {
    let permissionStatus = user?.enterpriseTicketingDetails?.permissions?.some((userPermission) =>
    {
      return userPermission.name === permissionValue
    });
    return permissionStatus
  }

  const hasAnyPermissionToSettings = () =>
  {
    let permissionStatus = false;
    for (let permission in settingsPermissions)
    {
      let userHasPermission = user?.enterpriseTicketingDetails?.permissions?.some((userPermission) =>
      {
        return userPermission.name === settingsPermissions[permission];
      });

      if (userHasPermission)
      {
        permissionStatus = userHasPermission
        break;
      }
    }
    return permissionStatus;
  }
  const hasAnyPermissionToReports = () =>
  {
    let permissionStatus = false;
    for (let permission in reportsPermissions)
    {
      let userHasPermission = user?.enterpriseTicketingDetails?.permissions?.some((userPermission) =>
      {
        return userPermission.name === reportsPermissions[permission];
      });

      if (userHasPermission)
      {
        permissionStatus = userHasPermission
        break;
      }
    }
    return permissionStatus;
  }





  return {
    hasPermissionForTicket,
    hasPermissionToUpdateOwner,
    hasPermissionToUpdateAssignee,
    hasAnyPermissionToSettings,
    hasPermission,
    hasAnyPermissionToReports,
    userBelongsToDefaultDivision
  };
}




// const hasPermissionForTicket = (permissionValue, ticketData) =>
// {

//   let newPermissionValue
//   if (ticketData?.owner?._id === user?._id ||
//     ticketData?.assignee?._id === user?._id ||
//     ticketData?.reporter?._id === user?._id)
//   {
//     newPermissionValue = permissionValue + "_self";
//   }
//   else if (ticketData?.assignee?.division?._id === user?.enterpriseTicketingDetails?.division?._id
//     && ticketData?.assignee?._id !== ticketData?.owner?._id
//   )
//   {
//     newPermissionValue = permissionValue + "_division";
//   }
//   else
//   {
//     newPermissionValue = permissionValue + "_all";
//   }

//   let permissionStatus = user?.enterpriseTicketingDetails?.permissions?.some((userPermission) =>
//   {
//     return userPermission.name === newPermissionValue
//   });

//   return permissionStatus
// };
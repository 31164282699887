/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useGenerateStatusName from "../../../utils/customHooks/ticketsRelatedHooks/useGenerateStatusName";


import HistoryContentSla from "./HistoryContentSla";



const TicketTable = ({
  allTicketsData,
  setPageSize,
  pageNo,
  setPageNo,
  isSuccessGetAllTicketsData,
  isFetchingGetAllTicketsData,
}) =>
{
  const [ticketsData, setTicketsData] = useState([]);
  const navigate = useNavigate();
  const generateStatusName = useGenerateStatusName();
  const [ticketId,setTicketId] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setTicketId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setTicketId("")
  };
  const columns = [
    {
      title: "Ticket",
      dataIndex: "subject",
      render: (text) => {
        return (
          <div>
            <p>
              {text.title.length > 20
                ? `${text.title.substring(0, 25)}...`
                : `${text.title}`}
            </p>
            <p style={{ fontSize: "10px", marginBottom: "0px" }}>
              from: {text.deployment?.name}
            </p>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (statusId) => {
        return generateStatusName(statusId);
      },
    },
    {
      title: "Type",
      dataIndex: "ticketType",
    },
    {
      title: "Owner / Assignee",
      dataIndex: "agents",
      render: (agent) => {
        return (
          <div>
            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
              Owner: {agent.owner?.firstName}
            </p>
            <p style={{ fontSize: "12px" }}>
              Assignee: {agent.assignee ? agent.assignee.firstName : "NA"}
            </p>
          </div>
        );
      },
    },
    {
      title: "Reporter",
      dataIndex: "reporter",
      // sorter: (a, b) => a.reporter - b.reporter,
      render: (reporter) => {
        return <p>{reporter?.firstName}</p>;
      },
    },
    {
      title: "SLA Violation",
      dataIndex: "slaViolation",
      key: 'actions',
      render:  (slaViolation,record) =>
      {      
        return <Button type="danger"  disabled={!slaViolation} onClick={          
         (event) =>
        {             
           event.stopPropagation();   
           setTicketId(record._id)
           showModal()
           
        }}>SLA history</Button>
        // return <Tag color={`${slaViolation?'red':'green'}`}>
        //                             {slaViolation ? "Yes" : "No"}
        //                           </Tag>;
      }
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
    },
  ];
  const describeTicketType = (type) => {
    if (!type || type.length === 0) return "N/A";
    if (type.length === 1) {
      return type[0];
    } else if (type.length === 2) {
      return type[0] + " / " + type[1];
    } else {
      return type[0] + " / " + type[1] + " / " + type[2];
    }
  };

  useEffect(() => {
    if (isSuccessGetAllTicketsData) {
      let formattedTicketsData = allTicketsData?.data?.map((ticket, key) => {
        return {
          typesValues: ticket.type,
          ticketType: describeTicketType(ticket.type),
          attachments: ticket.attachments,
          // createdOn: convertDate(ticket.createdOn),
          createdOn: moment(ticket.createdOn).format("h:mm A ,Do MMM YYYY"),
          customFields: ticket.customFields,
          description: ticket.description,
          lastComments: ticket.lastComments,
          lastHistory: ticket.lastHistory,
          lastModified: ticket.lastModified,
          status: ticket.status,
          subject: {
            title: "#" + ticket.ticketNumber + " - " + ticket.title,
            deployment: ticket.deployment,
          },
          ticketNumber: ticket.ticketNumber,
          title: ticket.title,
          slaViolation: ticket.slaViolation?ticket.slaViolation:false,
          agents: {
            assignee: ticket.assignee ? ticket.assignee : {},
            owner: ticket.owner,
          },
          reporter: ticket.reporter ? ticket.reporter : "NA",
          owner: ticket.owner ? ticket.owner : "NA",
          assignee: ticket.assignee ? ticket.assignee : {},
          deployment: {
            name: ticket?.deployment?.name,
            value: ticket?.deployment?._id,
            label: ticket?.deployment?.name,
            _id: ticket?.deployment?._id,
          },
          key: key,
          isActive: ticket.isActive,
          isDeleted: ticket.isDeleted,
          _id: ticket._id,
        };
      });
      setTicketsData(formattedTicketsData);
    }
  }, [allTicketsData]);
  return (
    <> 
      <div>
        <Table
          size="small"
          showHeader={true}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                return navigate(`/tickets/${record._id}`, {
                  state: { record: record },
                });
              },
            };
          }}
          loading={isFetchingGetAllTicketsData}
          className="capitalizeText"
          pagination={{
            position: ["bottomCenter"],
            defaultPageSize: 10,
            onChange: (page, size) => {
              setPageNo(page);
              setPageSize(size);
            },

            defaultCurrent: pageNo,
            total: isSuccessGetAllTicketsData
              ? allTicketsData?.totalTickets
              : 0,
            // hideOnSinglePage: true,
          }}
          columns={columns}
          dataSource={ticketsData.length ? ticketsData : []}
          scroll={{ y: 400, x: 1024 }}
        />
      </div>
      <Modal title="SLA History" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {!!ticketId && <HistoryContentSla id={ticketId} closeModal={ handleCancel} />}
      </Modal>
    </>
  );
};

export default TicketTable;

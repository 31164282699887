/** @format */

import React, { useEffect, useState } from "react";
import { Table, Space, Tag, Button, Tooltip, Modal, Row, Col } from "antd";
import { ExclamationCircleOutlined, MenuOutlined } from "@ant-design/icons";
import CreateCustomFieldDrawer from "../../../../component/drawers/customField/CreateCustomFieldDrawer";
import {
  useGetCustomFieldQuery,
  useUpdateStatusOfCustomFieldMutation,
} from "../../../../redux/services/customField/customFieldApi";
import { arrayMoveImmutable } from "array-move";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../../redux/apiSlice";
import { useUpdateCustomFieldsOrderMutation } from "../../../../redux/services/settings/customFieldOrder/customFieldOrderApi";
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
const CustomField = () => {
  const dispatch = useDispatch();
  const [isOpenCreateCustomFieldDrawer, setIsOpenCreateCustomFieldDrawer] =
    useState(false);
  const [selectedCustomField, setSelectedCustomField] = useState(null);
  const closeCreateCustomFieldDrawer = () => {
    setIsOpenCreateCustomFieldDrawer(false);
    setSelectedCustomField(null);
  };

  const {
    data: customFields,
    isSuccess,
    isLoading,
    refetch,
  } = useGetCustomFieldQuery();
  const [updateStatusOfCustomFieldApi] = useUpdateStatusOfCustomFieldMutation();
  const [updateCustomFieldsOrderApi] = useUpdateCustomFieldsOrderMutation();
  const updateStatusOfCustomField = (record) => {
    const updateStatusOfCustomField = async () => {
      try {
        const response = await toast.promise(
          updateStatusOfCustomFieldApi({ ...record }).unwrap,
          {
            //pending: { render: "Please wait...", autoClose: 2000 },
            success: {
              render: "Updated SuccessFully",
              autoClose: 3000,
            },
            error: {
              render({ data }) {
                if (data?.data?.msg) {
                  return data.data.msg;
                } else {
                  return "Error: Unable to update";
                }
              },
              autoClose: 5000,
            },
          }
        );

        if (response) {
          console.log(response);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do You want to ${
        record.isActive ? "Deactivate" : "Activate"
      } custom field ?`,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        updateStatusOfCustomField();
      },
    });
  };
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: "#999",
      }}
    />
  ));
  const columnForSort = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 100,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
  ];

  const columns = [
    {
      title: "Field Name",
      dataIndex: "name",
      className: "drag-visible",
    },
    {
      title: "Type of Field",
      dataIndex: "typeOfField",
      className: "drag-visible",
    },
    {
      title: "Mandatory",
      key: "isRequired",
      render: (record) => (
        <>
          <Space size="middle">
            {record.isRequired === true ? (
              <Tag color="success">Yes </Tag>
            ) : (
              <Tag color="error">NO</Tag>
            )}
          </Space>
        </>
      ),
    },
    {
      title: "Visible at Widget",
      key: "isVisibleAtWidget",
      render: (record) => (
        <>
          <Space size="middle">
            {record.isVisibleAtWidget === true ? (
              <Tag color="success">Yes </Tag>
            ) : (
              <Tag color="error">NO</Tag>
            )}
          </Space>
        </>
      ),
    },

    {
      title: "Status",
      key: "isActive",
      render: (record) => (
        <>
          <Space size="middle">
            {record?.isActive ? (
              <Tag color="success">Active</Tag>
            ) : (
              <Tag color="error">Disabled</Tag>
            )}
          </Space>
        </>
      ),
    },

    // {
    //   title: "Disabled",
    //   dataIndex: "isDisabled",
    // },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <Space>
            <Button
              onClick={() => {
                setSelectedCustomField(record);
                setIsOpenCreateCustomFieldDrawer(true);
              }}>
              Edit
            </Button>
            <Tooltip
              title={`Click to ${record.isActive ? "Deactivate" : "Activate"}`}>
              <Button
                type="primary"
                onClick={() => {
                  updateStatusOfCustomField(record);
                }}>
                {record.isActive ? "Deactivate" : "Activate"}
              </Button>
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];
  let customFieldsData;
  if (isSuccess) {
    customFieldsData = customFields?.map((data, key) => {
      return {
        ...data,
        key: data._id,
        index: key,
      };
    });
  }
  //! work here

  const [isChangeOrderActive, setIsChangeOrderActive] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const updateCustomFieldsOrder = () => {
    const updateCustomFieldsOrder = async () => {
      let formattedData = sortedData.map((item) => {
        return item._id;
      });
      try {
        const response = await toast.promise(
          updateCustomFieldsOrderApi({ customFieldsOrder: formattedData })
            .unwrap,
          {
            pending: { render: "Please wait...", autoClose: 2000 },
            success: {
              render: "Updated SuccessFully",
              autoClose: 3000,
            },
            error: {
              render({ data }) {
                if (data?.data?.msg) {
                  return data.data.msg;
                } else {
                  return "Error: Unable to update";
                }
              },
              autoClose: 5000,
            },
          }
        );

        if (response) {
          console.log(response);
          setIsChangeOrderActive(false);
          setSortedData([]);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm! You want to update custom field order.",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        updateCustomFieldsOrder();
      },
    });
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        customFieldsData.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      setSortedData(newData);
      dispatch(
        apiSlice.util.updateQueryData("getCustomField", undefined, (data) => {
          return newData;
        })
      );
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = customFieldsData?.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };
  return (
    <>
      <div>
        <h3 className="ant-typography css-9ntgx0">Custom Fields</h3>
        <Table
          className="capitalizeText"
          title={() => (
            <>
              <Row gutter={16}>
                <Col>
                  <Button
                    onClick={() => {
                      setIsOpenCreateCustomFieldDrawer(true);
                    }}
                    type="primary">
                    Create Field
                  </Button>
                </Col>
                {!isChangeOrderActive && (
                  <Col>
                    <Button
                      // style={{ background: "green", borderColor: "green" }}
                      onClick={() => {
                        setIsChangeOrderActive(true);
                      }}>
                      Change Order of Fields
                    </Button>
                  </Col>
                )}

                {isChangeOrderActive && (
                  <Col>
                    <Button
                      type="dashed"
                      danger
                      // style={{ background: "green", borderColor: "green" }}
                      onClick={() => {
                        if (!sortedData.length)
                          return toast.error("Change Order to Update");
                        updateCustomFieldsOrder();
                      }}>
                      Save Order of Custom Field
                    </Button>
                  </Col>
                )}
                {isChangeOrderActive && (
                  <Col>
                    <Button
                      type="dashed"
                      // style={{ background: "green", borderColor: "green" }}
                      onClick={() => {
                        setIsChangeOrderActive(false);
                        refetch();
                      }}>
                      Reset
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}
          pagination={false}
          loading={isLoading}
          showHeader={true}
          columns={
            isChangeOrderActive ? columnForSort.concat(columns) : columns
          }
          dataSource={isSuccess ? customFieldsData : []}
          scroll={{ y: 340, x: 1024 }}
          size="small"
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>

      <CreateCustomFieldDrawer
        selectedCustomField={selectedCustomField}
        isOpenCreateCustomFieldDrawer={isOpenCreateCustomFieldDrawer}
        closeCreateCustomFieldDrawer={closeCreateCustomFieldDrawer}
      />
    </>
  );
};

export default CustomField;

/** @format */

import { InfoCircleOutlined } from "@ant-design/icons";
import
{
  Button,
  Cascader,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetCustomFieldQuery } from "../../../../redux/services/customField/customFieldApi";
import { useGetTypeFieldNamesQuery } from "../../../../redux/services/settings/customTypeName/customTypeNameApi";
import { useUpdateTicketMutation } from "../../../../redux/services/ticket/ticketApi";
import { useGetTypesQuery } from "../../../../redux/services/types/typesApi";
import useResponsiveProfileDrawerSize from "../../../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
import PickListSelectBox from "../../customField/components/PickListSelectBox";
import PickListWithApiSelectBox from "../../customField/components/PickListWithApiSelectBox";


let validateMessages = {
  required: "${label} is required!",
  whitespace: "Enter Characters!",
};

const EditTicketDrawer = ({
  isEditTicketDrawerOpen,
  closeEditTicketDrawer,
  refetch,
  setData,
  query,
  formData,
  selectedRecord,
}) =>
{
  const {
    data: types,
    isSuccess: typesIsSuccess,
    isLoading: typesIsLoading,
  } = useGetTypesQuery();
  const { data: customFields, isSuccess: customFieldsIsSuccess } =
    useGetCustomFieldQuery();
  const { data: typesFieldName } = useGetTypeFieldNamesQuery();

  const [updateTicketApi, { isLoading: isLoadingUpdateTicket }] =
    useUpdateTicketMutation();
  const [typesOptions, setTypesOptions] = useState([]);

  const [selectedTypeValues, setSelectedTypeValues] = useState([]);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);
  const [dateAndTimeFields, setDateAndTimeFields] = useState([]);

  
  useEffect(() =>
  {
    if (typesIsSuccess)
    {
      let filteredTypes = types.map((superType) =>
      {
        return {
          value: superType.name,
          label: superType.name,
          children: superType.children.map((type) =>
          {
            return {
              value: type.name,
              label: type.name,
              children: type.children.map((subType) =>
              {
                return {
                  value: subType.name,
                  label: subType.name,
                };
              }),
            };
          }),
        };
      });
      setTypesOptions(filteredTypes);
    }
  }, [types]);

  const responsiveDrawerSize = useResponsiveProfileDrawerSize();
  const [form] = Form.useForm();
  const formatAndSetTicketValues = () =>
  {
    if (formData)
    {
      let ticketValues = JSON.parse(JSON.stringify(formData));
      let typesValues = ticketValues.type;
      ticketValues.typesValues = typesValues;
      delete ticketValues["type"];
      if (ticketValues?.typesValues?.length === 1)
      {
        ticketValues.superType = typesValues[0];
      } else if (ticketValues?.typesValues?.length === 2)
      {
        ticketValues.superType = typesValues[0];
        ticketValues.type = typesValues[1];
      } else if (ticketValues?.typesValues?.length === 3)
      {
        ticketValues.superType = typesValues[0];
        ticketValues.type = typesValues[1];
        ticketValues.subType = typesValues[2];
      }
      let priority = ticketValues.priority;
      if (priority === "" || priority === false)
      {
        ticketValues.priority = "low";
      }

      let customFields = ticketValues.customFields;
      if (dateAndTimeFields.length > 0)
      {
        dateAndTimeFields.forEach((field) =>
        {
          if (customFields[field._id])
          {
            customFields[field._id] = moment(customFields[field._id]);
          }
        });
      }
      form.setFieldsValue({ ...ticketValues, ...customFields });
    }
  };
  const closeDrawerOperations = () =>
  {
    closeEditTicketDrawer();
    setDateAndTimeFields([]);
    setIsUpdateButtonDisabled(true);
    form.resetFields();
  };

  const [fields, setFields] = useState([]);
  const fieldLabelGenerator = (field) =>
  {
    return (
      <div>
        <span>{field.name}</span>{" "}
        {field.isCustomFieldInfo && (
          <Tooltip
            // trigger="click"
            destroyTooltipOnHide={false}
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: field.customFieldInfo,
                }}></div>
            }>
            <InfoCircleOutlined style={{ marginLeft: "4px", color: "gray" }} />
          </Tooltip>
        )}
      </div>
    );
  };

  const clearRelatedCustomFieldValues = (field) =>
  {
    if (field?.alias)
    {
      let customFieldsWithApis = customFields.filter((customField) =>
      {
        if (customField?.typeOfField === "pickListWithApi" && customField?.isActive)
        {
          if (customField?.pickListWithApiDetails?.isDependentRequired)
          {
            if (customField?.pickListWithApiDetails?.dependentFields.includes(field?._id))
            {
              return customField
            }
          }
        }
      })
      if (customFieldsWithApis.length)
      {
        customFieldsWithApis.forEach((customField) =>
        {
          let object = {}
          object[customField._id] = []
          let extraFields = formData.customFields[customField._id]
          extraFields?.forEach((item) =>
          {
            object[item] = ""
          })
          form.setFieldsValue({ ...object })
        })
      }
    }
  }

  useEffect(() =>
  {
    async function generateFields()
    {
      if (customFieldsIsSuccess)
      {
        return await Promise.all(
          customFields?.map(async (field) =>
          {
            if (field.isActive)
              if (field.typeOfField === "pickList")
              {
                return (
                  <PickListSelectBox
                    fieldLabelGenerator={fieldLabelGenerator}
                    field={field}
                    form={form}
                    clearRelatedCustomFieldValues={clearRelatedCustomFieldValues}
                  />
                );
              } else if (field.typeOfField === "pickListWithApi")
              {
                return (
                  <PickListWithApiSelectBox
                    fieldLabelGenerator={fieldLabelGenerator}
                    field={field}
                    ticketForm={form}
                    tenantId={formData?.tenantId}
                    deployment={formData?.deployment}
                    clearRelatedCustomFieldValues={clearRelatedCustomFieldValues}
                  />
                );
              } else if (field.typeOfField === "date")
              {
                setDateAndTimeFields((dateAndTimeFields)=>[...dateAndTimeFields, field]);
                return (
                  <Form.Item
                    name={field._id}
                    label={fieldLabelGenerator(field)}
                    rules={[
                      {
                        required: field.isRequired,
                        message: `${ field.name } is required!`,
                      },
                    ]}>
                    <DatePicker
                      disabled={field.isVisibleAtWidget}
                      onChange={() =>
                      {
                        clearRelatedCustomFieldValues(field)
                      }}
                      format="Do-MMM-YYYY"
                      style={{ width: "100%" }}
                      picker={field.typeOfField}
                    />
                  </Form.Item>
                );
              } else if (field.typeOfField === "email")
              {
                return (
                  <Form.Item
                    name={field._id}
                    label={fieldLabelGenerator(field)}
                    hasFeedback
                    rules={[
                      {
                        required: field.isRequired,
                        message: `${ field.name } is required!`,
                      },
                      { whitespace: true },
                      {
                        type: "email",
                        message: `${ field.name } is not a valid email`,
                      },
                    ]}>
                    <Input
                      disabled={field.isVisibleAtWidget}
                      onChange={() =>
                      {
                        clearRelatedCustomFieldValues(field)
                      }}
                      type={field.typeOfField}
                      placeholder={"Enter " + field.name}
                    />
                  </Form.Item>
                );
              } else
              {
                return (
                  <Form.Item
                    name={field._id}
                    label={fieldLabelGenerator(field)}
                    rules={[
                      {
                        required: field.isRequired,
                      },
                      { whitespace: true },
                    ]}>
                    <Input
                      disabled={field.isVisibleAtWidget}
                      onChange={() =>
                      {
                        clearRelatedCustomFieldValues(field)
                      }}
                      type={field.typeOfField}
                      placeholder={"Enter " + field.name}
                    />
                  </Form.Item>
                );
              }
            return [];
          })
        );
      }
    }

    generateFields().then((allFields) =>
    {
      setFields(allFields);
    });
  }, [customFields, customFieldsIsSuccess, isEditTicketDrawerOpen]);

  const onFinishFailed = (values) =>
  {
    form.scrollToField(values.errorFields[0].name[0]);
    setIsUpdateButtonDisabled(true);
    toast.error("Fill Complete Details", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
  };

  const updateTicket = async (values) =>
  {
    const createTicketOptions = {
      customFields: {},
    };
    let mainFieldNames = [
      "description",
      "typesValues",
      "title",
      "attachments",
      "priority",
      "deployment",
    ];

    for (let field in values)
    {
      if (mainFieldNames.includes(field))
      {
        if (field === "attachments")
        {
        } else
        {
          createTicketOptions[field] = values[field];
        }
      } else
      {
        if (values[field])
        {
          createTicketOptions.customFields[field] = values[field];
        }
      }
    }

    //Deleted extra values from set
    if (createTicketOptions.customFields["superType"])
    {
      delete createTicketOptions.customFields["superType"];
    }
    if (createTicketOptions.customFields["type"])
    {
      delete createTicketOptions.customFields["type"];
    }
    if (createTicketOptions.customFields["subType"])
    {
      delete createTicketOptions.customFields["subType"];
    }
    if (createTicketOptions.hasOwnProperty("typesValues"))
    {
      createTicketOptions.type = createTicketOptions.typesValues;
      delete createTicketOptions["typesValues"];
    }
    try
    {
      const response = await toast.promise(
        updateTicketApi({ ...createTicketOptions, id: selectedRecord._id })
          .unwrap,
        {
          pending: { render: "Please wait..." },
          success: {
            render: "Updated SuccessFully",
            autoClose: 3000,
          },
          error: {
            render({ data })
            {
              if (data?.data?.msg)
              {
                return data.data.msg;
              } else
              {
                return "some error occurred while Updating Ticket";
              }
            },
            autoClose: 5000,
          },
        }
      );

      if (response)
      {
        refetch({ id: formData._id });
        closeDrawerOperations();
        setDateAndTimeFields([]);
      }
    } catch (error)
    {
      console.log("error", error);
    }
  };

  useEffect(() =>
  {
    //! This Use effect sets all values of form ( edit ticket fields )

    if (isEditTicketDrawerOpen)
    {
      formatAndSetTicketValues();
    }
  }, [formData, isEditTicketDrawerOpen]);

  return (
    <>
      <Drawer
        forceRender
        title="Edit Ticket"
        width={responsiveDrawerSize}
        onClose={closeDrawerOperations}
        open={isEditTicketDrawerOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={
          <Row justify="right" gutter={16}>
            <Col
              xxs={{ span: 14, offset: 6 }}
              xs={{ span: 12, offset: 10 }}
              sm={{ span: 6, offset: 12 }}
              md={{ span: 6, offset: 12 }}
              lg={{ span: 6, offset: 12 }}
              xl={{ span: 6, offset: 12 }}
              xxl={{ span: 6, offset: 12 }}>
              <Space>
                <Button onClick={() => closeDrawerOperations()}>Cancel</Button>
                <Button
                  disabled={isUpdateButtonDisabled || isLoadingUpdateTicket}
                  form="createTicketForm"
                  htmlType="submit"
                  type="primary">
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        }>
        <Form
          className="capitalizeText"
          form={form}
          name="createTicketForm"
          layout="vertical"
          validateMessages={validateMessages}
          onFinishFailed={onFinishFailed}
          onValuesChange={(values) =>
          {
            setIsUpdateButtonDisabled(false);
          }}
          onFinish={(values) =>
          {
            updateTicket(values);
          }}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                  },
                  {
                    whitespace: true,
                  },
                ]}>
                <Input
                   disabled={true} 
                  placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                  },
                  { whitespace: true },
                ]}>
                <TextArea
                  disabled={true}
                  rows={2}
                  placeholder="Enter description" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="priority"
                label="Priority"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select
                  //defaultValue="low"
                  style={{
                    width: 200,
                  }}>
                  <Select.Option value="high">
                    <span style={{ color: "red" }}>High Priority</span>
                  </Select.Option>
                  <Select.Option value="low">
                    <span style={{ color: "darkGreen" }}>Low Priority</span>
                  </Select.Option>
                  <Select.Option value="medium">
                    <span style={{ color: "#bfa61c" }}>Medium Priority</span>
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="typesValues"
                label="Select Type"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Cascader
                  options={typesOptions}
                  dropdownMenuColumnStyle={{ textTransform: "capitalize" }}
                  changeOnSelect={true}
                  dropdownRender={(data) =>
                  {
                    return (
                      <div>
                        {selectedTypeValues?.length !== 3 && (
                          <Typography.Text
                            mark
                            style={{
                              padding: 8,
                            }}>
                            Select{" "}
                            {selectedTypeValues?.length === 1
                              ? typesFieldName?.type
                              : selectedTypeValues?.length === 2
                                ? typesFieldName?.subType
                                : typesFieldName?.superType}{" "}
                            if exists
                          </Typography.Text>
                        )}

                        {data}
                        <Divider
                          style={{
                            margin: 0,
                          }}
                        />
                      </div>
                    );
                  }}
                  onChange={(values, object) =>
                  {
                    setSelectedTypeValues(values);
                    if (values?.length)
                    {
                      if (values.length === 1)
                      {
                        form.setFieldsValue({
                          superType: values[0],
                          type: null,
                          subType: null,
                        });
                      }
                      if (values.length === 2)
                      {
                        form.setFieldsValue({
                          superType: values[0],
                          type: values[1],
                          subType: null,
                        });
                      }
                      if (values.length === 3)
                      {
                        form.setFieldsValue({
                          superType: values[0],
                          type: values[1],
                          subType: values[2],
                        });
                      }
                    } else
                    {
                      form.setFieldsValue({
                        superType: null,
                        type: null,
                        subType: null,
                      });
                    }
                  }}
                  placeholder={
                    typesFieldName?.superType +
                    " / " +
                    typesFieldName?.type +
                    " / " +
                    typesFieldName?.subType
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="superType"
                className="capitalizeText"
                label={typesFieldName?.superType}>
                <Input className="capitalizeText" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type" label={typesFieldName?.type}>
                <Input className="capitalizeText" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="subType" label={typesFieldName?.subType}>
                <Input className="capitalizeText" disabled />
              </Form.Item>
            </Col>
            {customFieldsIsSuccess &&
              fields?.map((field, key) =>
              {
                return (
                  <Col span={24} key={key}>
                    {field}
                  </Col>
                );
              })}
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default EditTicketDrawer;

import React, { useState } from "react";
import AnalysisTable from "../../../../component/tables/analysisTable/AnalysisTable";
import { useGetAgentWiseMutation } from "../../../../redux/services/analysis/AnalysisApi";
import { DatePicker, Space, Typography } from "antd";
const { RangePicker } = DatePicker;

export default function Agentwise() {
  const [agentWise] = useGetAgentWiseMutation();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  let dataSource = [];
  let cols = [];
  const getReport = async (e) => {
    console.log(e[0]._d, "date");
    console.log(e[1]._d, "date");
    let res = await agentWise({ fromDate:new Date(e[0].startOf('day')), toDate: new Date(e[1].endOf('day'))});
    console.log(res, "response agentwise");
    if (res?.data) {
      setDataSource(res.data);
    }
  };

  function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return hrs + ":" + mins + ":" + secs;
  }

  const setDataSource = (data) => {
    console.log(data, "report");
    const findPending = (id) => {
      let pending = 0;

      data?.pendingRequest.forEach((x) => {
        console.log(x._id == id, "idd in find pending");
        // if (x._id == null) return;
        if (x._id == id) {
          pending = x.pendingRequest;
          return;
        }
      });
      console.log(pending, "pending");
      return pending;
    };
    dataSource = data?.data.map((x) => {
      return {
        AgentName: x.agentName,
        Resolved: x.count,
        // createdOn: getDate(x.createdOn),
        ResolutionTime: msToTime(x.resolutionTime),
        OpenTickets: x.openTickets,
        CloseTickets: x.closeTickets,
        PendingRequest: findPending(x._id.agent),
      };
    });

    cols = [
      {
        title: "Agent Name",
        dataIndex: "AgentName",
        key: "1",
      },

      {
        title: "Resolution Time (average)",
        dataIndex: "ResolutionTime",
        key: "3",
      },
      {
        title: "Tickets in bucket",
        dataIndex: "OpenTickets",
        key: "4",
      },
      {
        title: "Resolved Tickets",
        dataIndex: "Resolved",
        key: "2",
      },
      {
        title: "Closed Tickets",
        dataIndex: "CloseTickets",
        key: "5",
      },
      {
        title: "Pending Request",
        dataIndex: "PendingRequest",
        key: "6",
      },
      //   {
      //     title: "Status",
      //     dataIndex: "status",
      //     key: "5",
      //   },
    ];
    setData({ columns: cols, dataSource: dataSource });

    // console.log(data, "in typewise.js");
    setShow(true);
  };
  const disabledDate = (current) => {
    // Disable dates after the current date
    return current && current >= new Date();
  };
  return (
    <div>
      <Typography.Title  level={3}>Agent Wise</Typography.Title>
      <h3>Enter Date Range</h3>
      <Space direction="vertical" size={12} style={{ marginBottom: "20px" }}>
        <RangePicker
          disabledDate={disabledDate}
          onChange={async (e) => {
            if (!e?.length) return;
            getReport(e);
          }}
        />
      </Space>
      {show ? (
        <AnalysisTable
          fileName="Agentwise.csv"
          data={data}
          setData={setData}
          setShow={setShow}
          show={show}
          columns={data?.columns}
          dataSource={data?.dataSource}
        />
      ) : (
        ""
      )}
    </div>
  );
}

import { Button, DatePicker, Select, Space, Spin, Typography } from "antd";

import React, { useState } from "react";
import AnalysisTable from "../../../../component/tables/analysisTable/AnalysisTable";
import {
  useGetDeploymentsQuery,
  useGetDeploymentWiseMutation,
} from "../../../../redux/services/analysis/AnalysisApi";
import { useSearchDeploymentsMutation } from "../../../../redux/services/pos/PosApis";
import { useGetStatusQuery } from "../../../../redux/services/status/statusApi";
const { RangePicker } = DatePicker;

export default function DeploymentWise() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [spin, setspin] = useState(false);
  const [sendData, setSendData] = useState({});
  const { data: deployments, isLoading, isSuccess } = useGetDeploymentsQuery();
  const { data: status, isSuccess: statusSuccess } = useGetStatusQuery();
  const [getDeploymentWise] = useGetDeploymentWiseMutation();
  const [deploymentOptions, setDeploymentOptions] = useState([]);
  const [searchDeployments] = useSearchDeploymentsMutation();
  const [deployment, setDeploymet] = useState("");

  //   console.log(deployments, "deployemts");
  let deploymentData = [];
  if (isSuccess) {
    deploymentData = deployments?.map((x) => {
      return {
        value: x._id,
        label: x.name,
      };
    });
  }

  //   console.log(deploymentData, "deploymentDatadeploymentData");
  const getReport = async () => {
    //report
    let res = await getDeploymentWise(sendData);
    console.log(res.data, "send data");
    if (res?.data) {
      setDataSource(res.data);
    }
  };
  const setDataSource = (data) => {
    let dataSource = [];
    data.forEach((source) => {
      let ticketData = source.ticketData;
      let statusFilter = {};
      statusFilter = {
        deploymentName: source.deploymentName,
        total: source.total ? source.total : 0,
      };
      for (let i = 0; i < ticketData.length; i++) {
        for (let j = 0; j < status.length; j++) {
          if (ticketData[i].status == status[j]._id) {
            let statusName =
              status[j].name.charAt(0).toUpperCase() + status[j].name.slice(1);

            statusFilter[statusName] = ticketData[i].total;
          }
          // if (!filled) {
          //   let statusName = status[j].name;

          //   statusFilter[statusName] = ticketData[i].total;
          // }
        }
      }
      dataSource.push(statusFilter);
    });

    // let dataSource = data.map((x) => {
    //   let source = {};
    //   source = {
    //     deploymentName: x.deploymentName,
    //     total: x.total,
    //   };
    //   x.ticketData.forEach((status) => {
    //     source[`${status.status}`] = status.total;
    //   });
    //   return source;
    // });

    let col = [];

    col = [
      {
        title: "Deployment",
        dataIndex: "deploymentName",
        key: "1",
      },
    ];
    status.forEach((x, i) => {
      col.push({
        title: x.name,
        render: (text, record) => {
          let check = x.name.charAt(0).toUpperCase() + x.name.slice(1);

          // console.log(text, record, check, "record of status");
          if (
            record.hasOwnProperty(
              x.name.charAt(0).toUpperCase() + x.name.slice(1)
            )
          ) {
            return text[check];
            // return x.name.charAt(0).toUpperCase() + x.name.slice(1);
          } else {
            return 0;
          }
        },
        // dataIndex: x.name.charAt(0).toUpperCase() + x.name.slice(1),
        key: i + 2,
      });
    });
    col.push({
      title: "Total",
      dataIndex: "total",
      key: status.length + 2,
    });
    console.log(col, dataSource, "data source");
    setData({ columns: col, dataSource: dataSource });
    setShow(true);
  };
  var deploymentOption = [];

  const searchDeploymentsFromPos = async (value) => {
    let deploymentData = await searchDeployments({ searchName: value });

    if (deploymentData.data)
      deploymentOption = deploymentData?.data?.deployments.map((dep) => {
        return {
          label: dep.name,
          value: dep._id,
        };
      });
    // deploymentOption.push({
    //   label: "None",
    //   value: null,
    // });
    setDeploymentOptions(deploymentOption);
  };
  const disabledDate = (current) => {
    // Disable dates after the current date
    return current && current >= new Date();
  };
  return (
    <div>
      <div>
      <Typography.Title  level={3}>Deployment Wise</Typography.Title>
        <h3>Enter Date Range</h3>
        <Space
          direction="vertical"
          size={12}
          style={{
            marginBottom: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <RangePicker
              disabledDate={disabledDate}
            onChange={async (e, val) => {
              if (!e?.length) return;
              console.log(e, val, "date change");              
              setSendData({ ...sendData, fromDate:new Date(e[0].startOf('day')), toDate: new Date(e[1].endOf('day'))});
            }}
          />
          <Select
            style={{
              width: "150px",
            }}
            labelInValue
            placeholder="enter 3 words for search"
            // style={props.style}
            mode="multiple"
            filterOption={false}
            notFoundContent={
              !deployment && deployment == "" ? <Spin size="small" /> : null
            }
            onSearch={(e) => {
              if (e == "") return;

              setDeploymet(e);
              if (e.length >= 3) {
                // setDeploymet(e);
                searchDeploymentsFromPos(e);
              }
            }}
            onChange={(e, dep) => {
              console.log("values of dep", e);
              // // setQuery({ ...query, deployment: dep });
              // setApply({ ...query, ...apply, deployments: dep });
              setSendData({ ...sendData, deployments: dep });
            }}
            options={deploymentOptions}
          />
          <Button
            onClick={() => {
              getReport();
            }}
            type="primary"
          >
            Generate
          </Button>
        </Space>
        {show ? (
          <AnalysisTable
            fileName="DeploymentWise.csv"
            data={data}
            setData={setData}
            setShow={setShow}
            show={show}
            yScroll={ 2000}
            columns={data?.columns}
            dataSource={data?.dataSource}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

/** @format */

import React from "react";
import { Spin } from "antd";
const Spinner = () => {
  return (
    <div className="global-spinner-container">
      <Spin size="large" className="global-spinner" tip="Loading..."></Spin>
    </div>
  );
};

export default Spinner;

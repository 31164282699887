/** @format */

import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import IsAuthenticated from "./component/authentication/IsAuthenticated";
import LoginPage from "./pages/login/LoginPage";
import
{
  Roles,
  MainFrame,
  Divisions,
  Permissions,
  Status,
  TicketsBoard,
  Types,
  CustomField,
} from "./pages/mainFrame";
import { DoesNotExist, ErrorNotAuthorized } from "./pages/errorPages";
import
{
  selectCurrentUser,
  setLogOut,
  setUser,
} from "./redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

import SuperAdminPage from "./pages/superAdmin/SuperAdminPage";
import Home from "./pages/mainFrame/components/home/Home";
import TicketAssignment from "./pages/mainFrame/components/ticketAssignment/TicketAssignment";
import useUserPermissions from "./utils/customHooks/permissionHooks/useUserPermissions";
import
{
  permissionValues,
  reportsPermissions,
  settingPermission,
  settingsPermissions,
} from "./utils/permissions/permissionVariable";
import TicketTypeWise from "./pages/mainFrame/components/analysis/ticketTypeWise";
import Agentwise from "./pages/mainFrame/components/analysis/Agentwise";
import TicketView from "./component/drawers/ticket/ticketView/TicketView";
import EmailTemplates from "./pages/mainFrame/components/emailTemplates/EmailTemplates";
import SLA from "./pages/mainFrame/components/SLA/SLA";
import Authenticated from "./component/authentication/Authenticated";
import MiscellaneousSetting from "./pages/mainFrame/components/miscellaneousSetting/MiscellaneousSetting";
import DeploymentWise from "./pages/mainFrame/components/analysis/DeploymentWise";

const AppRoutes = ({ data, logOut }) =>
{
  const [userData, setUserData] = useState(data?.user);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const { hasAnyPermissionToReports, hasAnyPermissionToSettings, hasPermission } = useUserPermissions()
  useEffect(() =>
  {
    if (userData)
    {
      dispatch(setUser(userData));
    }
  }, [dispatch, userData]);

  return (
    <div>
      <Routes>
        <Route element={<Authenticated />}>
          <Route path="/login" element={<LoginPage />}></Route>
        </Route>
        {/* Protected Routes For Super Admin */}
        {/* <Route element={<IsAuthenticatedSuperAdmin />}>
          <Route
            path="/superAdmin"
            element={<SuperAdminPage logOut={logOut} />}>
            <Route exact index element={<Users />}></Route>
            <Route path="permissions" element={<Permissions />}></Route>
          </Route>
        </Route> */}

        {/* Protected Routes For General*/}
        <Route element={<IsAuthenticated />}>
          <Route path="/" element={<MainFrame logOut={logOut} />}>
            <Route
              exact
              index
              // path="home"
              element={<Home />}
            ></Route>
            {/* <Route path="options">
              <Route path="roles" element={<Roles />}></Route>
              <Route path="divisions" element={<Divisions />}></Route>
            </Route> */}
            {/* <Route path="users" element={<Users />}></Route> */}
            <Route path="tickets" element={<TicketsBoard />}></Route>
            <Route path="tickets/:id" element={<TicketView />} />

            {hasAnyPermissionToSettings() && (
              <Route path="settings">
                {hasPermission(settingsPermissions.settingsStatus) && (
                  <Route path="status" element={<Status />}></Route>
                )}

                {hasPermission(settingsPermissions.settingsTypes) && (
                  <Route path="types" element={<Types />}></Route>
                )}
                {hasPermission(settingsPermissions.settingsCustomFields) && (
                  <Route path="customField" element={<CustomField />}></Route>
                )}
                {hasPermission(settingsPermissions.settingsEmailTriggers) && (
                  <Route
                    path="emailtriggers"
                    element={<EmailTemplates />}
                  ></Route>
                )}
                {hasPermission(settingsPermissions.settingsSLA) && (
                  <Route path="SLA" element={<SLA />}></Route>
                )}

                <Route
                  path="ticketAssignment"
                  element={<TicketAssignment />}
                ></Route>
                {hasPermission(settingsPermissions.settingsMiscellaneous) && (
                  <Route
                    path="miscellaneous"
                    element={<MiscellaneousSetting />}
                  ></Route>
                )}
              </Route>
            )}
            {hasAnyPermissionToReports() && (
              <Route path="Analysis">
                {hasPermission(reportsPermissions.reportTickettypewise) && (
                  <Route
                    path="ticketTypeWise"
                    element={<TicketTypeWise />}
                  ></Route>
                )}
                {hasPermission(reportsPermissions.reportAgentwise) && (
                  <Route path="agentWise" element={<Agentwise />}></Route>
                )}
                {hasPermission(reportsPermissions.reportDeploymentwise) && (
                  <Route
                    path="deploymentWise"
                    element={<DeploymentWise />}
                  ></Route>
                )}
              </Route>
            )}
          </Route>
        </Route>
        <Route
          path="/errorNotAuthorized"
          element={<ErrorNotAuthorized />}
        ></Route>
        <Route path="*" element={<DoesNotExist />}></Route>
      </Routes>
    </div>
  );
};

export default AppRoutes;

/** @format */

import React from "react";
import qnet from "../../assets/logo/loginPage/qnet-image.webp"
import { Button, Form, Input } from "antd";
import { useLoginMutation } from "../../redux/services/authApi";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../redux/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import  logoImage from "../../assets/logo/blackTextLogo.png"
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["token"]);
  if (cookies.token) {
  }

  const cookieExpiresIn = new Date();
  cookieExpiresIn.setTime(cookieExpiresIn.getTime() + 24 * 60 * 60 * 1000);
  const [loginApi, { isLoading }] = useLoginMutation();

  const login = async (values) => {
    try {
      let response = await toast.promise(
        loginApi({
          loginId: values.subDomain + "_" + values.userName,
          password: values.password,
        }).unwrap(),
        {
          pending: { render: "Please wait...", autoClose: 2000 },
          success: {
            render: "Welcome",
            autoClose: 3000,
          },
          error: {
            render({ data }) {
              if (data?.data?.msg) {
                return data.data.msg;
              } else {
                return "Server Error";
              }
            },
            autoClose: 5000,
          },
        }
      );
      if (response) {
        dispatch(setCredentials(response));
        setCookie("token", response.token, {
          path: "/",
          expires: cookieExpiresIn,
        });
        localStorage.setItem("user", JSON.stringify(response.user));
        navigate("/");
      }
    } catch (error) {
      console.log("error in login is", error);
    }
  };
  return (
    <div className="login-page">       
      <div className="login-box">
        <div className="illustration-wrapper">
          <img
            src={qnet}            
            alt="Login"
          />
        </div>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={login}
          onFinishFailed={() => {
            toast.error("Fill Complete Details", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }}>          
          <div style={{position:"relative",height:"80px"}}>
          <img
            src={logoImage}
            style={{width:"140px",left:"-15px",
            position:"absolute"
            }}
            alt="Login"
          />
          </div>
      
          <p className="form-title">Welcome back</p>
          <p>Login to the Dashboard</p>
          <Form.Item
            name="subDomain"
            rules={[{ required: true, message: "Please input Sub domain!" }]}>
            <Input placeholder="Sub Domain" />
          </Form.Item>
          <Form.Item
            name="userName"
            rules={[
              { required: true, message: "Please input your User Name!" },
            ]}>
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input your Password!" },
            ]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button
              className="login-button login-form-button"
              disabled={isLoading}
              type="primary"
              htmlType="submit"           
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
       
      </div>
    </div>
  );
};

export default LoginPage;

/** @format */

import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetCustomFieldQuery } from "../../../redux/services/customField/customFieldApi";
import useResponsiveProfileDrawerSize from "../../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
let validateMessages = {
  required: "${label} is required!",
};
const PickListWithApiDrawer = ({
  isOpenPickListWithApiDrawer,
  closePickListWithApiDrawer,
  setPickListWithApiDrawerValues,
  selectedCustomField,
  pickListWithApiDrawerValues,
  setIsEditedPickListWithApiDetails,
}) => {
  const [pickListApiDetailsForm] = Form.useForm();
  const isDependentRequired = Form.useWatch(
    "isDependentRequired",
    pickListApiDetailsForm
  );
  const isArrayOfObjects = Form.useWatch(
    "isArrayOfObjects",
    pickListApiDetailsForm
  );
  const typeOfApi = Form.useWatch("typeOfApi", pickListApiDetailsForm);
  const isRequireMultipleInputsAsPerSelection = Form.useWatch(
    "isRequireMultipleInputsAsPerSelection",
    pickListApiDetailsForm
  );
  const responseDataType = Form.useWatch(
    "responseDataType",
    pickListApiDetailsForm
  );
  const responsiveDrawerSize = useResponsiveProfileDrawerSize();

  // const [isApiTested, setIsTestedApi] = useState(!isDependentRequired);
  const { data: customFields, isSuccess, isLoading } = useGetCustomFieldQuery();

  const setFormValues = () => {
    if (pickListWithApiDrawerValues) {
      pickListApiDetailsForm.setFieldsValue(pickListWithApiDrawerValues);
    } else if (selectedCustomField) {
      pickListApiDetailsForm.setFieldsValue(
        selectedCustomField?.pickListWithApiDetails
      );
    } else {
      pickListApiDetailsForm.resetFields();
    }
  };
  useEffect(() => {
    setFormValues();
  }, [
    selectedCustomField,
    pickListApiDetailsForm,
    pickListWithApiDrawerValues,
  ]);

  // useEffect(() => {
  //   setIsTestedApi(true);
  // }, [isDependentRequired]);
  const closePickListWithApiDrawerOperations = () => {
    //    setIsTestedApi(false);
    closePickListWithApiDrawer();
    setFormValues();
  };
  // const testApi = async () => {
  //   let apiDetails = pickListApiDetailsForm.getFieldsValue();
  //   try {
  //     const response = await toast.promise(
  //       axios({
  //         url: apiDetails?.url,
  //         method: apiDetails?.method,
  //         token: apiDetails?.token || null,
  //       }),
  //       {
  //         pending: { render: "Please wait...", autoClose: 2000 },
  //         success: {
  //           render: "Api Working SuccessFully",
  //           autoClose: 3000,
  //         },
  //         error: {
  //           render({ data }) {
  //             if (data?.response?.status && data?.response?.statusText) {
  //               return `API ERROR - Status: ${data?.response?.status} Message: ${data?.response?.statusText}`;
  //             } else {
  //               return "Error occurred in Api Test";
  //             }
  //           },
  //           autoClose: 5000,
  //         },
  //       }
  //     );

  //     if (response) {
  //       setIsTestedApi(true);
  //     }
  //   } catch (error) {
  //     setIsTestedApi(false);
  //     console.log("error", error);
  //   }
  // };
  return (
    <div>
      <Drawer
        forceRender
        title="Set Api details for Pick List with APi"
        width={responsiveDrawerSize}
        onClose={closePickListWithApiDrawerOperations}
        open={isOpenPickListWithApiDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={
          <Row justify="right" gutter={16}>
            <Col
              xxs={{ span: 14, offset: 6 }}
              xs={{ span: 12, offset: 6 }}
              sm={{ span: 12, offset: 6 }}
              md={{ span: 12, offset: 6 }}
              lg={{ span: 12, offset: 6 }}
              xl={{ span: 12, offset: 6 }}
              xxl={{ span: 12, offset: 6 }}>
              <Space>
                <Button onClick={closePickListWithApiDrawerOperations}>
                  Cancel
                </Button>

                {/* <Button disabled={isDependentRequired} onClick={testApi}>
                  Test
                </Button> */}

                <Button
                  //disabled={isDisabled}
                  form="pickListApiDetails"
                  htmlType="submit"
                  type="primary">
                  Set Details
                </Button>
              </Space>
            </Col>
          </Row>
        }>
        <Form
          name="pickListApiDetails"
          form={pickListApiDetailsForm}
          validateMessages={validateMessages}
          layout="vertical"
          onFinishFailed={() => {
            toast.error("Fill Complete & Correct Details", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }}
          onFinish={(values) => {
            console.log(pickListApiDetailsForm.isFieldsTouched());
            Modal.confirm({
              title: "Confirm",
              icon: <ExclamationCircleOutlined />,
              content: "Have You Tested Api ? ( And it is working correctly )",
              okText: "Yes",
              cancelText: "No",
              onOk() {
                setPickListWithApiDrawerValues(values);
                setIsEditedPickListWithApiDetails(true);
                closePickListWithApiDrawer();
              },
            });
          }}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="typeOfApi"
                label="Type Of Api"
                rules={[{ required: true }]}>
                <Select
                  style={{
                    width: 150,
                  }}
                  options={[
                    {
                      value: "posistApi",
                      label: "Posist Api",
                    },
                    {
                      value: "externalApi",
                      label: "External Api",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="method"
                label="Method"
                rules={[{ required: true }]}>
                <Select
                  style={{
                    width: 120,
                  }}
                  options={[
                    {
                      value: "GET",
                      label: "GET",
                    },
                    {
                      value: "POST",
                      label: "POST",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {typeOfApi === "posistApi" && (
              <Col span={12}>
                <Form.Item
                  label="Deployment or Tenant Based"
                  name="searchBasedOn"
                  rules={[{ required: true }]}>
                  <Select
                    style={{
                      width: 120,
                    }}
                    options={[
                      {
                        value: "tenantId",
                        label: "Tenant ID",
                      },
                      {
                        value: "deploymentId",
                        label: "Deployment ID",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            )}
            {typeOfApi === "posistApi" && (
              <Col span={12}>
                <Form.Item
                  label="Values you want"
                  name="projections"
                  rules={[{ required: true }]}>
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    onChange={(values) => {
                      let length = values.length;
                      if (length > 0) {
                        if (values[values.length - 1].trim() === "") {
                          pickListApiDetailsForm.setFieldsValue({
                            projections: values.slice(0, length - 1),
                          });
                        } else {
                          let newValue = values[values.length - 1].trim();
                          let newValuesArray = values;
                          newValuesArray[values.length - 1] = newValue;
                          pickListApiDetailsForm.setFieldsValue({
                            projections: newValuesArray,
                          });
                        }
                      }
                    }}
                    placeholder="Tags Mode"
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item
                name="url"
                label="URL"
                rules={[
                  { required: true },
                  { whitespace: true, message: "Cannot be empty" },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="token"
                rules={[{ whitespace: true, message: "Cannot be empty" }]}
                label="Authorization Token">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="responseDataType"
                label="Response Data Type"
                rules={[{ required: true }]}>
                <Select
                  style={{
                    width: 120,
                  }}
                  options={[
                    {
                      value: "array",
                      label: "Array",
                    },
                    {
                      value: "object",
                      label: "Object",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {responseDataType === "object" && (
                <Form.Item
                  name="keyOfObject"
                  label={
                    <div>
                      <span>Key Of Object</span>{" "}
                      <Tooltip
                        trigger="click"
                        title="Select The Key of Object that you want to use as list for pick list">
                        <InfoCircleOutlined
                          style={{ marginLeft: "4px", color: "gray" }}
                        />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    { required: true },
                    { whitespace: true, message: "Cannot be empty" },
                  ]}>
                  <Input />
                </Form.Item>
              )}
            </Col>

            <Col span={12}>
              <Form.Item
                name="isArrayOfObjects"
                label="Is Array of Objects"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              {isArrayOfObjects && (
                <Form.Item
                  name="arrayOfObjectsKey"
                  label={
                    <div>
                      <span>Array of Objects' Key</span>{" "}
                      <Tooltip
                        trigger="click"
                        title="Select The Key of Object that you want to use as list for pick list">
                        <InfoCircleOutlined
                          style={{ marginLeft: "4px", color: "gray" }}
                        />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Array of Objects' Key is required",
                    },
                    { whitespace: true, message: "Cannot be empty" },
                  ]}>
                  <Input />
                </Form.Item>
              )}
            </Col>

            <Col span={12}>
              <Form.Item
                name="isDependentRequired"
                label="Dependent Field required"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            {isDependentRequired && (
              <Col span={24}>
                <Form.Item
                  name="dependentFields"
                  label={
                    <div>
                      <span>Dependents</span>{" "}
                      <Tooltip
                        trigger="click"
                        title="You cannot select pickListWithApi and pickList without Values. <br> 
                        Note: DependentFields must be active else this pickList with api will not work
                        ">
                        <InfoCircleOutlined
                          style={{ marginLeft: "4px", color: "gray" }}
                        />
                      </Tooltip>
                    </div>
                  }
                  className="capitalizeText"
                  rules={[{ required: true }]}>
                  <Select
                    mode="multiple"
                    style={{
                      width: 300,
                    }}>
                    {customFields &&
                      customFields.map((field) => {
                        if (
                          field.typeOfField !== "pickListWithApi" ||
                          (field.typeOfField === "pickList" &&
                            field.isOptionWithValue !== false)
                        ) {
                          return (
                            <Select.Option
                              className="capitalizeText"
                              key={field._id}
                              value={field._id}
                              label={field.name}>
                              {field.name}
                            </Select.Option>
                          );
                        }
                      })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {isDependentRequired && (
              <Col span={24} style={{ color: " red", marginBottom: "20px" }}>
                Note: Dependent fields must be active and must have alias name
                (params name)
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="isRequireMultipleInputsAsPerSelection"
                label="Require multiple inputs as per selection ?"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              {isRequireMultipleInputsAsPerSelection && (
                <Form.Item
                  name="selectedOptionsLabelName"
                  label={
                    <div>
                      <span>Selected Options Label Name</span>{" "}
                      {/* <Tooltip
                        trigger="click"
                        title="Select The Key of Object that you want to use as list for pick list">
                        <InfoCircleOutlined
                          style={{ marginLeft: "4px", color: "gray" }}
                        />
                      </Tooltip> */}
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                    { whitespace: true, message: "Cannot be empty" },
                  ]}>
                  <Input placeholder="Enter Name" />
                </Form.Item>
              )}
            </Col>
            <Col span={24}>
              {isRequireMultipleInputsAsPerSelection &&
                <Form.Item
                  name="isRequireQuantityAndUnitsForStockApi"
                  label="Require Quantity and Units (For Stock API)"
                  valuePropName="checked">
                  <Switch 

                    onChange={(value) =>
                    {
                      if (value)
                      {
                        Modal.warning({
                          title: 'Important Check!!!',
                          content: 'Make sure that projections for Units and Quantity has been added in API',
                        });
                      }
                    }}
                  />
                </Form.Item>}
            </Col>
          
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default PickListWithApiDrawer;

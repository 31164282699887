import { apiSlice } from "../../../apiSlice";
export const miscellaneousApi = apiSlice.injectEndpoints({
  // tagTypes: ["Role"],
  endpoints: (builder) => ({
    updateMiscellaneous: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/setting/updateMiscellaneous",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Triggers"],
    }),
    getMiscellaneous: builder.query({
      query: () => ({
        url: `/setting/getMiscellaneous`,
        method: "GET",
      }),
      providesTags: ["Triggers"],
    }),
  }),
});

export const { useGetMiscellaneousQuery, useUpdateMiscellaneousMutation } =
  miscellaneousApi;

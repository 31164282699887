import { Checkbox, Col, Divider, Form, Select, TimePicker } from 'antd';
import React from 'react'

const SLAFormCriteria = ({ status, typesList, format, validateEmail, timePickerProps }) =>
{

    let formattedTypesList =[{value: "all",label:"All" }]
        
    typesList?.map((type) =>
        {   
                 formattedTypesList.push({
                    value: type._id,
                    label: type.name,
                     });
        })
    
    return (
        <>
            <Col span={24}>
                <Divider orientation="left" orientationMargin="0" >
                    Criteria
                </Divider>
            </Col>
            <Col span={6}  >
                <Form.Item
                    label="Status"
                    name="status"
                    rules={[{ required: true }]}
                    initialValue={"all"}
                >
                    <Select
                        placeholder="Search to Select"
                        options={status||[]}
                        className="capitalizeText"
                        dropdownStyle={{ textTransform: "capitalize" }}
                    />
                </Form.Item>
            </Col>
            <Col span={6}  >
                <Form.Item
                    label="Priority"
                    name="priority"
                    rules={[{ required: true }]}
                    initialValue={"all"}
                >
                    <Select
                        placeholder="Search to Select"
                        className="capitalizeText"
                        dropdownStyle={{ textTransform: "capitalize" }}
                        options={[
                            {
                                value: "all",
                               label:"All" 
                            },
                            {
                                value: "low",
                                label: "Low",
                            },
                            {
                                value: "high",
                                label: "High",
                            },
                            {
                                value: "medium",
                                label: "Medium",
                            },
                        ]}
                    />
                </Form.Item>
            </Col>
            <Col span={6}  >
                <Form.Item
                    label="Type"
                    name="type"
                    rules={[{ required: true }]}
                    initialValue={"all"}
                >
                    <Select
                        placeholder="Search to Select"
                        className="capitalizeText"
                        dropdownStyle={{ textTransform: "capitalize" }}
                        options={formattedTypesList || []}
                    />
                </Form.Item>
            </Col>
            <Col span={6}  >
                <Form.Item
                    label="Duration"
                    name="duration"
                    rules={[{ required: true }]}

                >
                    <TimePicker
                        {...timePickerProps}
                        showNow={false}
                        style={{ width: "100%" }}
                        format={format}
                    />
                </Form.Item>
            </Col>
            <Col span={6}  >
                <Form.Item
                    label="Warning (Minutes)"
                    name="warningDuration"
                    rules={[{ required: true },]}
                >
                    <TimePicker
                        style={{
                            width: "100%",
                        }}
                        showNow={false}
                        placeholder="Select minutes"
                        format="mm"
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Emails"
                    name="emails"
                    rules={[
                        {
                            required: true,
                            message: 'Enter at least one Email Id !',
                        },
                        {
                            validator: validateEmail,
                        }

                    ]}
                >
                    <Select
                        mode="tags"
                        placeholder="Please enter email addresses"
                    />
                </Form.Item>
            </Col>
            <Col span={6}>
                {/* Blank Space */}
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Send Emails to :"
                    name="sendToAgent"
                    style={{ marginBottom: "0px" }}
                    valuePropName="checked"
                >
                    <Checkbox
                        style={{ width: "100%" }}
                    >To Assignee</Checkbox>
                </Form.Item>
            </Col>
        </>
    )
}

export default SLAFormCriteria
/** @format */

import { apiSlice } from "../../apiSlice";

export const commentApi = apiSlice.injectEndpoints({
  tagTypes: ["Comment"],
  endpoints: (builder) => ({
    createComment: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/comment/create",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Comment"],
    }),
    // updateComment: builder.mutation({
    //   query: ({ ...rest }) => ({
    //     url: "/comment/update",
    //     method: "PATCH",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["Comment"],
    // }),
    // deleteComment: builder.mutation({
    //   query: ({ ...rest }) => ({
    //     url: "/comment/delete",
    //     method: "PATCH",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["Comment"],
    // }),
    getComments: builder.query({
      query: (ticketId) => ({
        url: `/comment/getAll/${ticketId}`,
        method: "GET",
      }),
      providesTags: ["Comment"],
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useCreateCommentMutation,
  //   useUpdateCommentMutation,
  //   useDeleteCommentMutation,
} = commentApi;

/** @format */

import { Button, Drawer, List, Comment, Form,  Skeleton } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useRef } from "react";
import useResponsiveProfileDrawerSize from "../../../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
import moment from "moment";
import {
  useCreateCommentMutation,
  useGetCommentsQuery,
} from "../../../../redux/services/comment/commentApi";
import { toast } from "react-toastify";
import AvatarComponent from "../../../appComponents/AvatarComponent";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../redux/features/auth/authSlice";
const CommentList = ({ comments,isLoadingComments }) => {
  return (
    <List
      dataSource={comments}
      header={`${comments.length} ${
        comments.length > 1 ? "Comments" : "comment"
      }`}
      itemLayout="horizontal"
      renderItem={(props) => <>        
          <Skeleton loading={isLoadingComments} active avatar>
          <Comment {...props} />
            </Skeleton>        
      </>}
    />
  );
};

const Editor = ({ onChange, onSubmit, isLoading, value }) => {
  const [form] = Form.useForm();
  return (
    <>
      <Form
        form={form}
        onFinishFailed={() => {
          toast.error("Fill Complete Details", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }}
        onFinish={(values) => {
          onSubmit(values);
          form.resetFields();
        }}>
        <Form.Item name="content">
          <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={isLoading} type="primary">
            Add Comment
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
const TicketCommentsDrawer = ({
  isTicketCommentDrawerOpen,
  closeTicketCommentsDrawer,
  selectedRecord,
}) => {
  const user = useSelector(selectCurrentUser);
  let drawerSize = useResponsiveProfileDrawerSize();
  const closeTicketCommentsDrawerOperations = () => {
    closeTicketCommentsDrawer();
  };
  const { data: comments, isSuccess ,refetch ,isFetching:isLoadingComments} = useGetCommentsQuery(
    selectedRecord?._id
  );

  let commentsData = [];
  if (isSuccess) {
    commentsData = comments.map((comment) => {
      return {
        content: comment.content,
        author:
          comment.userDetails.firstName + " " + comment.userDetails.lastName,
        avatar: (
          <AvatarComponent
            size="medium"
            avatarStyle={{
              backgroundColor: "green",
              border: "1px solid darkGrey",
            }}
            user={{firstname:comment.userDetails?.firstName ,lastname:comment.userDetails?.lastName}}
          />
        ),
        datetime: moment(comment.createdOn).fromNow(),
      };
    });
  }

  const [createCommentApi, { isLoading:isLoadingCommentCreation }] = useCreateCommentMutation();

  const handleSubmit = async (values) => {
    if (!values.content || values.content.trim().length === 0) return;

    let commentOptions = {
      ticketId: selectedRecord._id,
      content: values.content,
      createdOn: new Date(),
    };
    try {
      const response = await toast.promise(
        createCommentApi({ ...commentOptions }).unwrap,
        {
          error: {
            render({ data }) {
              if (data?.data?.msg) {
                return data.data.msg;
              } else {
                return "some error occurred";
              }
            },
            autoClose: 5000,
          },
        }
      );

      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const commentsEndRef = useRef(null);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: "auto" });
  };
  useEffect(scrollToBottom, [isTicketCommentDrawerOpen]);
  useEffect(() => {
    if (isTicketCommentDrawerOpen) {
      refetch();
    }
    console.log("isLoading",isLoadingComments)
  }, [isTicketCommentDrawerOpen]);
  return (
    <div>
      <Drawer
        width={drawerSize}
        title="Comments"
        placement="right"
        onClose={closeTicketCommentsDrawerOperations}
        open={isTicketCommentDrawerOpen}>
        <div>
          {commentsData.length > 0 && <CommentList isLoadingComments={ isLoadingComments} comments={commentsData} />}
          <Comment
            avatar={
              <AvatarComponent
                size="large"
                avatarStyle={{
                  backgroundColor: "green",
                  border: "1px solid darkGrey",
                }}
                user={user}
              />
            }
            content={<Editor onSubmit={handleSubmit} isLoading={isLoadingCommentCreation} />}
          />
          <div ref={commentsEndRef}></div>
        </div>
      </Drawer>
    </div>
  );
};

export default TicketCommentsDrawer;

/** @format */

import React, { useState } from "react";
import {
  Row,
  Col,
  List,
  Input,
  Form,
  Button,
  Tooltip,
  Popconfirm,
  Typography,
  Result,
  Divider,
} from "antd";
import { toast } from "react-toastify";

import {
  useUpdateTicketAssignmentSettingMutation,
  useGetTicketAssignmentQuery,
} from "../../../../redux/services/settings/ticketAssignment/TicketAssignmentApi";
export default function TicketAssignment() {
  const { data, iseError, isSuccess, refetch } = useGetTicketAssignmentQuery();
  if (iseError) toast.error("Reload! something went wrong");
  const [division, setDivision] = useState(
    data
      ? data
      : {
          qnet: "",
          widget: "",
          agent: "",
        }
  );
  const [edit, setEdit] = useState(false);

  const [updateTicketAssignment] = useUpdateTicketAssignmentSettingMutation();
  const updateDivision = async () => {
    const response = await toast.promise(
      updateTicketAssignment(division).unwrap(),
      {
        pending: { render: "Please wait...", autoClose: 2000 },
        success: {
          render: "Division Updated SuccessFully",
          autoClose: 3000,
        },
        error: {
          render({ data }) {
            if (data?.data?.msg) {
              return data.data.msg;
            } else {
              return "some error occurred";
            }
          },
          autoClose: 3000,
        },
      }
    );

    if (response) {
      setDivision(response);
      setEdit(false);
    }
    refetch();
  };
  const editButton = () => {
    setDivision(data);
    setEdit(!edit);
  };
  return (
    <>
      <Row className="capitalizeText" gutter={16}>
        <Col
          style={{ marginBottom: "20px" }}
          // span={8}
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 10 }}
          lg={{ span: 8 }}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <div>
                <List
                  bordered
                  header={
                    <div style={{ fontWeight: "bolder" }}>
                      Ticket Assignment Setting
                    </div>
                  }>
                  <Form>
                    <List.Item>
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        // name="qnet"
                        label="From Qnet"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Status name",
                          },
                        ]}>
                        {data && !edit ? (
                          data.qnet ? (
                            data.qnet
                          ) : (
                            " NA"
                          )
                        ) : (
                          <Input
                            placeholder="enter division name"
                            value={division.qnet}
                            style={{ marginLeft: "10px" }}
                            onChange={(e) =>
                              setDivision({ ...division, qnet: e.target.value })
                            }
                          />
                        )}
                      </Form.Item>
                    </List.Item>
                    <List.Item>
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        // name="widget"
                        label="From Widget"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Status name",
                          },
                        ]}>
                        {data && !edit ? (
                          data.agent ? (
                            data.agent
                          ) : (
                            "NA"
                          )
                        ) : (
                          <Input
                            placeholder="enter division name"
                            // rules={[{ required: true }]}
                            value={division.widget}
                            onChange={(e) => {
                              // if(!edit) setDivision({ ...data, widget: e.target.value })
                              setDivision({
                                ...division,
                                widget: e.target.value,
                              });
                            }}
                          />
                        )}
                      </Form.Item>
                    </List.Item>
                    <List.Item>
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        // name="agent"
                        label="Agent Assign"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Status name",
                          },
                        ]}>
                        {data && !edit ? (
                          data.agent ? (
                            data.agent
                          ) : (
                            "NA"
                          )
                        ) : (
                          <Input
                            placeholder="enter division name"
                            value={division.agent}
                            onChange={(e) => {
                              setDivision({
                                ...division,
                                agent: e.target.value,
                              });
                            }}
                          />
                        )}
                      </Form.Item>
                    </List.Item>
                    <List.Item>
                      {edit ? (
                        <Form.Item style={{ marginBottom: "0px" }}>
                          <Button
                            disabled={
                              division.qnet || division.agent || division.widget
                                ? false
                                : true
                            }
                            type="primary"
                            onClick={updateDivision}>
                            Update
                          </Button>
                          <Button
                            style={{ marginLeft: "10px" }}
                            type="danger"
                            onClick={() => {
                              setEdit(!edit);
                            }}>
                            Cancel
                          </Button>
                        </Form.Item>
                      ) : (
                        <Form.Item style={{ marginBottom: "0px" }}>
                          <Button
                            // style={{ marginLeft: "10px" }}
                            type="primary"
                            onClick={editButton}>
                            Edit
                          </Button>
                        </Form.Item>
                      )}
                    </List.Item>
                  </Form>
                </List>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

/** @format */

import React, { useEffect, useMemo, useRef, useState } from "react";

import { Form, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function DebounceSelect({
  name,
  keyOfResponseToAccessDataOfArray,
  searchOptionKeyName = "searchText",
  fetchOptions,
  keyOfResponseForLabelOfOptions,
  keyOfResponseForValueOfOptions,
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      if (value.length < 3) {
        setOptions([]);
        return;
      }

      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      let searchOptions = {};
      searchOptions[searchOptionKeyName] = value;
      fetchOptions({ ...searchOptions }).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        let formattedData = newOptions?.data[
          keyOfResponseToAccessDataOfArray
        ]?.map((item) => {
          return {
            label: item[keyOfResponseForLabelOfOptions],
            value: JSON.stringify(item),
          };
        });
        console.log(formattedData);
        setOptions(formattedData);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      filterOption={false}
      onSearch={debounceFetcher}
      onDeselect={() => {
        setOptions([]);
      }}
      dropdownStyle={{ textTransform: "capitalize" }}
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : (
          <div>
            <ExclamationCircleOutlined /> No record found
          </div>
        )
      }
      {...props}
      options={(name === "assigneeFilter" || name === "ownerFilter") ?[{label:"unassigned",value:"{}"},...options] :options}
    />
  );
}
const SearchBoxWithDebouncing = ({
  fetchFunction,
  keyOfResponseForLabelOfOptions,
  keyOfResponseForValueOfOptions,
  placeholderValue,
  name,
  label,
  searchOptionKeyName,
  keyOfResponseToAccessDataOfArray,
}) => {
  return (
    <Form.Item name={name} label={label}>
      <DebounceSelect
        test="test"
        name={name}
        keyOfResponseToAccessDataOfArray={keyOfResponseToAccessDataOfArray}
        searchOptionKeyName={searchOptionKeyName}
        keyOfResponseForLabelOfOptions={keyOfResponseForLabelOfOptions}
        keyOfResponseForValueOfOptions={keyOfResponseForValueOfOptions}
        mode="multiple"
        placeholder={placeholderValue}
        fetchOptions={fetchFunction}
        style={{
          width: "100%",
        }}
      />
    </Form.Item>
  );
};

export default SearchBoxWithDebouncing;


// these permission variables are just for code presentation and simplification 
// and to avoid exact name mistake

export const reportsPermissions = {
  reportAgentwise: "report_agentwise",
  reportDeploymentwise: "report_deploymentwise",
  reportTickettypewise: "report_tickettypewise",
};

export const permissionValues = {
  ticketCommentsAccess: "ticket_comments",
  createTicket: "create_ticket",
  resolveTicket: "resolve_ticket",
  editTicket: "edit_ticket",
  ticketHistory: "view_ticket_history",
  updateTicketStatus: "update_ticket_status",
  viewAttachments: "view_attachments",
  transferTicketToNewAssignee: "transfer_to_new_assignee",
  transferTicketToNewOwner: "transfer_to_new_owner",
  viewAllTickets:"view_all_tickets"
};

export const settingsPermissions = {
  settingsStatus: "settings_ticket_status",
  settingsTypes: "settings_ticket_types",
  settingsMiscellaneous: "settings_miscellaneous",
  settingsEmailTriggers: "settings_email_triggers",
  settingsCustomFields: "settings_custom_fields",
  settingsSLA: "settings_SLA",
}






/** @format */

import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import
{
  Button,
  Card,
  Cascader,
  Col,
  Descriptions,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";

import
{
  permissionValues,
  userRoles,
  permissionForAllTickets
} from "../../../../utils/permissions/permissionVariable";
import { useGetStatusQuery } from "../../../../redux/services/status/statusApi";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../redux/features/auth/authSlice";
import { SearchUser } from "../../../appComponents/users/SearchUsers";
import { useGetTypeFieldNamesQuery } from "../../../../redux/services/settings/customTypeName/customTypeNameApi";
import { useGetCustomFieldQuery } from "../../../../redux/services/customField/customFieldApi";
import moment from "moment";
import
{
  useGetAllAgentQuery,
  useGetDivisionsQuery,
} from "../../../../redux/services/agent/Agent";
import
{
  useGetTicketByIdQuery,
  useUpdateTicketMutation,
  useUpdateTicketStatusMutation,
} from "../../../../redux/services/ticket/ticketApi";
import { toast } from "react-toastify";
import TicketHistoryDrawer from "../ticketHistoryDrawer/TicketHistoryDrawer";
import TicketAttachmentsDrawer from "../ticketAttachments/TicketAttachmentsDrawer";
import TicketCommentsDrawer from "../ticketCommentsDrawer/TicketCommentsDrawer";
import EditTicketDrawer from "../editTicket/EditTicketDrawer";
import
{
  useCheckTicketAssignMutation,
  useTicketAssignMutation,
  useAssignToOwnerMutation,
} from "../../../../redux/services/ticketAssignRequest/TicketAssignRequest";
import useWindowSize from "../../../../utils/customHooks/general/useWindowSize";
import useUserPermissions from "../../../../utils/customHooks/permissionHooks/useUserPermissions";
export default function TicketView()
{
  let { id } = useParams();
  let navigate = useNavigate();
  const {
    data: ticket,
    refetch: ticketRefetch,
    isError: ticketError,
  } = useGetTicketByIdQuery({
    id: id,
  });
  const windowSize = useWindowSize();
  const [formData, setFormData] = useState();
  let ticketData = ticket;
  const [checkTicketAssign] = useCheckTicketAssignMutation();
  const [assignToOwner] = useAssignToOwnerMutation();
  const [verify, setVerify] = useState(false);
  const verifyTicket = async (ticket) =>
  {
    let response = await checkTicketAssign({ id: ticket._id });
    console.log("response", !response.data)
    if (response?.data)
    {
      setVerify(true);
      toast.error("Ticket Assigned Request Already Generated waiting for Acceptance");
      return true;
    } else return false;
  };
  // if (isSuccess && ticketData) setFormData({ ...ticketData });
  // let ticketData;
  // if (ticket) ticketData = ticket;
  // ticketData = location.state?.record;
  const {
    data: getTicket,
    refetch,
    isError: errorInTicket,
  } = useGetTicketByIdQuery({ id: id });
  // if (errorInTicket && !getTicket) {
  //   toast.error("Ticket not found");
  //   return navigate(-1);
  // }

  const [disableSave, setDisableSave] = useState(false);
  const [editData, setEditData] = useState(null);

  var user = useSelector(selectCurrentUser);
  const { hasPermissionForTicket, hasPermissionToUpdateOwner, hasPermissionToUpdateAssignee ,userBelongsToDefaultDivision} = useUserPermissions()
  const { data: divisionData, isError } = useGetDivisionsQuery();
  const { data: agentList, iseError } = useGetAllAgentQuery();
  let data = [];
  const [updateTicketStatus] = useUpdateTicketStatusMutation();
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [HistoryDrawer, setHistoryDrawer] = useState(false);
  const [isTicketCommentDrawerOpen, setIsTicketCommentDrawerOpen] =
    useState(false);
  const [isEditTicketDrawer, setIsEditTicketDrawer] = useState(false);
  const closeEditTicketDrawer = () =>
  {
    setIsEditTicketDrawer(false);
  };
  const [ticketAssignment] = useTicketAssignMutation();
  let division = [];
  let ownerDivision = [];
  let divisionGroup;
  if (divisionData)
  {
    if (agentList?.length)
    {
      data = agentList?.map((x) =>
      {
        if (user._id == x._id) return { label: "None", value: "none" };
        else return { label: x.firstname, value: x._id };
      });
    }
    divisionData?.forEach((x) =>
    {
      //here the change
      let data = [];

      agentList?.forEach((agent) =>
      {
        if (
          user._id === agent._id ||
          ticketData?.assignee?._id === agent._id ||
          ticketData?.owner?._id === agent._id
        )
          return;
        if (agent.enterpriseTicketingDetails.division?._id === x._id)
          data.push({ label: agent.firstname, value: agent._id });
      });
      if (data.length !== 0)
      {
        if (x.isDefault)
        {
          ownerDivision.push({
            label: x.name,
            value: x._id,
            children: data,
          });
        } else
          division.push({
            label: x.name,
            value: x._id,
            children: data,
          });
        return;
      }
    });
   // division.push({ label: "None", value: "None" });
  }

  const {
    data: typeFieldNames,
    isLoading: isTypeFieldNamesLoading,
    isSuccess: isTypeFieldNamesSuccess,
  } = useGetTypeFieldNamesQuery();
  const { data: customFields, isSuccess: customFieldsIsSuccess } =
    useGetCustomFieldQuery();
  const { data: status, isSuccess: isStatusSuccess } = useGetStatusQuery();
  let formattedStatus = [];
  if (isStatusSuccess)
  {
    formattedStatus = status?.map((item) =>
    {
      if (item.name === "closed")
      {
        return {
          disabled: true,
          value: item._id,
          label: item.name,
        };
      }
      
      if (item.name === "resolved"
        &&( !hasPermissionForTicket(permissionValues.resolveTicket, ticket)
        || !userBelongsToDefaultDivision())
    
      )
      {
        return {
          disabled: true,
          value: item._id,
          label: item.name,
        };
      }
      return {
        value: item._id,
        label: item.name,
      };
    });
  }

  //send update request
  const update = async (editData) =>
  {
    if (
      (editData?.assignee?.firstName || editData?.group) &&
      editData?.owner?.firstName
    )
    {
      return toast.error("You cannot select assignee and owner ");
    }

    setDisableSave(true);
    if (editData.status)
    {
      //call update ticket
      var resStatus = await updateTicketStatus({
        status: editData.status,
        _id: editData.id,
      });

      if (resStatus?.data) toast.success("status updated");
      else
      {
        toast.error("Permission denied");
      }
      refetch({ id: ticketData._id });
      setEditData(null);
      setDisableSave(false);

      //   updateTicket({ status:editData.status, id: id });
    }
    if (editData?.assignee?.firstName || editData.group)
    {
      var resAssign = await ticketAssignment({
        assignTo: editData.assignee,
        group: editData.group,
        ticket: ticketData,
        owner: {
          firstName: ticketData?.owner?.firstName,
          lastName: ticketData?.owner?.lastName,
          email: ticketData?.owner?.email,
          userId: ticketData?.owner?._id,
          division: ticketData?.owner?.division,
        },
        _id: editData.id,
        assignFrom: {
          firstName: user.firstname,
          lastName: user.lastname,
          email: user?.email,
          userId: user._id,
          division: user?.enterpriseTicketingDetails?.division,
        },
      });
      if (resAssign?.data)
        toast.success("Ticket Transfer Request Sent successfully");
      else
      {
        toast.error(resAssign?.error?.data?.message);
      }
      setDisableSave(false);
    } else if (editData?.owner.firstName || editData?.ownerGroup)
    {
      var resOwner = await assignToOwner({
        assignTo: editData.owner,
        group: editData.ownerGroup,
        ticket: ticketData,
        owner: editData.owner,
        _id: editData.id,
        assignFrom: {
          firstName: user.firstname,
          lastName: user.lastname,
          email: user?.email,
          userId: user._id,
          division: user?.enterpriseTicketingDetails?.division,
        },
      });
      if (resOwner?.data)
        toast.success("Ticket owner Transfer Request Sent sucessfully");
      else
      {
        toast.error(resOwner?.error?.data?.message);
      }
    }

    setEditData(null);
    setDisableSave(false);
  };
  const assignAgent = (change) =>
  {
    let group = {};
    let agent = {};
    if (!change || change[0] === "none") return;
    if (change.length === 2)
    {
      agentList?.find((x) =>
      {
        if (x._id === change[1])
          agent = {
            firstName: x.firstname,
            lastName: x.lastname,
            email: x.email,
            _id: x._id,
            division: x.enterpriseTicketingDetails.division,
          };
      });
      group = { _id: change[0] };
    } else group = { _id: change[0] };
    console.log(group, "agentlist change");

    setEditData({ ...editData, assignee: agent, group: group });
  };
  const ownerAssign = (change) =>
  {
    let group = {};
    let agent = {};

    if (!change || change[0] === "none") return;
    if (change.length === 2)
    {
      agentList?.find((x) =>
      {
        if (x._id === change[1])
          agent = {
            firstName: x.firstname,
            lastName: x.lastname,
            email: x.email,
            userId: x._id,
            division: x.enterpriseTicketingDetails.division,
          };
      });
      group = { _id: change[0] };
    } else group = { _id: change[0] };
    setEditData({ ...editData, owner: agent, ownerGroup: group });
  };
  //for getting default name in input
  let assignee = ["NA"];
  const assigneeGroup = (ticketData) =>
  {
    console.log(ticketData, "here on division grp");

    let agent;
    if (!ticketData?.assignee.firstName && !ticketData?.group?._id)
    {
      return ["NA"];
    }
    if (ticketData?.assignee?.division)
    {
      console.log("here on division grp");
      return [
        ticketData?.assignee?.division.name,
        ticketData?.assignee?.firstName,
      ];
    }

    if (ticketData?.group?._id && !ticketData?.assignee.firstName)
    {
      console.log("here on division grp");

      let group = [];
      divisionData.forEach((x) =>
      {
        if (x._id === ticketData?.group._id) group = [x.name];
      });
      console.log(group, "return group");
      return group;
    }
    agentList?.forEach((x) =>
    {
      if (x._id === ticketData?.assignee?._id) agent = x;
    });
    if (agent?._id === user?._id) return ["ME"];

    return [agent?.enterpriseTicketingDetails.division.name, agent?.firstname];
  };
  const ownerGroup = (ticketData) =>
  {
    let agent;

    if (!ticketData?.owner.firstName && !ticketData?.ownerGroup?._id)
    {
      return ["NA"];
    }
    if (ticketData?.owner?.division)
      return [ticketData?.owner?.division.name, ticketData?.owner?.firstName];
    if (ticketData?.ownerGroup?._id && !ticketData?.owner?.division)
    {
      let group = [];
      divisionData.forEach((x) =>
      {
        if (x._id === ticketData?.ownerGroup._id) group = [x.name];
      });
      return group;
    }
    agentList?.forEach((x) =>
    {
      if (x._id === ticketData?.owner?._id) agent = x;
    });
    if (agent?._id === user?._id) return ["ME"];

    return [agent?.enterpriseTicketingDetails.division.name, agent?.firstname];
  };
  const customFieldValue = (field) =>
  {
    let correspondingId = ticketData.customFields[field._id];
    if (field.typeOfField === "pickList")
    {
      if (field.isOptionWithValue)
      {
        correspondingId = JSON.parse(ticketData.customFields[field._id])._id;
      }
      let object = field.pickListValues.find((value) =>
      {
        return value._id === correspondingId;
      });
      if (object)
      {
        return object.name;
      }
    } else if (field.typeOfField === "pickListWithApi")
    {
      return ticketData.customFields[field._id];
    } else if (field.typeOfField === "date")
    {
      return moment(ticketData.customFields[field._id]).format("Do-MMM-YYYY");
    } else
    {
      return ticketData.customFields[field._id];
    }
  };
  const checkField = (customField) =>
  {
    let res = false;
    let cf = getTicket?.customFields;

    let cF = Object.entries(cf);
    cF.forEach((x) =>
    {
      let feild = x;

      if (feild[0] === customField._id)
      {
        res = true;
        return;
      }
    });
    return res;
  };
  return (
    <>
      {!ticketData ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}>
          {ticketError ? toast.error("Ticket not found") : ""}

          <Spin size="default"/>
          {ticketError ? navigate(-1) : ""}
        </div>
      ) : (
        <div>
          <h2>
            #{ticketData?.ticketNumber}-{ticketData?.title}
          </h2>
          <Descriptions
            column={{ xs: 1, sm: 2, md: 3, xl: 3, xxl: 3 }}
            bordered
            //layout={windowSize.width < 420 ? "vertical" : "horizontal"}
            title="Reporter"
            size="default">
            <Descriptions.Item label="Deployment Name">
              {ticketData?.deployment?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Reporter Name">
              {ticketData?.reporter?.firstName}
            </Descriptions.Item>
            <Descriptions.Item label="email">
              {ticketData?.reporter?.email}
            </Descriptions.Item>

            <Descriptions.Item label="Priority" span={1}>
              {ticketData?.priority === "high" ? (
                <span style={{ color: "red" }}>High Priority</span>
              ) : ticketData?.priority === "medium" ? (
                <span style={{ color: "#bfa61c" }}>Medium Priority</span>
              ) : (
                <span style={{ color: "darkGreen" }}>Low Priority</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Source" span={1}>
              {ticketData?.source}
            </Descriptions.Item>
            <Descriptions.Item label="Created On">
              {moment(ticketData?.createdOn).format("hh:mm:ss A ,Do-MMM-YYYY")}
            </Descriptions.Item>
            {customFieldsIsSuccess &&
              customFields?.map((customField, key) =>
              {
                let resultOfCheckField = checkField(customField);
                if (customField?.isActive && resultOfCheckField)
                {
                  if (customField.typeOfField === "pickListWithApi")
                  {
                    if (!customFieldValue(customField).length)
                    {
                      return <></>;
                    }
                    let selectedOptionsValues = [];
                    if (ticketData?.customFields)
                    {
                      let valueOfField = customFieldValue(customField);

                      if (Array.isArray(valueOfField))
                      {
                        //!ErrorHandling for existing fields
                        if (
                          customField?.pickListWithApiDetails
                            ?.isRequireMultipleInputsAsPerSelection
                        )
                        {
                          valueOfField?.forEach((item) =>
                          {
                            selectedOptionsValues.push(
                              <li>{`${ item }-${ customField?.pickListWithApiDetails
                                ?.selectedOptionsLabelName
                                }: ${ ticketData?.customFields[item]
                                  ? ticketData?.customFields[item]
                                  : " Nil"
                                }`}</li>
                            );
                          });
                        } else
                        {
                          valueOfField?.forEach((item) =>
                          {
                            selectedOptionsValues.push(<li>{item}</li>);
                          });
                        }
                      } else
                      {
                        selectedOptionsValues.push(<li>{valueOfField}</li>);
                      }
                    }
                    return (
                      <Descriptions.Item
                        key={key}
                        label={
                          <div className="capitalizeText">
                            {customField?.name}
                          </div>
                        }>
                        <ul>
                          {selectedOptionsValues.map((value, key) => (
                            <div key={key}>{value}</div>
                          ))}
                        </ul>
                      </Descriptions.Item>
                    );
                  } else
                  {
                    return (
                      <Descriptions.Item
                        key={key}
                        label={
                          <div className="capitalizeText">
                            {customField?.name}
                          </div>
                        }>
                        {ticketData?.customFields
                          ? customFieldValue(customField)
                          : ""}
                      </Descriptions.Item>
                    );
                  }
                }
              })}
          </Descriptions>
          {/* <Card title="Description"> */}
          <Card type="inner" title="Description">
            {ticketData?.description}
          </Card>
          {/* </Card> */}
          <Descriptions
            column={{ xs: 1, sm: 1, md: 2, xl: 2, xxl: 2 }}
            bordered
            layout={windowSize.width < 420 ? "vertical" : "horizontal"}
            title="Ticket"
            size="default"
            style={{ marginTop: "10px" }}>
            <Descriptions.Item label="Status" span={1}>
              <Select
                disabled={
                  !hasPermissionForTicket(permissionValues.updateTicketStatus, ticket) ||
                  ticketData?.status === "closed"
                }
                value={editData?.status ? editData.status : ticketData?.status}
                className="capitalizeText"
                style={{
                  width: "100%",
                }}
                dropdownStyle={{ textTransform: "capitalize" }}
                onChange={(value) =>
                {
                  setEditData({ ...editData, status: value });
                }}
                options={formattedStatus}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Assign To" span={1}>
                <Cascader
                  allowClear={false}
                style={{
                  width: "100%",
                }}
                  disabled={ 
                    (!ticketData?.assignee.firstName && !ticketData?.group?._id) ||
                  verify ||
                  !hasPermissionToUpdateAssignee(permissionValues.transferTicketToNewAssignee,
                    ticketData) ||
                  ticketData?.status === "closed"
                }
                onClick={() => verifyTicket(ticketData)}
                changeOnSelect
                options={[...ownerDivision,...division]}
                value={
                  editData?.assignee?.firstName || editData?.group?._id
                    ? assigneeGroup(editData)
                    : assigneeGroup(ticketData)
                }
                onChange={(e) =>
                {
                  if (!e)
                  {
                    if (editData)
                    {
                      const { assignee, group, ...newData } = editData;

                      setEditData(newData);
                    }
                    return;
                  }

                  if (e && e[0] == "None")
                  {
                    if (editData)
                    {
                      const { assignee, group, ...newData } = editData;

                      setEditData(newData);
                    }
                    return;
                  }

                  assignAgent(e);
                }}
              />
            </Descriptions.Item>
            
              <Descriptions.Item label="Owner" span={1}>
                
                {(ticketData?.owner?.division?.name  || "NA") +"\t/\t"+ (ticketData?.owner?.firstName || "NA")}
              {/* <Cascader
                style={{
                  width: "100%",
                  }}
                  disabled={true}
                // disabled={
                //   verify ||
                //   !hasPermissionToUpdateOwner() ||
                //   ticketData?.status === "closed"
                // }
                onClick={() => verifyTicket(ticketData)}
                changeOnSelect
                options={ownerDivision}
                value={
                  editData?.owner
                    ? ownerGroup(editData)
                    : ownerGroup(ticketData)
                }
                onChange={(e) =>
                {
                  if (!e)
                  {
                    if (editData)
                    {
                      const { owner, ownerGroup, ...newData } = editData;

                      setEditData(newData);
                    }

                    return;
                  }

                  if (e.length <= 1) return;

                  if (e && e[0] == "None")
                  {
                    if (editData)
                    {
                      const { owner, ownerGroup, ...newData } = editData;

                      setEditData(newData);
                    }

                    return;
                  }

                  ownerAssign(e);
                }}
              /> */}
            </Descriptions.Item>

            {ticketData?.type[0] && (
              <Descriptions.Item
                className="capitalizeText"
                label={typeFieldNames?.superType}>
                {ticketData?.type[0]}
              </Descriptions.Item>
            )}
            {ticketData?.type[1] && (
              <Descriptions.Item
                className="capitalizeText"
                label={typeFieldNames?.type}>
                {ticketData?.type[1]}
              </Descriptions.Item>
            )}
            {ticketData?.type[2] && (
              <Descriptions.Item
                className="capitalizeText"
                label={typeFieldNames?.subType}>
                {ticketData?.type[2]}
              </Descriptions.Item>
            )}
          </Descriptions>

          <Card style={{ color: "gray" }} type="inner">
            <Row
              gutter={(8, 8)}
              justify="end"
              align="middle"
              style={{
                width: "100%",
              }}>
              <Col style={{ marginTop: "10px" }}>
                <Button
                  disabled={!hasPermissionForTicket(permissionValues.ticketHistory, ticket)}
                  type="primary"
                  onClick={() =>
                  {
                    setHistoryDrawer(true);
                  }}>
                  History
                </Button>
              </Col>
              <Col style={{ marginTop: "10px" }}>
                <Button
                  disabled={!hasPermissionForTicket(permissionValues.viewAttachments, ticket)}
                  type="primary"
                  onClick={() =>
                  {
                    setAttachmentOpen(true);
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    {ticketData?.attachments?.length > 0 ? (
                      <p
                        style={{
                          color: "brown",
                          //   backgroundColor: "red",
                          padding: "0px",
                          margin: "0px",
                          width: "fit-content",
                          fontSize: "14px",
                          fontWeight: 600,
                          marginRight: "4px",
                          //   borderRadius: "5px",
                        }}>
                        [ {ticketData?.attachments?.length} ]
                      </p>
                    ) : (
                      ""
                    )}
                    Attachments
                  </div>
                </Button>
              </Col>
              <Col style={{ marginTop: "10px" }}>
                <Button
                  disabled={!hasPermissionForTicket(permissionValues.ticketCommentsAccess, ticket)}
                  type="primary"
                  onClick={() =>
                  {
                    setIsTicketCommentDrawerOpen(true);
                  }}>
                  Comments
                </Button>
              </Col>
              <Col style={{ marginTop: "10px" }}>
                <Button
                  disabled={
                    !hasPermissionForTicket(permissionValues.editTicket, ticket) ||
                    ticketData?.status === "closed"

                  }
                  type="danger"
                  onClick={() =>
                  {
                    setIsEditTicketDrawer(true);
                    setFormData(ticketData);
                  }}>
                  Edit ticket
                </Button>
              </Col>
            </Row>
          </Card>
          {editData && Object.entries(editData).length > 0 ? (
            <div>
              <Button
                disabled={disableSave}
                onClick={() =>
                {
                  update({ ...editData, id: id });
                }}
                type="primary"
                style={{ marginTop: "10px" }}>
                Save
              </Button>
              <Button
                disabled={disableSave}
                onClick={() =>
                {
                  setEditData(null);
                }}
                type="danger"
                style={{ marginTop: "10px", marginLeft: "10px" }}>
                Cancel
              </Button>
            </div>
          ) : (
            ""
          )}
          <TicketHistoryDrawer
            isTicketHistoryDrawerOpen={HistoryDrawer}
            closeTicketHistoryDrawer={setHistoryDrawer}
            selectedRecord={ticketData ? ticketData : {}}
          />

          <TicketAttachmentsDrawer
            user={user}
            isTicketAttachmentsDrawerOpen={attachmentOpen}
            closeTicketAttachmentsDrawer={setAttachmentOpen}
            selectedRecord={ticketData ? ticketData : {}}
          // setSelectedRecord={setSelectedRecord}
          />
          <TicketCommentsDrawer
            isTicketCommentDrawerOpen={isTicketCommentDrawerOpen}
            closeTicketCommentsDrawer={setIsTicketCommentDrawerOpen}
            selectedRecord={ticketData ? ticketData : {}}
          // setSelectedRecord={t}
          />
          <EditTicketDrawer

            isEditTicketDrawerOpen={isEditTicketDrawer}
            closeEditTicketDrawer={closeEditTicketDrawer}
            selectedRecord={ticketData ? ticketData : {}}
            refetch={ticketRefetch}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      )}
    </>
  );
}

/** @format */

import { apiSlice } from "../../apiSlice";

export const agent = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateLiveStatus: builder.mutation({
      query: (body) => ({
        url: "/agent/updateLiveStatus",
        method: "POST",
        body: body,
      }),
      providesTags: ["Agent"],
    }),
    getAllAgent: builder.query({
      query: (body) => ({
        url: `/agent/getAllAgent`,
        method: "GET",
      }),
    }),
    getDivisions: builder.query({
      query: () => ({
        url: `/agent/divisions`,
        method: "GET",
      }),
    }),
    ownerSearch: builder.query({
      query: (query) => ({
        url: `/agent/ownerSearch`,
        method: "GET",
        params: { ...query },
      }),
    }),
    assigneeSearch: builder.query({
      query: (query) => ({
        url: `/agent/assigneeSearch`,
        method: "GET",
        params: { ...query },
      }),
    }),
    getLiveStatus: builder.query({
      query: ({ ...body }) => ({
        url: `/agent/liveUser?_id=${body.id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUpdateLiveStatusMutation,
  useGetAllAgentQuery,
  useGetDivisionsQuery,
  useGetLiveStatusQuery,
  useLazyOwnerSearchQuery,
  useLazyAssigneeSearchQuery,
} = agent;

export const ownerSearch = agent.endpoints.ownerSearch;

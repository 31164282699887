/** @format */

import React, { useState } from "react";
import { Layout, Row, Col, Switch, Menu, Card } from "antd";

import corbi from "../../assets/logo/corbi.png";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/features/auth/authSlice";
import AvatarComponent from "../../component/appComponents/AvatarComponent";
import { toast } from "react-toastify";
import
{
  useUpdateLiveStatusMutation,
  useGetLiveStatusQuery,
} from "../../redux/services/agent/Agent";
import { setStatusFilter } from "../../redux/features/filter/filterSlice";
import { useGetTicketAnalysisQuery } from "../../redux/services/ticket/ticketApi";
import { useLocation, useNavigate } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";

const { Header } = Layout;
let logoImage =
  "https://i0.wp.com/posist.com/wp-content/uploads/2022/01/POSIST-LOGO.png";

const NavBar = ({ showProfileDrawer }) =>
{
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: analysisData, isLoading: isLoadingAnalysisData } =
    useGetTicketAnalysisQuery();

  const analysisDataItems = analysisData?.map((item) =>
  {
    return {
      label: (
        <Card
          onClick={() =>
          {
            dispatch(setStatusFilter([item._id]));
            navigate("/tickets", {
              state: { status: [`${ item._id }`] },
            });
          }}
          className="ticket-analysis-cards">
          <div
            className="capitalizeText"
            style={{
              fontWeight: "500",
              fontSize: "14px",
            }}>
            {item.name} : {item.total}
          </div>
        </Card>
      ),
      key: item.name,
    };
  });

  const user = useSelector(selectCurrentUser);

  const [live, setLive] = useState(true);
  const [updateLiveStatus] = useUpdateLiveStatusMutation();
  const {
    data: userLive,
    isError,
    isSuccess,
  } = useGetLiveStatusQuery({ id: user?._id });
  const changeActiveStatus = async (e) =>
  {
    setLive(e);

    const response = await toast.promise(
      updateLiveStatus({ status: e }).unwrap,
      {
        success: {
          render: "Status change",
          autoClose: 3000,
        },
        error: {
          render({ data })
          {
            if (data?.data?.message)
            {
              return data.data.message;
            } else
            {
              return "some error occurred";
            }
          },
          autoClose: 5000,
        },
      }
    );
  };

  return (
    <div>
      <Header
        className="header"
        style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <div className="pages-mainframe-navbar-logo-container">
          <img
            id="pages-mainframe-navbar-big-logo-visibility"
            className="pages-mainframe-navbar-logo-image"
            src={logoImage}
            onClick={() => { navigate("/") }}
            alt=""
          />
          <img
            id="pages-mainframe-navbar-small-logo-visibility"
            className="pages-mainframe-navbar-logo-image"
            src={corbi}
            alt=""
          />
        </div>
        <Layout>
          <Row
            style={{
              background: "white",
              width: "100%",
              paddingRight: "80px",
              paddingLeft: "20px",
            }}>
            <Col
              id="my-menu-container"
              className="homePageAnalysis"
              justify="space-between"
              style={{
                width: "100%",
                background: "white",
                height: "55px",
              }}
              xs={{ span: 18 }}
              sm={{ span: 20 }}
              md={{ span: 21 }}
              lg={{ span: 22 }}
              xl={{ span: 23 }}
              xxl={{ span: 23 }}>
              {location.pathname === "/" && (
                <Menu
                  style={{
                    borderBottom: "none",
                    marginTop: "3px",
                    width: "100%",
                    height: "55px",
                  }}
                  overflowedIndicator={
                    <EllipsisOutlined
                      style={{
                        fontSize: "30px",
                        fontWeight: "bolder",
                        marginTop: "15px",
                      }}
                    />
                  }
                  className="capitalizeText "
                  selectable={false}
                  mode="horizontal"
                  items={analysisDataItems}
                />
              )}
            </Col>
            <Col span={1}>
              {isSuccess ? (
                <Switch
                  defaultChecked={userLive?.isActive}
                  onChange={(e) => changeActiveStatus(e)}
                  style={{
                    transform: "scale(1) ",
                    margin: "0px 10px 0px 0px",
                  }}
                  checkedChildren="LIVE"
                  unCheckedChildren="OFF"
                />
              ) : (
                ""
              )}
            </Col>
          </Row>

          <div
            style={{
              borderRadius: "40px 0px 0px 40px",
              width: "65px",
              right: "0",
              position: "fixed",
              background: "#001628",
              padding: "0px 0px 0px 10px",
            }}>
            <div onClick={showProfileDrawer}>
              <AvatarComponent
                size={45}
                avatarStyle={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  verticalAlign: "middle",
                  border: "2px solid white",
                  fontWeight: "500",
                }}
                tooltip={false}
                user={user}
              />
            </div>
          </div>
        </Layout>
      </Header>
    </div>
  );
};

export default NavBar;

/** @format */

import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const ErrorNotAuthorized = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <div>
            <Button type="primary" onClick={() => navigate(-1)}>
              Go Back
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              type="primary"
              onClick={() => navigate("/login", { replace: true })}>
              Go To Login Page
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default ErrorNotAuthorized;

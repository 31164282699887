/** @format */
/** @format */

import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/features/auth/authSlice";
import { useCookies } from "react-cookie";

const Authenticated = ({ userData }) => {
  const location = useLocation();

  const [cookies] = useCookies();
  let token = useSelector(selectCurrentToken);
  if (!token) {
    token = cookies.token;
  }

  if (token && token !== "undefined") {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return <Outlet />;
  }
};
// const Authenticated = ({ userData }) => {
//   const [cookies] = useCookies();
//   const location = useLocation();
//   let token = useSelector(selectCurrentToken);
//   if (!token) {
//     token = cookies.token;
//   }

//   return token && token !== "undefined" ? (
//     userData?.user?.isSuperAdmin ? (
//       <Navigate to="/superAdmin" state={{ from: location }} replace />
//     ) : (
//       <Navigate to="/main" state={{ from: location }} replace />
//     )
//   ) : (
//     <Outlet />
//     // <Navigate to="/login" state={{ from: location }} replace />
//   );
// };

export default Authenticated;

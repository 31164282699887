const checkForDuplicateSpaces = (rule, value, callback) => {
    const cleanedValue = value.replace(/\s+/g, ' ').trim(); // Remove extra spaces
    if (cleanedValue !== value) {
      callback('Duplicate spaces are not allowed.');
    } else {
      callback();
    }
};
const formValidations = {
    checkForDuplicateSpaces,
}
  
export default formValidations
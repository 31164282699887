import { Col, Divider, Form, Input, Select, Switch } from 'antd'
import React from 'react'
import formValidations from '../../utils/formValidations'

const SLAFormDetails = () =>
{
    return (
        <>
            <Col span={24}>
                {/* <Form.Item
                    name="isActive"
                    style={{ marginBottom: "0px" }}
                    valuePropName="checked"
                >
                    <Switch
                        style={{
                            marginLeft: "15px",
                            //  backgroundColor: slaForm.isActive ? "#389e0d" : "#ff7875" 
                        }}
                    />

                </Form.Item> */}
                <Divider orientation="left" orientationMargin="0" >
                    SLA Details
                </Divider>
            </Col>
            <Col span={6}  >
                <Form.Item
                    label="Name"
                    name="name"
                    style={{ marginBottom: "5px" }}
                    rules={[{ required: true }, { whitespace: true },
                        {validator: formValidations.checkForDuplicateSpaces},]}
                >
                    <Input placeholder="Enter name for SLA" />
                </Form.Item>
            </Col>
            <Col span={6}  >
                <Form.Item
                    label="SLA Type"
                    name="ruleType"
                    style={{ marginBottom: "5px" }}
                    rules={[
                        { required: true },
                    ]}
                >
                    <Select
                        placeholder="Select Type"
                        options={[
                            {
                                value: "CO",
                                label: "Created On",
                            },
                            {
                                value: "LM",
                                label: "Last Modified",
                            },
                        ]}
                    />
                </Form.Item>
            </Col>
        </>
    )
}

export default SLAFormDetails
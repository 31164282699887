/** @format */

import React, { useState } from "react";

import {
  DesktopOutlined,
  KeyOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu } from "antd";

import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/features/auth/authSlice";

const { Header, Content, Footer, Sider } = Layout;

const getItem = (label, key, icon, children) => {
  return {
    label,
    key,
    icon,
    children,
  };
};

const items = [
  getItem("Users", "/superAdmin", <TeamOutlined />),
  // getItem("Create User", "/superAdmin/createUser", <UserOutlined />),
  getItem("Settings", "/superAdmin/settings", <DesktopOutlined />),
  getItem("Permissions", "/superAdmin/permissions", <KeyOutlined />),
  // getItem("Settings", "/superAdmin/settings", <DesktopOutlined />, [
  //   getItem("Team 1", "6"),
  //   getItem("Team 2", "8"),
  // ]),
  getItem("Log Out", "/setLogOut", <LogoutOutlined />),
];
const SuperAdminPage = ({ logOut }) => {
  let location = useLocation();
  console.log("first", location);
  let navigate = useNavigate();
  let user = useSelector(selectCurrentUser);

  // console.log("user", user);
  const [collapsed, setCollapsed] = useState(false);
  // if (isSuccess) {
  //   console.log(data?.user?.isSuperAdmin);
  //   if (data?.user?.isSuperAdmin) {
  //     showSuperAdminPage = true;
  //   }
  // }
  // console.log("showSuperAdminPage", showSuperAdminPage);
  // if (!showSuperAdminPage) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }
  console.log("superAdmin", user);
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" />
        <Menu
          theme="dark"
          defaultSelectedKeys={[location.pathname]}
          defaultOpenKeys={["/users"]}
          mode="inline"
          onClick={({ key }) => {
            if (key === "/setLogOut") {
              logOut();
            } else {
              console.log("key ", key);
              navigate(key);
            }
          }}
          items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        />
        <Content
          style={{
            margin: "0 16px",
          }}>
          {/* <Breadcrumb
            style={{
              margin: "16px 0",
            }}>
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <div
            className="site-layout-background"
            style={{
              marginTop: "20px",
              padding: 24,
              minHeight: 360,
            }}>
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}>
          © Posist Technologies 2017
        </Footer>
      </Layout>
    </Layout>
  );
};

export default SuperAdminPage;

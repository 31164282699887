import { apiSlice } from "../../apiSlice";

export const posApi = apiSlice.injectEndpoints({
  tagTypes: ["POS"],
  endpoints: (builder) => ({
    searchDeployments: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/pos/searchDeployments",
        method: "POST",
        body: rest,
      }),
    }),
    ownerSearch: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/pos/ownerSearch",
        method: "POST",
        body: rest,
      }),
    }),
    assigneeSearch: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/pos/assigneeSearch",
        method: "POST",
        body: rest,
      }),
    }),
  }),
});

export const {
  useSearchDeploymentsMutation,
  useOwnerSearchMutation,
  useAssigneeSearchMutation,
} = posApi;

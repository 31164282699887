/** @format */

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import permissionReducer from "./features/permission/permissionSlice";
import { apiSlice } from "./apiSlice";
import filterReducer from "./features/filter/filterSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    permission: permissionReducer,
    filter: filterReducer,
  },
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

import { Button } from "antd";
import React from "react";
import ReactQuill from "react-quill";
import { useState } from "react";
export default function SlaEditor({
  createSlaSetting,
  setEmailBody,
  emailBody,
  updateEmailForm,
  isOpen,
  quillRef,
  setDisableButtons
})
{
  let modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      // ["link", "image"],
    ],
  };

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    // "blockquote",
    "list",
    "bullet",
    "indent",
    // "link",
    // "image",
  ];
  const [value, setValue] = useState('');
  return (
    <>
      <ReactQuill
        onFocus={() => setDisableButtons(false)}
        onBlur={() => setDisableButtons(true)}
        ref={quillRef}
        theme="snow"
        formats={formats}
        modules={modules}
        value={emailBody}
        onChange={updateEmailForm}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
      </div>
    </>
  );
}

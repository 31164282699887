/** @format */

import React, { useState } from "react";
import { Button, DatePicker, Space, Table } from "antd";
import { useGetAgentWiseMutation } from "../../../redux/services/analysis/AnalysisApi";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
const { RangePicker } = DatePicker;

export default function AnalysisTable({
  dataSource,
  setData,
  setShow,
  columns,
  fileName,
  yScroll
}) {
  return (
    <>
      <div
        style={{
          marginTop: "10px",
          width: "100%",
        }}
      >
        <Button type="primary" style={{ marginBottom: "5px" }}>
          <CSVLink
            filename={fileName}
            data={dataSource}
            className="btn btn-primary"
            onClick={() => {
              toast.success("The file is downloading");
            }}
          >
            Export to CSV
          </CSVLink>
        </Button>
        <Table
          dataSource={dataSource}
          columns={columns}
          scroll={{ y: 340, x: yScroll || 900 }}
          className="capitalizeText"
        />
      </div>
    </>
  );
}

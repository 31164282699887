/** @format */
//! DON'T DELETE CODE- there is some code for custom type fieldNames -getting  saved in setting
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
} from "antd";
import { toast } from "react-toastify";

import React, { useState } from "react";
import {
  useCreateTypeMutation,
  useDeleteTypeMutation,
  useGetTypesQuery,
  useUpdateTypeMutation,
} from "../../../../redux/services/types/typesApi";
import {
  useGetTypeFieldNamesQuery,
  useUpdateTypeFieldNameMutation,
} from "../../../../redux/services/settings/customTypeName/customTypeNameApi";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../redux/features/auth/authSlice";
import formValidations from "../../../../utils/formValidations";

const { Panel } = Collapse;
const responsive = {
  xxs: {
    span: 24,
    // offset: 6
  },
  xs: {
    span: 24,
    // offset: 10
  },
  sm: {
    span: 12,
    // offset: 12
  },
  md: {
    span: 8,
    // offset: 12
  },
  lg: {
    span: 8,
    // offset: 12
  },
  xl: {
    span: 6,
    // offset: 12
  },
  xxl: {
    span: 6,
    // offset: 12
  },
};

// const checkForDuplicateSpaces = (rule, value, callback) => {
//   const cleanedValue = value.replace(/\s+/g, ' ').trim(); // Remove extra spaces
//   if (cleanedValue !== value) {
//     callback('Duplicate spaces are not allowed.');
//   } else {
//     callback();
//   }
// };
const sameTypeFieldNameError = (values) => {
  let { superType, type, subType } = values;

  if (superType === type || superType === subType || subType === type) {
    toast.error(`SuperType, Type, SubType names must be different`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
    return true;
  } else {
    return false;
  }
};
const alreadyExistError = () => {
  return toast.error(`Already exists`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });
};
const Types = () => {
  const [form] = Form.useForm();
  const [formForTypeCreation] = Form.useForm();
  const [createTypeApi] = useCreateTypeMutation();
  const [deleteTypeApi, { isLoading: isLoadingDeleteType }] =
    useDeleteTypeMutation();
  const [updateTypeApi] = useUpdateTypeMutation();
  const [updateTypeFieldName] = useUpdateTypeFieldNameMutation();

  const {
    data: types,
    isLoading: isGetTypesLoading,
    isError: isGetTypesError,
    isSuccess: isGetTypesSuccess,
  } = useGetTypesQuery();

  const {
    data: typeFieldNames,
    isLoading: isTypeFieldNamesLoading,
    isSuccess: isTypeFieldNamesSuccess,
    isError: isTypeFieldNamesError,
    error,
  } = useGetTypeFieldNamesQuery();

  const [updateFieldNamesButton, setUpdateFieldNamesButton] = useState(false);

  const [whichType, setWhichType] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [isCreateTypeModalOpen, setIsCreateTypeModalOpen] = useState(false);
  const showModal = () => {
    setIsCreateTypeModalOpen(true);
  };

  const deleteTypeValue = async (type) => {
    let type1 = type[0];
    let type2 = type[1];
    let type3 = type[2];

    let findType = {};

    types?.forEach((superType) => {
      if (superType._id === type1[1]) {
        findType = superType;
      }
    });
    if (type.length === 3)
      var newChildren = findType.children.map((sub) => {
        if (sub._id === type2[1]) {
          let subofsub = sub.children.filter((x) => {
            if (x._id !== type3[1]) {
              return x;
            }
          });
          return { ...sub, children: subofsub };
        } else return sub;
      });
    if (type.length === 2) {
      var newChildren = findType.children.filter((sub) => {
        if (sub._id !== type2[1]) {
          return sub;
        }
      });
    }
    if (type.length === 1) {
      var newChildren = findType;
    }
    let newType = { ...findType, children: newChildren };

    return { type: newType, size: type.length };
  };

  const deleteType = (typeValuesArray) => {
    const deleteType = async () => {
      try {
        let typeValues = await deleteTypeValue(typeValuesArray);

        const response = await toast.promise(
          deleteTypeApi({ type: typeValues.type, size: typeValues.size })
            .unwrap,
          {
            pending: { render: "Please wait...", autoClose: 2000 },
            success: {
              render: "Type Deleted SuccessFully",
              autoClose: 3000,
            },
            error: {
              render({ data }) {
                if (data?.data?.msg) {
                  return data.data.msg;
                } else {
                  return "some error occurred";
                }
              },
              autoClose: 5000,
            },
          }
        );

        if (response) {
          console.log(response);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Do You want to delete type ?",
      okButtonProps: isLoadingDeleteType && { disabled: true },
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteType();
      },
    });
  };

  const createType = async (operation, values) => {
    try {
      const response = await toast.promise(
        operation === "create"
          ? createTypeApi({ type: values }).unwrap
          : updateTypeApi({ type: values }).unwrap,
        {
          //pending: { render: "Please wait...", autoClose: 2000 },
          success: {
            render: "Type Created SuccessFully",
            autoClose: 3000,
          },
          error: {
            render({ data }) {
              if (data?.data?.msg) {
                return data.data.msg;
              } else {
                return "some error occurred";
              }
            },
            autoClose: 5000,
          },
        }
      );

      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addType = async () => {
    let newTypeValue = formForTypeCreation.getFieldsValue().newTypeValue;
    newTypeValue = newTypeValue.trim();

    if (whichType === "superType") {
      let superType = types?.find((type) => {
        return type.name.toLowerCase() === newTypeValue.toLowerCase();
      });
      if (superType) {
        return alreadyExistError();
      }

      createType("create", [[newTypeValue]]);
    } else if (whichType === "type") {
      let newTypeArray = typeValue;

      let superType = types?.find((type) => {
        return type.name.toLowerCase() === newTypeArray[0][0].toLowerCase();
      });
      let type = superType?.children?.find((type) => {
        return type.name.toLowerCase() === newTypeValue.toLowerCase();
      });
      if (type) {
        return alreadyExistError();
      }

      newTypeArray.push([newTypeValue]);
      createType("update", newTypeArray);
    } else {
      let newTypeArray = typeValue;

      let superType = types?.find((type) => {
        return type.name.toLowerCase() === newTypeArray[0][0].toLowerCase();
      });
      let type = superType?.children?.find((type) => {
        return type.name.toLowerCase() === newTypeArray[1][0].toLowerCase();
      });
      let subType = type?.children?.find((item) => {
        return item.name.toLowerCase() === newTypeValue.toLowerCase();
      });

      if (subType) {
        return alreadyExistError();
      }
      console.log("hello");
      newTypeArray.push([newTypeValue]);
      createType("update", newTypeArray);
    }
    setIsCreateTypeModalOpen(false);
    formForTypeCreation.resetFields();
  };
  const extraButtons = (typeValuesArray) => {
    return (
      <div>
        <Space>
          <DeleteFilled
            style={{ color: "grey", fontSize: "18px" }}
            onClick={() => {
              deleteType(typeValuesArray);
            }}
          />
        </Space>
      </div>
    );
  };
  let validateMessages = {
    required: "${label} is required!",
  };
  const updateFieldNames = () => {
    const typeFieldNames = form.getFieldsValue();
    for (var item in typeFieldNames) {
      typeFieldNames[item] = typeFieldNames[item].trim();
    }
    if (sameTypeFieldNameError(typeFieldNames)) {
      return;
    }
    const updateNames = async () => {
      try {
        const response = await toast.promise(
          updateTypeFieldName({
            typeFieldNames: typeFieldNames,
          }).unwrap,
          {
            pending: { render: "Please wait...", autoClose: 2000 },
            success: {
              render: "Updated Successfully",
              autoClose: 2000,
            },
            error: {
              render({ data }) {
                if (data?.data?.msg) {
                  return data.data.msg;
                } else {
                  return "some error occurred";
                }
              },
              autoClose: 3000,
            },
          }
        );

        if (response) {
          console.log(response);
          setUpdateFieldNamesButton(false);
          //form.setFieldsValue(typeFieldNames);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Do You want to update type field names?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        updateNames();
      },
    });
  };

  const openModalAndSetType = (type, typeValuesArray) => {
    setWhichType(type);
    setTypeValue(typeValuesArray);
    showModal();
  };
  if (isTypeFieldNamesSuccess) {
    form.setFieldsValue(typeFieldNames);
  }


 return <>
     
    {isGetTypesLoading ?
      (<div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          width: "100%",
        }}
      >
        <Spin size="default"/>
      </div>) :
      (<div>
        {/* //! DON'T DELETE - This code is for custom type fieldNames saved in setting */}
        {/* <Card className="card-shadow" title="Field name for types">
          <Row>
            <Col span={24}>
              <Form
                form={form}
                validateMessages={validateMessages}
                onFinishFailed={() => {
                  toast.error("Fill Complete Details", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                }}
                onFinish={updateFieldNames}>
                <Row gutter={16}>
                  <Col {...responsive}>
                    <Form.Item
                      name="superType"
                      label="Super Type"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Value",
                        },
                        {
                          max: 30,
                          message: "- Name can contain 30 Characters",
                        },
                        {
                          whitespace: true,
                          message: "enter characters",
                        },
                      ]}>
                      <Input
                        disabled={!updateFieldNamesButton && typeFieldNames}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...responsive}>
                    <Form.Item
                      name="type"
                      label="Type :"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Value",
                        },
                        {
                          max: 30,
                          message: "- Name can contain 30 Characters",
                        },
                        {
                          whitespace: true,
                          message: "enter characters",
                        },
                      ]}>
                      <Input
                        disabled={!updateFieldNamesButton && typeFieldNames}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...responsive}>
                    <Form.Item
                      name="subType"
                      label="Sub Type :"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Value",
                        },
                        {
                          max: 30,
                          message: "- Name can contain 30 Characters",
                        },
                        {
                          whitespace: true,
                          message: "enter characters",
                        },
                      ]}>
                      <Input
                        disabled={!updateFieldNamesButton && typeFieldNames}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...responsive}>
                    <Form.Item>
                      <Space>
                        {typeFieldNames && !updateFieldNamesButton && (
                          <Button
                            type="primary"
                            onClick={() => {
                              setUpdateFieldNamesButton(true);
                            }}>
                            Edit
                          </Button>
                        )}

                        {typeFieldNames && updateFieldNamesButton && (
                          <Button type="danger" htmlType="submit">
                            Update
                          </Button>
                        )}
                        {!typeFieldNames && (
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        )}
                        <Button
                          disabled={!updateFieldNamesButton && typeFieldNames}
                          onClick={() => {
                            form.resetFields();
                            setUpdateFieldNamesButton(false);
                          }}>
                          Reset
                        </Button>
                        <Button
                          disabled={!updateFieldNamesButton && typeFieldNames}
                          onClick={() => {
                            form.setFieldsValue({
                              superType: "",
                              type: "",
                              subType: "",
                            });
                          }}>
                          Clear
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card> */}
        <Card
          title={
            <div>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      typeFieldNames &&
                      typeFieldNames?.superType &&
                      typeFieldNames?.type &&
                      typeFieldNames?.subType
                    ) {
                      openModalAndSetType("superType", null);
                    } else {
                      toast.error("Create type field names first", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                      });
                    }
                  }}
                  icon={<PlusOutlined />}>
                  Add{" "}
                  {typeFieldNames?.superType
                    ? typeFieldNames?.superType
                    : "SuperType"}
                </Button>
              </Space>
            </div>
          }
          className="card-shadow">
          <Row>
            <Col
              xxs={{ span: 24 }}
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}>
              {types?.length ? (
                <Collapse style={{ width: "100%" }}>
                  {types?.map((superType) => (
                    <Panel
                      header={superType.name}
                      key={superType._id}
                      extra={extraButtons([[superType.name, superType._id]])}>
                      <div style={{ marginBottom: "20px" }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            openModalAndSetType("type", [[superType.name]]);
                          }}
                          icon={<PlusOutlined />}>
                          Add{" "}
                          {typeFieldNames?.type ? typeFieldNames?.type : "Type"}
                        </Button>
                      </div>
                      {superType.children.map((type) => (
                        <Collapse>
                          <Panel
                            header={type.name}
                            key={type._id}
                            extra={extraButtons([
                              [superType.name, superType._id],
                              [type.name, type._id],
                            ])}>
                            <div style={{ marginBottom: "20px" }}>
                              <Button
                                type="primary"
                                onClick={() => {
                                  openModalAndSetType("subType", [
                                    [superType.name],
                                    [type.name],
                                  ]);
                                }}
                                icon={<PlusOutlined />}>
                                Add{" "}
                                {typeFieldNames?.subType
                                  ? typeFieldNames?.subType
                                  : "Sub Type"}
                              </Button>
                            </div>
                            {type.children.map((subType) => (
                              <Collapse expandIcon={() => {}}>
                                <Panel
                                  header={subType.name}
                                  key={subType._id}
                                  extra={extraButtons([
                                    [superType.name, superType._id],
                                    [type.name, type._id],
                                    [subType.name, subType._id],
                                  ])}></Panel>
                              </Collapse>
                            ))}
                          </Panel>
                        </Collapse>
                      ))}
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <>No Types as of Now </>
              )}
            </Col>
          </Row>
        </Card>
        <Modal
          title={(whichType === "superType" ? "Create Type" :
            (whichType === "type" ? "Create Related To" : "Create Sub Related To"))||"Create Type"}
          open={isCreateTypeModalOpen}
          onOk={() => {
            formForTypeCreation.submit();
          }}
          onCancel={() => {
            setIsCreateTypeModalOpen(false);
            setWhichType(null);
            setTypeValue(null);
            formForTypeCreation.resetFields();
          }}
          okText="Create">
          <Form
            form={formForTypeCreation}
            onFinish={addType}
            onFinishFailed={() => {
              toast.error("Fill Details Correctly", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }}
            validateMessages={validateMessages}>
            <Form.Item
              name="newTypeValue"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
                {
            validator: formValidations.checkForDuplicateSpaces
            ,
          },
                {
                  pattern: new RegExp(
                    /^[^~`!@#$%^&*()_+=[\]{}\\|;:'",.<>?]*[a-zA-Z0-9\s]+$/
                  ),
                  message: "-Should Not contain special Characters ",
                },
                {
                  max: 30,
                  message: "- Name can contain 30 Characters",
                },
                {
                  whitespace: true,
                  message: "enter characters",
                },
              ]}
              label="Name">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>)}
      
    
    


   </>
};

export default Types;

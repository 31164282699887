/** @format */

/** @format */
import
{
  DownloadOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";

import
{
  Button,
  Col,
  Drawer,
  Row,
  Space,
  Divider,
  List,
  Typography,
  Form,
  Image,
  Card,
  Empty,
} from "antd";
import axios from "axios";
import Dragger from "antd/lib/upload/Dragger";
import React, { Component, useState } from "react";
import useResponsiveProfileDrawerSize from "../../../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
// import { useGetAttachmentByIdQuery } from "../../../../redux/services/ticket/ticketApi";
import { useUpdateAttachmentMutation } from "../../../../redux/services/ticket/ticketApi";
import Item from "antd/lib/list/Item";
import { saveAs } from "file-saver";
const TicketAttachmentsDrawer = ({
  isTicketAttachmentsDrawerOpen,
  closeTicketAttachmentsDrawer,
  selectedRecord,
  setSelectedRecord,
  user,
}) =>
{
  let drawerSize = useResponsiveProfileDrawerSize();
  const closeTicketAttachmentsDrawerOperations = () =>
  {
    closeTicketAttachmentsDrawer();
  };
  const { Meta } = Card;

  const [save, setSave] = useState(false);
  const [updateAttachmentApi] = useUpdateAttachmentMutation();
  var filesList = [];
  const [fileList, setfileList] = useState([]);
  // const {data,isSucess,isError} = useGetAttachmentByIdQuery(selectedRecord._id,selectedRecord.attachments)
  // console.log(selectedRecord,"selectedRecord data")
  const uploadProps = {
    multiple: true,
    customRequest: function ()
    {
      console.log("hello");
    },
    onChange: function (file)
    {
      if (file.status == "error") return;

      filesList = [...file.fileList];
      setfileList(filesList);
      console.log(filesList, "done");
      if (filesList.length != 0) setSave(true);
      else setSave(false);
    },
    onDrop(e)
    {
      console.log("Dropped files", e.dataTransfer.files);
      filesList.push(e.dataTransfer.files);
    },
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) =>
    {
      try
      {
        let value;
        var arr = [];

        file.forEach(function (f, index)
        {
          let reader = new FileReader();
          reader.readAsDataURL(f.originFileObj);
          reader.onload = (e) =>
          {
            // console.log(arr,"result");
            value = e.target.result;
            arr.push(value);
            if (file.length == index + 1) resolve(arr);
          };
        });
      } catch (error)
      {
        reject(error);
      }
    });
  const downloadImage = async (url, name) =>
  {
    try
    {
      console.log(url, "urll jpg");

      // const config = {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      //   },
      // };

      // let image = await axios.get(url, config);
      // console.log(image, "response");
      let res = await window.saveAs(url, "img.jpeg");
      console.log(res, "resss");
      //saveAs(url, "image.jpg"); // Put your image url here.
    } catch (error)
    {
      console.log(error, "error in download image");
    }
  };
  const verifyAttachment = (attachmnets, fileType) =>
  {
    let find = false;
    attachmnets.find((file) =>
    {
      if (fileType == "image" && file.downloadLocation)
      {
        if (file.downloadLocation)
        {
          find = true;
        }
      } else if (fileType == "file" && !file.downloadLocation)
      {
        if (file.location)
        {
          find = true;
        }
        // find = true;
      }
    });
    return find;
  };
  console.log("attachments", selectedRecord.attachments)
  return (
    <div>
      <Drawer
        // drawerStyle={{ background: "#fafafa" }}
        width={drawerSize}
        title="Attachments"
        // footer={
        // <Row justify="right" gutter={16}>
        //   <Col
        //     xxs={{ span: 14, offset: 6 }}
        //     xs={{ span: 12, offset: 10 }}
        //     sm={{ span: 6, offset: 12 }}
        //     md={{ span: 6, offset: 12 }}
        //     lg={{ span: 6, offset: 12 }}
        //     xl={{ span: 6, offset: 12 }}
        //     xxl={{ span: 6, offset: 12 }}>
        //     <Space>
        //       <Button>Reset</Button>

        //       <Button
        //       //htmlType="submit"
        //       //  onClick={editUserProfile}
        //       >
        //         Edit
        //       </Button>
        //     </Space>
        //   </Col>
        // </Row>
        // }
        placement="right"
        onClose={closeTicketAttachmentsDrawerOperations}
        open={isTicketAttachmentsDrawerOpen}
      >
        {selectedRecord?.attachments.length > 0 ? (
          <Col>
            {verifyAttachment(selectedRecord.attachments, "image") ? (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Divider orientation="left" style={{margin:"0px 0px 20px 0px"}}>
                Images
              </Divider>
                {/* <label
                  style={{
                    fontSize: "20px",
                    width: "100%",
                  }}
                >
                  Images
                </label> */}
                {selectedRecord?.attachments?.map((item) =>
                {
                  // console.log(item, "item attachment");
                  if (item.downloadLocation)
                    return (
                      <Col className="gutter-row" span={12}>
                        {/* <Image
                  width={170}
                  src={item.location}
                  style={{ margin: "5px" }}
                /> */}
                        <Card
                          style={{
                            width: 160,
                            marginBottom: 5,
                            padding: "0px",
                          }}
                          cover={
                            item.downloadLocation ? (
                              <Image src={item.location} />
                            ) : (
                              item.Meta_Data["original_file_name"]
                            )
                          }
                        >
                          <Button
                            style={{
                              width: "100%",
                            }}
                            onClick={(e) =>
                            {
                              console.log(e, "image on click");
                              // downloadImage(item.location, item.key);
                              window.location.href = item.downloadLocation
                                ? item.downloadLocation
                                : item.location;
                            }}
                          >
                            <DownloadOutlined />
                          </Button>
                          {/* <Meta description={item.key}  /> */}
                        </Card>
                      </Col>
                    );
                })}
              </Row>
            ) : (
              ""
            )}
            {verifyAttachment(selectedRecord?.attachments, "file") ? (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Divider orientation="left" style={{margin:"20px 0px 20px 0px"}}>
                Files
              </Divider>
                {/* <label
                  style={{
                    fontSize: "20px",
                    width: "100%",
                  }}
                >
                  Files
                </label> */}
                {selectedRecord?.attachments?.map((item) =>
                {
                  // console.log(item, "item attachment");
                  if (!item.downloadLocation)
                    return (
                      <Col className="gutter-row" span={12}>
                        <Card
                          style={{
                            width: "100%",
                            marginBottom: 5,
                            padding: "0px",
                          }}
                        // cover={<p>{item.key}</p>}
                        >
                          {/* {text.title.length > 20
                ? `${text.title.substring(0, 25)}...`
                : `${text.title}`} */}
                          <Meta
                            style={{
                              maxWidth: "45em",
                            }}
                            description={item.key.substring(0, 25)}
                          />
                          <Button
                            style={{
                              width: "100%",
                            }}
                            onClick={(e) =>
                            {
                              // downloadImage(item.location, item.key);
                              window.location.href = item.downloadLocation
                                ? item.downloadLocation
                                : item.location;
                            }}
                          >
                            <DownloadOutlined />
                          </Button>
                        </Card>
                      </Col>
                    );
                })}
              </Row>
            ) : (
              ""
            )}
          </Col>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {/* <Card
                  style={{
                    width: 150,
                    marginBottom: 5,
                  }}
                  cover={<Image alt="example" src={item.location} />}
                >
                  {/* {item.description ? (
                    <Meta description="This is the description" />
                  ) : (
                    ""
                  )} */}
        {/* </Card> */}
        {/* <List
          bordered
          dataSource={selectedRecord.attachments}
          renderItem={(item) => (
            // <List.Item>
            <Image.PreviewGroup>
              <Image
                width={150}
                src={item.location}
                style={{ margin: "5px" }}
              />
            </Image.PreviewGroup>
          )}
        /> */}

        {/* 
          <Form.Item name="attachments" label="Add More Attachments">
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </Dragger>
          </Form.Item>
         */}
        {/* {save ? <Button onClick={updateAttachment}>Save</Button> : []} */}
      </Drawer>
    </div>
  );
};

export default TicketAttachmentsDrawer;

/** @format */

import { apiSlice } from "../../../apiSlice";
export const customTypeNameApi = apiSlice.injectEndpoints({
  tagTypes: ["Field"],
  endpoints: (builder) => ({
    updateTypeFieldName: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/setting/updateTypFieldName",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Field"],
    }),
    getTypeFieldNames: builder.query({
      query: () => ({
        url: `/setting/getTypeFieldNames`,
        method: "GET",
      }),
      providesTags: ["Field"],
    }),
  }),
});

export const { useGetTypeFieldNamesQuery, useUpdateTypeFieldNameMutation } =
  customTypeNameApi;

/** @format */

/** @format */

import { Button, Col, Drawer, Row, Space, List } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLazyGetHistoryQuery } from "../../../../redux/services/History/History";
import useResponsiveProfileDrawerSize from "../../../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
import AvatarComponent from "../../../appComponents/AvatarComponent";
const TicketHistoryDrawer = ({
  isTicketHistoryDrawerOpen,
  closeTicketHistoryDrawer,
  selectedRecord,
}) => {
  let drawerSize = useResponsiveProfileDrawerSize();

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(7);
  const [logsData, setLogsData] = useState([]);
  const [totalLogsSize, setTotalLogsSize] = useState(0);
  const [getLogsDataApi, { isFetching }] = useLazyGetHistoryQuery();
  const getLogsData = async () => {
    console.log("hello2", pageNo);
    let data = await getLogsDataApi({
      pageNo: logsData.length ? pageNo + 1 : pageNo,
      pageSize: pageSize,
      id: selectedRecord._id,
    });
    console.log("data.data", data.data);
    setTotalLogsSize(data?.data?.length);
    if (data) {
      if (logsData.length) {
        setPageNo(pageNo + 1);
        setLogsData((prevItems) => {
          let newData = data?.data?.logs;
          return [...prevItems, ...newData];
        });
      } else {
        setLogsData(data.data.logs);
      }
    }
  };
  console.log(logsData);
  useEffect(() => {
    if (isTicketHistoryDrawerOpen) {
      getLogsData();
    }
  }, [isTicketHistoryDrawerOpen]);

  //! dont delete this function can be used to format time
  const formatTime = (date) => {
    const str = date.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return str;
  };
  console.log(
    "totalLogsSize",
    totalLogsSize,
    "logsData.length",
    logsData.length
  );
  const closeTicketHistoryDrawerOperations = () => {
    setLogsData([]);
    setPageNo(1);
    closeTicketHistoryDrawer();
  };
  return (
    <div>
      <Drawer
        width={drawerSize}
        title="History"
        footer={
          <Row justify="right" gutter={16}>
            <Col
              xxs={{ span: 14, offset: 6 }}
              xs={{ span: 12, offset: 10 }}
              sm={{ span: 6, offset: 12 }}
              md={{ span: 6, offset: 12 }}
              lg={{ span: 6, offset: 12 }}
              xl={{ span: 6, offset: 12 }}
              xxl={{ span: 6, offset: 12 }}>
              <Space>
                <Button
                  disabled={totalLogsSize === logsData.length}
                  type="primary"
                  ghost
                  onClick={() => {
                    getLogsData();
                  }}>
                  load more...
                </Button>
              </Space>
            </Col>
          </Row>
        }
        placement="right"
        onClose={closeTicketHistoryDrawerOperations}
        open={isTicketHistoryDrawerOpen}>
        <List
          loading={isFetching}
          itemLayout="horizontal"
          //loadMore={loadMore}
          dataSource={logsData}
          renderItem={(log) => (
            <List.Item className="capitalizeText">
              <List.Item.Meta
                title={
                  <Row gutter={16} >
                 
          <Row justify="start" align="middle" gutter={10}>
    
      {/* <Row className="row">
      <Space>
      <Col flex="1 0 auto" className="column Red"><AvatarComponent
            size="medium"
            avatarStyle={{
              backgroundColor: "green",
              border: "1px solid darkGrey",
            }}
            user={{firstName:"Ghulam",lastName:"Yazdani"}}
          /></Col>
      <Col flex="1 0 auto" className="column Green">Ghulam Yazdani</Col>
      </Space>
    </Row> */}
    <div className="logMain" >
      <AvatarComponent
            size="medium"
            avatarStyle={{
              backgroundColor: "green",
              border: "1px solid darkGrey",
            }}
            user={{firstname:(log.createdBy?.firstName ? log.createdBy?.firstName : ""), lastname:(log?.createdBy?.lastName ? log?.createdBy?.lastName : "")}}
          />

                  <div>
                    <div className="date"><div
                        style={{
                          fontSize: "10px",
                          color: "#72abdb",
                          textTransform: "lowercase",
                        }}>
                        {moment(log.createdOn).fromNow()}
                        </div>
                       </div>
                      <div className="name">{((log.createdBy && Object.keys(log.createdBy).length ===0)?  "Anonymous" :log.createdBy?.firstName ? log.createdBy?.firstName : "" )+ " " + ((log.createdBy && Object.keys(log.createdBy).length ===0)? "":log.createdBy?.lastName ? log.createdBy?.lastName : "")} <span style={{color:"#72abdb"}}>{log.title}</span> </div>
                    </div>
                  </div>
  
                    </Row>
                    {/* <Col span={8}>
                      <div
                        style={{
                          fontSize: "10px",
                          padding: "4px",
                          color: "#72abdb",
                          textTransform: "lowercase",
                        }}>
                        {moment(log.createdOn).fromNow()}
                      </div>
                    </Col> */}
                  </Row>
                }
                description={
                  <>
                    {log.description.split('<br/>').map((st,i)=>{
                      if(i==0){
                        return <p style={{"marginBottom": "6px",fontSize:"12px",marginLeft:"3.2rem"}}>{st}</p>
                      }
                      return <p style={{fontSize:"12px",marginLeft:"3.2rem"}}>{st}</p>
                    })}
                    {/* <div
                      style={{
                        fontSize: "12px",
                        marginTop: "10px",
                      }}>
                      Date:{" "}
                      {moment(formatTime(log.createdOn)).format("Do-MMM-YYYY")}{" "}
                      <br></br>
                      Time:{" "}
                      {moment(formatTime(log.createdOn)).format("hh:mm:ss A")}
                    </div> */}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export default TicketHistoryDrawer;

/** @format */

// /** @format */
import React from "react";
import { Drawer, Button, Row, Col, Descriptions } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import useResponsiveProfileDrawerSize from "../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/features/auth/authSlice";
import AvatarComponent from "../appComponents/AvatarComponent";

const ProfileDrawer = ({ setOpenProfileDrawer, openProfileDrawer, logOut }) => {
  let user = useSelector(selectCurrentUser);
  let drawerSize = useResponsiveProfileDrawerSize();

  //! for independentApp
  // const [updateButton, setUpdateButton] = useState(false);
  // const [isOpenUpdatePopConfirm, setIsOpenUpdatePopConfirm] = useState(false);
  // const editUserProfile = () => {
  //   setUpdateButton(true);
  // };
  // const openPopConfirm = () => {
  //   setIsOpenUpdatePopConfirm(true);
  //   //setUpdateButton(false);
  // };
  // const updateUserProfile = () => {
  //   setIsOpenUpdatePopConfirm(false);
  // };

  const onClose = () => {
    setOpenProfileDrawer(false);
  };
  return (
    <div>
      <Drawer
        // drawerStyle={{ background: "#fafafa" }}
        width={drawerSize}
        title={
          <div>
            <Row gutter={30}>
              <Col offset={10}></Col>
              <Col>
                <Button
                  onClick={logOut}
                  style={{
                    float: "right",
                    fontWeight: "bold",
                  }}
                  icon={<LogoutOutlined />}
                  shape="round"
                  size="large">
                  Log Out
                </Button>
              </Col>
            </Row>
          </div>
        }
        // footer={
        //   <Row justify="right" gutter={16}>
        //     <Col
        //       xxs={{ span: 14, offset: 6 }}
        //       xs={{ span: 12, offset: 10 }}
        //       sm={{ span: 6, offset: 12 }}
        //       md={{ span: 6, offset: 12 }}
        //       lg={{ span: 6, offset: 12 }}
        //       xl={{ span: 6, offset: 12 }}
        //       xxl={{ span: 6, offset: 12 }}>
        //       <Space>
        //         <Button>Reset</Button>
        //         {updateButton ? (
        //           <Popconfirm
        //             title="Would You like to Update Profile"
        //             open={isOpenUpdatePopConfirm}
        //             onConfirm={() => updateUserProfile()}
        //             onCancel={() => setIsOpenUpdatePopConfirm(false)}
        //             okButtonProps={{}}>
        //             <Tooltip placement="left" title="Update Profile">
        //               <Button
        //                 //htmlType="submit"
        //                 onClick={openPopConfirm}
        //                 type="primary">
        //                 Update
        //               </Button>
        //             </Tooltip>
        //           </Popconfirm>
        //         ) : (
        //           <Button
        //             //htmlType="submit"
        //             onClick={editUserProfile}>
        //             Edit
        //           </Button>
        //         )}
        //       </Space>
        //     </Col>
        //   </Row>
        // }
        placement="right"
        onClose={onClose}
        open={openProfileDrawer}>
        <Row align="middle" justify="center">
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "30px",
            }}>
            <div>
              <AvatarComponent
                user={user}
                avatarStyle={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  verticalAlign: "middle",
                  // border: "4px solid darkgrey",
                  fontWeight: "500",
                  fontSize: "38px",
                }}
                size={90}
              />
            </div>
          </Col>
        </Row>
        <Row className="capitalizeText" align="middle" justify="center">
          <Col span={24}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="User Name">
                <span className="lowercaseText">{user?.username}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Email ">
                <span className="lowercaseText">{user?.email}</span>
              </Descriptions.Item>

              <Descriptions.Item label="Sub Domain">
                <span className="lowercaseText"> {user?.name}</span>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Organization"> */}
                {/* {user?.tenant.organizationName} */}
              {/* </Descriptions.Item> */}
            </Descriptions>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default ProfileDrawer;

/** @format */

import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import
  {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    List,
    Row,
    Select,
    Space,
    Switch,
    Tooltip,
    Typography,
  } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import
  {
    useCreateCustomFieldMutation,
    useGetCustomFieldQuery,
    useUpdateCustomFieldMutation,
    useUpdateCustomFieldPickListValueMutation,
  } from "../../../redux/services/customField/customFieldApi";
import useResponsiveProfileDrawerSize from "../../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
import PickListDrawer from "./components/PickListDrawer";
import PickListWithApiDrawer from "./PickListWithApiDrawer";
import formValidations from "../../../utils/formValidations";
let validateMessages = {
  required: "${label} is required!",
};
const customFieldOptions = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "pickList",
    label: "Pick List",
  },
  {
    value: "pickListWithApi",
    label: "Pick list with Api",
  },
  {
    value: "date",
    label: "Date",
  },
  {
    value: "email",
    label: "Email",
  },
];
const rules = [
  {
    required: true,
    message: "Please enter Values",
  },
  {
    max: 30,
    message: "Can contain Upto 30 Characters",
  },
  {
    min: 3,
    message: "At least 3 Characters required",
  },
  {
    whitespace: true,
    message: "Enter characters",
  },
  {validator: formValidations.checkForDuplicateSpaces}
];
const noChangeError = () =>
{
  toast.error("No changes available to update", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });
};
const CreateCustomFieldDrawer = ({
  selectedCustomField,
  isOpenCreateCustomFieldDrawer,
  closeCreateCustomFieldDrawer,
}) =>
{
  const [form] = Form.useForm();
  const { data: customFields, isSuccess, isLoading } = useGetCustomFieldQuery();
  const isCustomFieldInfo = Form.useWatch("isCustomFieldInfo", form);
  const isOptionWithValue = Form.useWatch("isOptionWithValue", form);
  const [createCustomFieldApi, { isLoading: isLoadingCreateCustomField }] =
    useCreateCustomFieldMutation();
  const [updateCustomFieldApi, { isLoading: isLoadingUpdateCustomField }] =
    useUpdateCustomFieldMutation();

  const responsiveDrawerSize = useResponsiveProfileDrawerSize();

  const [fieldType, setFieldType] = useState(null);

  const [isOpenPickListDrawer, setIsOpenPickListDrawer] = useState();
  const [newOptionsAdded, setNewOptionsAdded] = useState([]);

  const [fieldUpdateButton, setFieldUpdateButton] = useState();

  const [isEditedPickListWithApiDetails, setIsEditedPickListWithApiDetails] =
    useState(false);
  const [isOpenPickListWithApiDrawer, setIsOpenPickListWithApiDrawer] =
    useState();
  const [pickListWithApiDrawerValues, setPickListWithApiDrawerValues] =
    useState(null);
  const [isAnyOptionEdited, setIsAnyOptionEdited] = useState(false);

  const updateOptionIntoNewOptionsAdded = (item, editedNewFieldName) =>
  {
    let index = newOptionsAdded.findIndex((option) =>
    {
      return option.name === item.name;
    });
    setNewOptionsAdded((prevItems) =>
    {
      prevItems[index].name = editedNewFieldName?.name;
      return [...prevItems];
    });
  };
  const createOptionIntoNewOptionsAdded = (optionName, optionValue) =>
  {
    setNewOptionsAdded((prevItems) => [
      ...prevItems,
      { key: newOptionsAdded.length + 1, name: optionName, value: optionValue },
    ]);
  };

  const closePickListWithApiDrawer = () =>
  {
    setIsOpenPickListWithApiDrawer(false);
  };

  const selectedFieldType = (value) =>
  {
    setFieldType(value);
    if (value === "pickList" || value === "picklist")
    {
      setIsOpenPickListDrawer(true);
    }
  };

  useEffect(() =>
  {
    if (selectedCustomField)
    {
      form.setFieldsValue(selectedCustomField);
      setFieldType(selectedCustomField.typeOfField);
      setFieldUpdateButton(true);
      setPickListWithApiDrawerValues(
        selectedCustomField.pickListWithApiDetails
      );
    }
  }, [selectedCustomField, setFieldType, fieldType]);

  const closeDrawerOperations = () =>
  {
    closeCreateCustomFieldDrawer();
    setFieldType(null);
    form.resetFields();
    setNewOptionsAdded([]);
    setFieldUpdateButton(false);
    setPickListWithApiDrawerValues(null);
    setIsEditedPickListWithApiDetails(false);
    setIsAnyOptionEdited(false);
  };

  const createCustomField = async (values) =>
  {
    // createCustomFieldApi;
    const validateChange = () =>
    {
      if (
        // fieldType?.toLowerCase() === "picklist" &&
        selectedCustomField?.isRequired === values?.isRequired &&
        selectedCustomField?.isVisibleAtWidget === values?.isVisibleAtWidget &&
        selectedCustomField?.name === values?.name &&
        selectedCustomField?.alias === values?.alias &&
        selectedCustomField?.isCustomFieldInfo === values?.isCustomFieldInfo &&
        (selectedCustomField?.customFieldInfo === values?.customFieldInfo ||
          selectedCustomField?.customFieldInfo === "") &&
        (selectedCustomField.typeOfField === "pickList" ||
          selectedCustomField.typeOfField === "picklist"
          ? isAnyOptionEdited
            ? false
            : true
          : true)
      )
      {
        return true;
      } else
      {
        return false;
      }
    };
    if (selectedCustomField?.typeOfField === "pickListWithApi")
    {
      if (!isEditedPickListWithApiDetails)
      {
        if (validateChange())
        {
          return noChangeError();
        }
      }
    } else
    {
      if (validateChange())
      {
        return noChangeError();
      }
    }

    if (fieldType === "pickListWithApi" && !pickListWithApiDrawerValues)
    {
      return toast.error("Set Pick List Api Details", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }

    const alreadyExists = customFields?.find((field) =>
    {
      if (field?.name.toLowerCase() === values?.name.toLowerCase())
      {
        if (selectedCustomField?._id !== field?._id)
        {
          return field;
        }
      }
    });
    if (alreadyExists)
    {
      return toast.error("Field already exists with same name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }

    if (!fieldUpdateButton)
    {
      try
      {
        const response = await toast.promise(
          createCustomFieldApi({
            ...values,
            pickListValues: newOptionsAdded.map((option) =>
            {
              return { name: option.name, value: option.value };
            }),
            pickListWithApiDetails: pickListWithApiDrawerValues
              ? pickListWithApiDrawerValues
              : {},
          }).unwrap,

          {
            pending: { render: "Please wait...", autoClose: 2000 },
            success: {
              render: "CustomField Created SuccessFully",
              autoClose: 3000,
            },
            error: {
              render({ data })
              {
                if (data?.data?.msg)
                {
                  return data.data.msg;
                } else
                {
                  return "some error occurred";
                }
              },
              autoClose: 5000,
            },
          }
        );

        if (response)
        {
          closeDrawerOperations();
        }
      } catch (error)
      {
        console.log("error", error);
      }
    } else
    {
      try
      {
        const response = await toast.promise(
          updateCustomFieldApi({
            ...values,
            _id: selectedCustomField._id,
            typeOfField: selectedCustomField.typeOfField,
            pickListWithApiDetails: pickListWithApiDrawerValues
              ? pickListWithApiDrawerValues
              : {},
            pickListValues: newOptionsAdded.map((option) =>
            {
              return { name: option.name, value: option.value };
            }),
          }).unwrap,

          {
            pending: { render: "Please wait...", autoClose: 2000 },
            success: {
              render: "CustomField Updated SuccessFully",
              autoClose: 3000,
            },
            error: {
              render({ data })
              {
                if (data?.data?.msg)
                {
                  return data.data.msg;
                } else
                {
                  return "some error occurred";
                }
              },
              autoClose: 5000,
            },
          }
        );
        if (response)
        {
          console.log(response);
          closeDrawerOperations();
        }
      } catch (error)
      {
        console.log("error", error);
      }
    }
  };

  return (
    <div>
      <Drawer
        title={
          fieldUpdateButton ? (
            <span>Update Custom Field</span>
          ) : (
            <span>Create Custom Field</span>
          )
        }
        width={responsiveDrawerSize}
        onClose={closeDrawerOperations}
        open={isOpenCreateCustomFieldDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={
          <Row justify="right" gutter={16}>
            <Col
              xxs={{ span: 14, offset: 6 }}
              xs={{ span: 12, offset: 10 }}
              sm={{ span: 6, offset: 12 }}
              md={{ span: 6, offset: 12 }}
              lg={{ span: 6, offset: 12 }}
              xl={{ span: 6, offset: 12 }}
              xxl={{ span: 6, offset: 12 }}>
              <Space>
                <Button onClick={closeDrawerOperations}>Cancel</Button>
                {!fieldUpdateButton && (
                  <Button
                    disabled={
                      isLoadingCreateCustomField || isLoadingUpdateCustomField
                    }
                    form="customFieldCreationForm"
                    htmlType="submit"
                    type="primary">
                    Create
                  </Button>
                )}
                {fieldUpdateButton && (
                  <Button
                    disabled={
                      isLoadingCreateCustomField || isLoadingUpdateCustomField
                    }
                    form="customFieldCreationForm"
                    htmlType="submit"
                    type="danger">
                    Update
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        }>
        <Form
          form={form}
          onFinishFailed={() =>
          {
            toast.error("Fill Complete Details", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }}
          name="customFieldCreationForm"
          layout="vertical"
          hideRequiredMark
          validateMessages={validateMessages}
          // initialValues={{ role: "ss" }}
          onFinish={createCustomField}>
          <Form.Item rules={rules} hasFeedback name="name" label="Field Name">
            <Input placeholder="Enter field name" />
          </Form.Item>
          <Form.Item name="typeOfField" rules={rules} label="Type of Field">
            <Select
              disabled={selectedCustomField ? true : false}
              showSearch
              placeholder="Select type of Field"
              onChange={selectedFieldType}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={customFieldOptions}
            />
          </Form.Item>
          {(fieldType === "pickList" || fieldType === "picklist") && (
            <Form.Item>
              <Button
                onClick={() =>
                {
                  setIsOpenPickListDrawer(true);
                }}>
                Set Pick List Value Options
              </Button>
            </Form.Item>
          )}
          {(fieldType === "pickList" || fieldType === "picklist") && (
            <Form.Item
              name="isOptionWithValue"
              label="Want Options with Values?"
              valuePropName="checked">
              <Switch disabled={selectedCustomField ? true : false} />
            </Form.Item>
          )}
          {(fieldType === "pickListWithApi" ||
            fieldType?.toLowerCase() === "picklistwithapi") && (
              <Form.Item>
                <Button
                  onClick={() =>
                  {
                    setIsOpenPickListWithApiDrawer(true);
                  }}>
                  Set Pick List Api Details
                </Button>
              </Form.Item>
            )}
          <Form.Item
            name="isRequired"
            label={
              <div>
                <span>{"Mandatory Field (is Required)"}</span>{" "}
                {(selectedCustomField?.typeOfField === "picklistwithapi" ||
                  selectedCustomField?.typeOfField === "pickListWithApi") && (
                    <Tooltip
                      // trigger="click"
                      destroyTooltipOnHide={false}
                      title="If you make it mandatory and api sends no data then you will not be able to create ticket">
                      <InfoCircleOutlined
                        style={{ marginLeft: "4px", color: "gray" }}
                      />
                    </Tooltip>
                  )}
              </div>
            }
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            name="isVisibleAtWidget"
            label="Widget Field (is Visible at widget)"
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            name="alias"
            rules={[
              {
                whitespace: true,
                message: "Enter characters",
              },
              {
                max: 30,
                message: "Can contain Upto 30 Characters",
              },
              {
                min: 2,
                message: "At least 2 Characters required",
              },
            ]}
            label={
              <div>
                <span>Alias Name ( Optional )</span>{" "}
                <Tooltip
                  // trigger="click"
                  title="Alias Name is used as dependent name in pickList with api details and it must be same as params name in APi ">
                  <InfoCircleOutlined
                    style={{ marginLeft: "4px", color: "gray" }}
                  />
                </Tooltip>
              </div>
            }>
            <Input placeholder="Enter Alias Name" />
          </Form.Item>
          <Form.Item
            name="isCustomFieldInfo"
            label="Add Info to Field"
            valuePropName="checked">
            <Switch />
          </Form.Item>

          {isCustomFieldInfo && (
            <Form.Item
              name="customFieldInfo"
              label="Add Info to Field"
              rules={[
                { required: true },

                {
                  whitespace: true,
                  message: "Enter characters",
                },
                {
                  min: 3,
                  message: "At least 3 Characters required",
                },
              ]}>
              <Input.TextArea rows={2} />
            </Form.Item>
          )}
        </Form>
        {(fieldType === "pickList" || fieldType === "picklist") && (
          <PickListDrawer
            newOptionsAdded={newOptionsAdded}
            updateOptionIntoNewOptionsAdded={updateOptionIntoNewOptionsAdded}
            setIsOpenPickListDrawer={setIsOpenPickListDrawer}
            isOpenPickListDrawer={isOpenPickListDrawer}
            selectedCustomField={selectedCustomField}
            rules={rules}
            setIsAnyOptionEdited={setIsAnyOptionEdited}
            createOptionIntoNewOptionsAdded={createOptionIntoNewOptionsAdded}
            isOptionWithValue={isOptionWithValue}
          />
        )}
        {fieldType === "pickListWithApi" && (
          <PickListWithApiDrawer
            setPickListWithApiDrawerValues={setPickListWithApiDrawerValues}
            closePickListWithApiDrawer={closePickListWithApiDrawer}
            isOpenPickListWithApiDrawer={isOpenPickListWithApiDrawer}
            selectedCustomField={selectedCustomField}
            pickListWithApiDrawerValues={pickListWithApiDrawerValues}
            setIsEditedPickListWithApiDetails={
              setIsEditedPickListWithApiDetails
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default CreateCustomFieldDrawer;

/** @format */

import { createSlice } from "@reduxjs/toolkit";

const permissionSlice = createSlice({
  name: "permission",
  initialState: { allPermissions: [] },
  reducers: {
    setPermissions: (state, action) => {
      const { permissions } = action.payload;
      state.allPermissions = permissions;
    },
  },
});

export const { setPermissions } = permissionSlice.actions;

export default permissionSlice.reducer;

export const selectAllPermissions = (state) => state.permission.allPermissions;

/** @format */

import { CheckCircleOutlined } from "@ant-design/icons";
import { Descriptions, Modal, Spin } from "antd";
import moment from "moment";
import React from "react";
import { useGetTicketQuery } from "../../redux/services/ticket/ticketApi";

import { useGetTypeFieldNamesQuery } from "../../redux/services/settings/customTypeName/customTypeNameApi";
import { useGetCustomFieldQuery } from "../../redux/services/customField/customFieldApi";
import useGenerateStatusName from "../../utils/customHooks/ticketsRelatedHooks/useGenerateStatusName";

export default function ViewTicketModal({
  isTicketModalOpen,
  setIsTicketModalOpen,
  ticket,
}) {
  const { data: ticketInfo, isLoading: isLoadingTicketInfo } =
    useGetTicketQuery(ticket?.id);
  let ticketData = ticketInfo;

  const {
    data: typeFieldNames,
    isLoading: isTypeFieldNamesLoading,
    isSuccess: isTypeFieldNamesSuccess,
  } = useGetTypeFieldNamesQuery();
  const generateStatusName = useGenerateStatusName();
  const { data: customFields, isSuccess: customFieldsIsSuccess } =
    useGetCustomFieldQuery();
  const customFieldValue = (field) => {
    let correspondingId = ticketData.customFields[field._id];
    if (field.typeOfField === "pickList") {
      if (field.isOptionWithValue) {
        correspondingId = JSON.parse(ticketData.customFields[field._id])._id;
      }
      let object = field.pickListValues.find((value) => {
        return value._id === correspondingId;
      });
      if (object) {
        return object.name;
      }
    } else if (field.typeOfField === "pickListWithApi") {
      return ticketData.customFields[field._id];
    } else if (field.typeOfField === "date") {
      return moment(ticketData.customFields[field._id]).format("Do-MMM-YYYY");
    } else {
      return ticketData.customFields[field._id];
    }
  };

  const checkField = (customField) => {
    let result = false;
    let customFields = ticketData?.customFields;
    let customFieldObjectEntries = Object.entries(customFields);
    customFieldObjectEntries.forEach((entry) => {
      if (entry[0] === customField._id) {
        result = true;
        return;
      }
    });
    return result;
  };
  return (
    <div>
      <Modal
        width={900}
        centered
        open={isTicketModalOpen}
        onCancel={() => setIsTicketModalOpen(false)}
        footer="">
        {isLoadingTicketInfo && <Spin></Spin>}
        {!ticketInfo ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            {" "}
            <Spin size="default"/>
          </div>
        ) : (
          <Descriptions
            title={
              <div className="capitalizeText">
                <span>
                  <CheckCircleOutlined
                    style={{ color: "#1990FF", marginRight: "5px" }}
                  />
                </span>
                <span>{ticketData?.title}</span>
              </div>
            }
            size="small"
            bordered
            column={{
              xxl: 3,
              xl: 2,
              lg: 2,
              md: 2,
              sm: 2,
              xs: 1,
            }}>
            <Descriptions.Item
              span={1}
              className="capitalizeText"
              label="Created On">
              {/* <h3>Created on:</h3> */}
              {moment(ticketData?.createdOn).format("h:mm A ,Do MMM YYYY")}
            </Descriptions.Item>
            <Descriptions.Item
              span={1}
              className="capitalizeText"
              label="status">
              {/* <h3>Created on:</h3> */}
              {generateStatusName(ticketData?.status)}
            </Descriptions.Item>
            <Descriptions.Item
              span={1}
              className="capitalizeText"
              label="Deployment">
              {/* <h3>Created on:</h3> */}
              {ticketData?.deployment.name}
            </Descriptions.Item>
            <Descriptions.Item
              span={1}
              className="capitalizeText"
              label="Owner">
              {/* <h3>Created on:</h3> */}
              {ticketData?.owner?.firstName}
            </Descriptions.Item>
            <Descriptions.Item
              span={1}
              className="capitalizeText"
              label={typeFieldNames?.superType}>
              {/* <h3>Created on:</h3> */}
              {ticketData?.type[0]}
            </Descriptions.Item>

            {customFieldsIsSuccess &&
              customFields?.map((customField, key) => {
                let resultOfCheckField = checkField(customField);
                if (customField?.isActive && resultOfCheckField) {
                  if (customField.typeOfField === "pickListWithApi") {
                    if (!customFieldValue(customField).length) {
                      return <></>;
                    }
                    let selectedOptionsValues = [];
                    if (ticketData?.customFields) {
                      let valueOfField = customFieldValue(customField);
                      if (
                        customField?.pickListWithApiDetails
                          ?.isRequireMultipleInputsAsPerSelection
                      ) {
                        valueOfField?.forEach((item) => {
                          selectedOptionsValues.push(
                            <li>{`${item}-${
                              customField?.pickListWithApiDetails
                                ?.selectedOptionsLabelName
                            }: ${
                              ticketData?.customFields[item]
                                ? ticketData?.customFields[item]
                                : " Nil"
                            }`}</li>
                          );
                        });
                      } else {
                        valueOfField?.forEach((item) => {
                          selectedOptionsValues.push(<li>{item}</li>);
                        });
                      }
                    }
                    return (
                      <Descriptions.Item
                        key={key}
                        label={
                          <div className="capitalizeText">
                            {customField?.name}
                          </div>
                        }>
                        <ul>
                          {selectedOptionsValues.map((value, key) => (
                            <div key={key}>{value}</div>
                          ))}
                        </ul>
                      </Descriptions.Item>
                    );
                  } else {
                    return (
                      <Descriptions.Item
                        key={key}
                        label={
                          <div className="capitalizeText">
                            {customField?.name}
                          </div>
                        }>
                        {ticketData?.customFields
                          ? customFieldValue(customField)
                          : ""}
                      </Descriptions.Item>
                    );
                  }
                }
              })}
            <Descriptions.Item
              xxs={{ span: 1 }}
              xs={{ span: 1 }}
              sm={{ span: 1 }}
              md={{ span: 2 }}
              lg={{ span: 2 }}
              xl={{ span: 2 }}
              xxl={{ span: 3 }}
              className="capitalizeText"
              label="description">
              {ticketData?.description}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    </div>
  );
}

/** @format */

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../redux/features/auth/authSlice";
import { useCookies } from "react-cookie";
import { ErrorNotAuthorized } from "../../pages/errorPages";

const IsAuthenticated = () => {
  const [cookies] = useCookies();
  const location = useLocation();
  const user = useSelector(selectCurrentUser);
  let token = useSelector(selectCurrentToken);
  if (!token) {
    token = cookies.token;
  }
  if (token && token !== "undefined") {
    return <Outlet />;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};
export default IsAuthenticated;

//!Last one as per independent app use case
// const IsAuthenticated = () => {
//   const [cookies] = useCookies();
//   const location = useLocation();
//   const user = useSelector(selectCurrentUser);
//   let token = useSelector(selectCurrentToken);
//   if (!token) {
//     token = cookies.token;
//   }
//   if (token && token !== "undefined") {
//     if (user?.isSuperAdmin) {
//       return <ErrorNotAuthorized />;
//       // return <Navigate to="/superAdmin" state={{ from: location }} replace />;
//     } else {
//       return <Outlet />;
//     }
//   } else {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }
// };

/** @format */

import { apiSlice } from "../../apiSlice";

export const permissionApi = apiSlice.injectEndpoints({
  tagTypes: ["Permission"],
  endpoints: (builder) => ({
    createPermission: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/permission/create",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Permission"],
    }),
    getAllPermissions: builder.query({
      query: () => ({
        url: `/permission/getAll`,
        method: "GET",
      }),
      providesTags: ["Permission"],
    }),
  }),
});

export const { useGetAllPermissionsQuery, useCreatePermissionMutation } =
  permissionApi;

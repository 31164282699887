/** @format */

import { Col, Tag, Row, List, Button, Typography, Switch, Spin, Tooltip, Segmented, Result } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Editor from "../../../../component/appComponents/editor/editor";

import {
  useGetTriggerSettingsQuery,
  useUpdateTriggerSettingMutation,
} from "../../../../redux/services/settings/emailTriggers/emailTriggersApi";
import { InfoCircleOutlined } from "@ant-design/icons";
import useWindowSize from "../../../../utils/customHooks/general/useWindowSize";
export default function EmailTemplates()
{
  const screenSize = useWindowSize();
  const [template, setTemplate] = useState(null);
  const [templateBody, setTemplateBody] = useState(null);
  const quillRef = React.useRef(null);
  const [disableBtns,setDisableBtns]=useState(true);
  let { data: getData, refetch, isLoading } = useGetTriggerSettingsQuery();
  const [updateTrigger] = useUpdateTriggerSettingMutation();
  const [customButtons, setCustomButtons] = useState([]);
  //No need to use this useeffect we can directly use getData
  useEffect(()=>{
    if(getData){
      setData(getData.triggers);
      setCustomButtons(getData.customButton)
    }
    console.log("hello")
  },[getData]);
  console.log(template,'template changes')
  const [selectedType, setSelectedType] = useState(null)
  const [activeItem, setActiveItem] = useState(null);
  const [data,setData]=useState([])
  
  const sendUpdateData = async (item,Name,bool) => {
    let response = await updateTrigger({
      ...item,
    });
    if (response?.data)
    {
      if (!bool)
      {
        toast.info(`${ template.name } Email will not be Sent to ${ Name }`);
      } else
      {
        toast.success(`${ template.name } Email will be Sent to ${ Name }`)
      }

    }  
 
    refetch();
  };
  const handleInsertString = (value) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const selection = quill.getSelection();
      if (selection) {
        const cursorPosition = selection.index;
        quill.insertText(cursorPosition, value);
      } 
    }
  };
  if (screenSize?.width < 1200)
  {
    return<Result
    status="warning"
    title="This feature is available only for Desktop or Laptop."    
      />    
  }
  console.log("selected type",selectedType)
  if (isLoading) {
    return (
      <div
        style={{
          height: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
             <Spin size="default"/>
      </div>
    );
  }
  return (
    <div
      style={{
        textAlign: "center",
      }}>
      <Row gutter={24}>
        <Col span={6}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
                  <List
                    header={
                      <div
                    style={{      
                      fontWeight: "bolder",
                      textTransform: "capitalize",
                          
                        }}>
                        {" "}
                        {"Email Types"}
                      </div>
                    }
                    bordered
                    >
                    {data?.map((x, i) => {
                     return (
                          <List.Item
                            key={i}
                            style={
                              activeItem &&
                              activeItem === i.toString()
                                ? {
                                    background: "#1890ff",
                                    color:"white"
                                  }
                                : {}
                            }
                            onClick={() => {
                              setTemplate(x);
                              setSelectedType(x.types[0])
                              setTemplateBody(x.types[0].body);
                              setActiveItem(i.toString());
                            }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}>
                              {x.name}
                              <div
                                style={{
                                  marginLeft: "5px",
                                }}>
                                  <Tag color={`${!x.active?'red':'green'}`}>
                                    {x.active ? "Active" : "InActive"}
                                  </Tag>
                              </div>
                            </div>
                          </List.Item>
                        );
                    })}
                  </List>
            </Col>
          </Row>
        </Col>
        
        
  <Col  span={18}>
      {activeItem && 
      <>
      <Row  span={10} style={{height:"8%"}}>
        <Col span={10} style={{display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
        <h2>{`${template.name} Email`}</h2>
        <Tooltip
            title={`${template.name} Email will be sent to the selected users `}>
                  <InfoCircleOutlined
                    style={{ marginLeft: "8px",marginBottom:"7px", color: "gray" }}
                  />
        </Tooltip>
        </Col>
      </Row>
      </>}
      {activeItem && data[activeItem].types.length>1 && <Row style={{marginBottom:"12px"}}>
      <Segmented onChange={(x)=>{
          var tmp = data[activeItem].types.find((ele)=> ele.keyValue === x);
          setSelectedType(tmp);
          setTemplateBody(tmp.body);
          
      }} options={template.types.map((type)=>{
        return {
          label:type.name,
          value:type.keyValue
        }
      })} style={{ margin: "5px 0" }} 
      />
      
      </Row>}
      
      <Row style={{marginBottom:"10px",display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
      { activeItem && 
                selectedType.sendTo.map((user,i)=>{
                  return (
                    <Col key={i} style={{display:"flex",alignItems:"center",justifyContent:"space-between",marginRight:"15px"}}>
                      <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                          <p style={{margin:"0 10px 0 0"}}>{user.name}</p>
                          <Switch 
                            checked={user.value}
                            onChange={(e, i) => {
                              var newData = JSON.parse(JSON.stringify(data));
                              var idx1= newData[activeItem].types.findIndex((val)=>val.keyValue == selectedType.keyValue)
                              var idx2 = newData[activeItem].types[idx1].sendTo.findIndex((val)=>val.key == user.key);
                              newData[activeItem].types[idx1].sendTo[idx2].value=e;
                              if(e && !newData[activeItem].active){
                                newData[activeItem].active=true;
                              }else{
                                let flag = false;
                                for(let i=0;i<newData[activeItem].types.length;i++){
                                  for(let j=0;j<newData[activeItem].types[i].sendTo.length;j++){
                                    if(newData[activeItem].types[i].sendTo[j].value){
                                      flag = true; break;
                                    }
                                  }
                                }
                                if(!flag){
                                  newData[activeItem].active=false;
                                }
                             }
                              setData(newData);
                              setSelectedType(newData[activeItem].types[idx1])
                              setTemplate(newData[activeItem]);
                              sendUpdateData(newData[activeItem],user.name,e)
                            }}
                            />
                      </div>
                    </Col>
                  )
                })
              }
      </Row>
      <Row span={12}>
      <Col span={22}>
          <Row span={24}>
            <Row span={24}>
          {activeItem && customButtons.map((customButton, key) => {
            var flag = customButton.group.find((gp) => gp === selectedType.keyValue);
            console.log(customButtons,selectedType.keyValue ,data)
            if(flag){
              return <Button style={{margin:"0 2px 5px 0"}}
                key={key}
                onClick={() => {
                  handleInsertString(customButton.value);
                }}
                disabled={disableBtns}
              >
                {customButton.name}
              </Button>
            }
          })}
          </Row>
          <Row span={24} style={{width:"100%"}}>
          {template ? (
            <Editor
              setDisableBtns={setDisableBtns}
              setTemplate={setTemplate}
              template={template}
              selectedType={selectedType}
              setTemplateBody={setTemplateBody}
              templateBody={templateBody}
              refetch={refetch}
              updateTrigger={updateTrigger}
              quillRef={quillRef}
            />
          ) : (
            <div style={{ margin: "20%",width:"100%" }}>
              <Typography.Title level={3} type="secondary">
                Select Any Trigger Tab
              </Typography.Title>
            </div>
          )}
          </Row>
          </Row>
        </Col>

      </Row>
  </Col>
</Row>

      <div></div>
    </div>
  );
}

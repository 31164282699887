/** @format */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import TicketTable from "../../../../component/tables/ticketsTable/TicketTable";
import { selectAllFilters } from "../../../../redux/features/filter/filterSlice";
import { useGetAllTicketsQuery } from "../../../../redux/services/ticket/ticketApi";
import TicketsBoardHeader from "./TicketsBoardHeader";

const TicketsBoard = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const allFilters = useSelector(selectAllFilters);
  const {
    data: allTicketsData,
    isSuccess: isSuccessGetAllTicketsData,
    isFetching: isFetchingGetAllTicketsData,
    refetch: refetchTickets,
  } = useGetAllTicketsQuery({
    allFilters: JSON.stringify(allFilters),
    pageNo: pageNo,
    pageSize: pageSize,
    // reporter: user._id,
  });
  if (isSuccessGetAllTicketsData) {
    console.log("all tickets", allTicketsData);
  }

  return (
    <>
      <div>
        <TicketsBoardHeader
          allTicketsData={allTicketsData}
          isSuccessGetAllTicketsData={isSuccessGetAllTicketsData}
          refetchTickets={refetchTickets}
          isFetchingGetAllTicketsData={isFetchingGetAllTicketsData}
        />
        <TicketTable
          allTicketsData={allTicketsData}
          setPageNo={setPageNo}
          isSuccessGetAllTicketsData={isSuccessGetAllTicketsData}
          isFetchingGetAllTicketsData={isFetchingGetAllTicketsData}
          setPageSize={setPageSize}
        />
      </div>
    </>
  );
};

export default TicketsBoard;

/** @format */

import { apiSlice } from "../../apiSlice";

export const userApi = apiSlice.injectEndpoints({
  tagTypes: ["User"],
  endpoints: (builder) => ({
    //   registerUser: builder.mutation({
    //     query: ({ ...rest }) => ({
    //       url: "/register",
    //       method: "POST",
    //       body: rest,
    //     }),
    //     invalidatesTags: ["User"],
    //   }),
    // updatePassword: builder.mutation({
    //   query: ({ ...rest }) => ({
    //     url: "/user/updatePassword",
    //     method: "PATCH",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["User"],
    // }),
    // updateUser: builder.mutation({
    //   query: ({ ...rest }) => ({
    //     url: "/user/updateUser",
    //     method: "PATCH",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["User"],
    // }),
    // changeActiveStatus: builder.mutation({
    //   query: ({ ...rest }) => ({
    //     url: "/user/changeActiveStatus",
    //     method: "PATCH",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["User"],
    // }),
    // deleteUser: builder.mutation({
    //   query: ({ ...rest }) => ({
    //     url: "/user/deleteUser",
    //     method: "PATCH",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["User"],
    // }),
    // createUser: builder.mutation({
    //   query: ({ ...rest }) => ({
    //     url: "/createUser",
    //     method: "POST",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["User"],
    // }),
    getAllUsers: builder.query({
      query: (paramsForGetAllUsers) => ({
        url: `/user/getAllUsers/${paramsForGetAllUsers}`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    getMe: builder.query({
      query: () => ({
        url: "/user/getMe",
        method: "GET",
      }),
      // provideTags: ["User"],
    }),
    searchUsers: builder.query({
      query: ({ ...rest }) => ({
        url: `/user/searchUser?text=${rest.text}&role=${rest.role}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useCreateUserMutation,
  useUpdatePasswordMutation,
  useGetMeQuery,
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useChangeActiveStatusMutation,
  useUpdateUserMutation,
  useSearchUsersQuery,
} = userApi;

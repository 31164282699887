/** @format */

import { DeleteTwoTone,  ExclamationCircleOutlined } from "@ant-design/icons";
import { Col,  List, Modal, Result, Row, Spin } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import
{
  useDeleteSlaMutation,
  useGetAllSLAQuery,
} from "../../../../redux/services/settings/SLA/SLAapi";
import SLAform from "../../../SLA/SLAform";
import useWindowSize from "../../../../utils/customHooks/general/useWindowSize";

export default function SLA()
{ const screenSize = useWindowSize();
  
  const [clearSlaValues, setClearSlaValues] = useState(false);
  const { data: slaList, refetch, isLoading } = useGetAllSLAQuery();
  console.log("sla list", slaList)
  const [slaForm, setSlaForm] = useState({});
  const [emailBody, setEmailBody] = useState(null);
  const [editSla, setEditSla] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [deleteSlaApi] = useDeleteSlaMutation();
  const nameAlreadyExists = (nameOfNewSla) =>
  {
    let found = slaList.sla.find((eachSla) => eachSla?.name?.toLowerCase() === nameOfNewSla?.toLowerCase())
    if (found) return true
    else return false
  }
  const deleteSLA = (id) =>
  {
    const okDeleteSLA = async () =>
    {
      try
      {
        let response = await toast.promise(deleteSlaApi({ id: id }).unwrap, {
          success: {
            render: "Deleted successfully",
            autoClose: 3000,
          },
          error: {
            render({ data })
            {
              if (data?.data?.msg)
              {
                return data.data.msg;
              } else
              {
                return "some error occurred";
              }
            },
            autoClose: 5000,
          },
        });
      } catch (error)
      {
        console.log("error", error);
      }
    }
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do You Want to Delete SLA ?`,
      okText: "Yes",
      cancelText: "No",
      onOk()
      {
        okDeleteSLA();
      },
    });
  };

  if (screenSize?.width < 1200)
  {
    return<Result
    status="warning"
    title="This feature is available only for Desktop or Laptop."    
      />    
  }
  

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Spin size="default"/>
        </div>
      ) : (
        <Row gutter={16}>
        <Col span={5}>
          <List
            header={<div style={{ fontWeight: "bolder", textAlign: "center", fontSize: "16px" }}> SLA</div>}
            bordered
            span={10}
            style={{ minWidth: 200 }}
          >
            {slaList?.sla?.map((x, key) =>
            {
              return (
                <List.Item
                  key={key}
                  style={
                    selectedTemplate === key
                      ? {
                        background: "rgb(24, 144, 255)",
                        color: "white"
                      }
                      : {}
                  }
                  onClick={() =>
                  {
                    // setClearSlaValues(true)
                    setSelectedTemplate(key);
                    setSlaForm(x);
                    setEmailBody(x.emailBody);
                    setEditSla(true);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {x.name}{" "}
                    <div>
                      {/* <EditOutlined
                        onClick={() =>
                        {
                          setSlaForm(x);
                          setEmailBody(x.emailBody);
                          setEditSla(true);
                        }}
                        style={{ fontSize: "16px", margin: 10 }}
                      /> */}
                      <DeleteTwoTone
                        onClick={() =>
                        {

                          deleteSLA(x._id);
                          setClearSlaValues(true)
                        }}
                        twoToneColor="red"
                        style={{ fontSize: "16px" }}
                      />
                    </div>
                  </div>
                </List.Item>
              );
            })}
          </List>
        </Col>
        <Col span={19}>
          <SLAform
            slaForm={slaForm}
            setSlaForm={setSlaForm}
            emailBody={emailBody}
            setEmailBody={setEmailBody}
            editSla={editSla}
            setEditSla={setEditSla}
            refetch={refetch}
            fields={slaList?.customButton}
            setSelectedTemplate={setSelectedTemplate}
            nameAlreadyExists={nameAlreadyExists}
            setClearSlaValues={setClearSlaValues}
            clearSlaValues={clearSlaValues}
          />
        </Col>
      </Row>
      )}
     
    </>
  );
}

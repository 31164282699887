/** @format */

// /** @format */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectCurrentUser, setLogOut } from "./features/auth/authSlice";

import { Cookies } from "react-cookie";
import config from "../config"
import axios from "axios";
const cookies = new Cookies();
//export const baseUrl = "http://localhost:3010";
//export const baseUrl = "http://18.140.214.202:3001";

const baseQuery = fetchBaseQuery({
  baseUrl: config.baseUrl,

  prepareHeaders: (headers, { getState }) => {
    let token = getState().auth.token;

    if (!token) {
      token = cookies.get("token");
    }

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  
  if (result?.error?.status === 401) {
    if (api.getState(selectCurrentUser).auth.user)
    {
      let token = api.getState(selectCurrentUser).auth.token;
      if (!token) 
      {
        token = cookies.get("token");
      }
      let url = config.baseUrl + "/logOut"
      const headers = {
        'Authorization': 'Bearer '+token, // Example: Bearer token       
      };
      try {
        await axios.post(url,{logOut:true},{headers})
      } catch (error) {
        console.log("error in baseQueryWithReauth",error)
      }
    }
    
    api.dispatch(setLogOut());
    if (cookies.get("token")) {
      cookies.remove("token", { path: "/" });
    }
    if (window.location.href !== window.location.origin + "/login") {
      window.location.replace(window.location.origin + "/login");
    }

    localStorage.clear();
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});

/** @format */

import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  List,
  Row,
  Tag,
  Typography,
} from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useUpdateCustomFieldPickListValueMutation } from "../../../../redux/services/customField/customFieldApi";
import useResponsiveProfileDrawerSize from "../../../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
let validateMessages = {
  required: "${label} is required!",
};

const PickListDrawer = ({
  setIsOpenPickListDrawer,
  isOpenPickListDrawer,
  newOptionsAdded,
  updateOptionIntoNewOptionsAdded,
  createOptionIntoNewOptionsAdded,
  selectedCustomField,
  rules,
  setIsAnyOptionEdited,
  isOptionWithValue,
}) => {
  const [optionsForm] = Form.useForm();
  const responsiveDrawerSize = useResponsiveProfileDrawerSize();
  const [updateCustomFieldPickListValueApi] =
    useUpdateCustomFieldPickListValueMutation();
  const [existingOptions, setExistingOptions] = useState(
    selectedCustomField?.pickListValues
      ? selectedCustomField?.pickListValues
      : []
  );

  const [isUpdateButtonVisible, setIsUpdateButtonVisible] = useState({
    visible: false,
    key: null,
  });
  const [editedExistingOptionName, setEditedExistingOptionName] =
    useState(null);
  const [editedNewOptionName, setEditedNewOptionName] = useState(null);
  const clearPickListOptions = () => {
    setIsUpdateButtonVisible({ visible: null, key: null });
    setEditedExistingOptionName(null);
    setEditedNewOptionName(null);
  };
  const createNewOption = (optionName, rowItem, optionValue) => {
    createOptionIntoNewOptionsAdded(optionName, optionValue);
    if (optionName) {
      optionsForm.resetFields();
    }
  };
  const updateNewAddedFieldOption = (optionName, rowItem) => {
    updateOptionIntoNewOptionsAdded(rowItem, editedNewOptionName);
    setIsUpdateButtonVisible({ visible: null, key: null });
  };

  const updateExistingFieldOption = async () => {
    setIsUpdateButtonVisible({ visible: null, key: null });
    let idOfOption = editedExistingOptionName.key;
    let updateOptions = {
      idOfOption: idOfOption,
      name: editedExistingOptionName.name.trim(),
      _id: selectedCustomField._id,
    };

    try {
      const response = await toast.promise(
        updateCustomFieldPickListValueApi(updateOptions).unwrap,

        {
          pending: { render: "Please wait...", autoClose: 2000 },
          success: {
            render: "Field Updated SuccessFully",
            autoClose: 3000,
          },
          error: {
            render({ data }) {
              if (data?.data?.msg) {
                return data.data.msg;
              } else {
                return "some error occurred";
              }
            },
            autoClose: 5000,
          },
        }
      );

      if (response) {
        let index = existingOptions.findIndex((option) => {
          return option?._id === editedExistingOptionName.key;
        });

        if (index > -1) {
          setExistingOptions((prevItems) => {
            let test = JSON.parse(JSON.stringify(prevItems));
            test[index].name = editedExistingOptionName?.name;
            return [...test];
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fieldOptionAlreadyExist = (valueName) => {
    if (newOptionsAdded.length) {
      let index = newOptionsAdded.findIndex(
        (option) => option.name.toLowerCase() === valueName.toLowerCase()
      );
      if (index >= 0) {
        return true;
      }
    }
    if (existingOptions.length) {
      let index = existingOptions.findIndex(
        (option) => option.name.toLowerCase() === valueName.toLowerCase()
      );
      if (index >= 0) {
        return true;
      }
    }
    return false;
  };
  const fieldOptionValueAlreadyExist = (optionValue) => {
    if (newOptionsAdded.length) {
      let index = newOptionsAdded.findIndex(
        (option) => option.value.toLowerCase() === optionValue.toLowerCase()
      );
      if (index >= 0) {
        return true;
      }
    }
    if (existingOptions.length) {
      let index = existingOptions.findIndex(
        (option) => option.value.toLowerCase() === optionValue.toLowerCase()
      );
      if (index >= 0) {
        return true;
      }
    }
    return false;
  };
  const checkValidationsAndUpdateOptionName = (
    optionName,
    updateFunction,
    rowItem,
    optionValue
  ) => {
    let testValue = new RegExp(
      /^[^~`!@#$%^&*()_+=[\]{}\\|;:'",.<>?]*[a-zA-Z0-9\s]+$/
    );
    if (fieldOptionAlreadyExist(optionName)) {
      return toast.error("Option Name Already Exist", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    // if (isOptionWithValue) {
    //   if (fieldOptionValueAlreadyExist(optionValue)) {
    //     return toast.error("Option with same value already Exist", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       autoClose: 3000,
    //     });
    //   }
    // }

    if (optionName.length <= 2) {
      return toast.error("Name must be greater than 2 characters", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    if (!testValue.test(optionName)) {
      return toast.error("Name should not contain special Characters", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    clearPickListOptions();
    setIsAnyOptionEdited(true);
    return updateFunction(optionName, rowItem, optionValue);
  };
  return (
    <div>
      <Drawer
        title="Create Pick List Options"
        width={responsiveDrawerSize}
        onClose={() => {
          optionsForm.resetFields();
          setIsOpenPickListDrawer(false);
        }}
        open={isOpenPickListDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}>
        <Form
          validateMessages={validateMessages}
          form={optionsForm}
          layout="vertical"
          onFinishFailed={() => {
            toast.error("Fill Complete Details", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }}
          onFinish={(values) => {
            let optionName = values.optionName;
            let optionValue = values.optionValue ? values.optionValue : null;
            checkValidationsAndUpdateOptionName(
              optionName,
              createNewOption,
              null,
              optionValue
            );
          }}>
          <Form.Item
            name="optionName"
            label="Option Name"
            rules={[
              ...rules,
              {
                pattern: /^[^~`!@#$%^&*()_+=[\]{}\\|;:'",.<>?]*[a-zA-Z0-9\s]+$/,
                message: "Special Characters not allowed",
              },
            ]}>
            <Input></Input>
          </Form.Item>
          {isOptionWithValue && (
            <Form.Item
              name="optionValue"
              label="Option Value"
              rules={[
                { required: true, message: "Please enter Values" },
                {
                  max: 30,
                  message: "Can contain Upto 30 Characters",
                },
                {
                  min: 1,
                  message: "At least 1 Character required",
                },
                {
                  whitespace: true,
                  message: "Enter characters",
                },
                {
                  pattern:
                    /^[^~`!@#$%^&*()_+=[\]{}\\|;:'",.<>?]*[a-zA-Z0-9\s]+$/,
                  message: "Special Characters not allowed",
                },
              ]}>
              <Input></Input>
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Option
            </Button>
          </Form.Item>
        </Form>
        {newOptionsAdded.length ? (
          <div style={{ color: "red", marginBottom: "20px" }}>
            Note: Click On Update Button of Last Drawers to Update Options
          </div>
        ) : (
          <></>
        )}

        {existingOptions?.length || newOptionsAdded.length ? (
          <List
            bordered
            header={
              <Row>
                <Col
                  xs={{ span: 20 }}
                  sm={{ span: 14 }}
                  md={{ span: 16 }}
                  lg={{ span: 16 }}
                  xl={{ span: 18 }}
                  xxl={{ span: 22 }}>
                  <div style={{ fontWeight: "bolder" }}>Pick List Options</div>
                </Col>
                <Col
                  xs={{ span: 4 }}
                  sm={{ span: 10 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                  xxl={{ span: 2 }}>
                  {isUpdateButtonVisible && (
                    <Typography.Link
                      onClick={() => {
                        clearPickListOptions();
                      }}>
                      Clear
                    </Typography.Link>
                  )}
                </Col>
              </Row>
            }>
            {existingOptions.map((item) => (
              <List.Item key={item._id} style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                  <Col span={14}>
                    <Row gutter={[4, 4]}>
                      <Col span={24}>
                        <Typography.Text
                          style={{ marginTop: "1px" }}
                          editable={
                            isUpdateButtonVisible.visible &&
                            isUpdateButtonVisible.key === item._id
                              ? {
                                  maxLength: 30,
                                  editing: true,
                                  onChange: (value) => {
                                    setEditedExistingOptionName({
                                      name: value,
                                      key: item._id,
                                    });
                                  },
                                }
                              : false
                          }>
                          {editedExistingOptionName?.key === item._id &&
                          editedExistingOptionName
                            ? editedExistingOptionName.name
                            : item.name}
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        {isOptionWithValue && (
                          <Tag style={{ marginRight: "10px" }} disabled={true}>
                            Value:{item.value}
                          </Tag>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10}>
                    {isUpdateButtonVisible.visible &&
                      isUpdateButtonVisible.key === item._id && (
                        <Button
                          style={{ marginRight: "10px" }}
                          icon={<ReloadOutlined />}
                          onClick={() => {
                            clearPickListOptions();
                          }}
                          type="blue"></Button>
                      )}
                    {isUpdateButtonVisible.visible &&
                      isUpdateButtonVisible.key === item._id && (
                        <Button
                          onClick={() => {
                            checkValidationsAndUpdateOptionName(
                              editedExistingOptionName.name,
                              updateExistingFieldOption
                            );
                          }}
                          type="danger">
                          Update
                        </Button>
                      )}

                    {!isUpdateButtonVisible?.visible && (
                      <Button
                        onClick={() =>
                          setIsUpdateButtonVisible({
                            visible: true,
                            key: item._id,
                          })
                        }>
                        Edit
                      </Button>
                    )}
                  </Col>
                </Row>
              </List.Item>
            ))}
            {newOptionsAdded.map((item, key) => (
              <List.Item key={item.name} style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                  <Col span={14}>
                    <Row gutter={[4, 4]}>
                      <Col span={24}>
                        <Typography.Text
                          style={{ marginTop: "1px" }}
                          editable={
                            isUpdateButtonVisible.visible &&
                            isUpdateButtonVisible.key === key
                              ? {
                                  maxLength: 30,
                                  editing: true,
                                  onChange: (value) => {
                                    setEditedNewOptionName({
                                      name: value,
                                      key: item._id,
                                    });
                                  },
                                }
                              : false
                          }>
                          {editedNewOptionName?.key === item._id &&
                          editedNewOptionName
                            ? editedNewOptionName.name
                            : item.name}
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        {isOptionWithValue && (
                          <Tag style={{ marginRight: "10px" }} disabled={true}>
                            Value:{item.value}
                          </Tag>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={10}>
                    {isUpdateButtonVisible.visible &&
                      isUpdateButtonVisible.key === key && (
                        <Button
                          style={{ marginRight: "10px" }}
                          icon={<ReloadOutlined />}
                          onClick={() => {
                            clearPickListOptions();
                          }}
                          type="blue"></Button>
                      )}
                    {isUpdateButtonVisible.visible &&
                      isUpdateButtonVisible.key === key && (
                        <Button
                          onClick={() => {
                            checkValidationsAndUpdateOptionName(
                              editedNewOptionName.name,
                              updateNewAddedFieldOption,
                              item
                            );
                          }}
                          type="danger">
                          Update
                        </Button>
                      )}
                    {!isUpdateButtonVisible?.visible && (
                      <Button
                        onClick={() =>
                          setIsUpdateButtonVisible({
                            visible: true,
                            key: key,
                          })
                        }>
                        Edit
                      </Button>
                    )}
                  </Col>
                </Row>
              </List.Item>
            ))}
          </List>
        ) : (
          ""
        )}
      </Drawer>
    </div>
  );
};

export default PickListDrawer;

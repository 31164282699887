import { Card, Cascader, Checkbox, Col, Form, Row, Select, TimePicker } from 'antd'
import moment from 'moment'
import React from 'react'

const RuleCardComponent = ({ rule, format, groups, division, objectName, disabled, ruleName, form ,timePickerProps }) =>
{
    return (
        <>
            <Card
                bodyStyle={
                    (rule?.isActive && disabled) ?
                        {
                            backgroundColor: "white"
                        } : {
                            display: "none",
                            backgroundColor: "white"
                        }}
                className="subRuleSLA"

                size="small"
                title={
                    <div>
                        <Form.Item
                            label=""
                            name={[objectName, "isActive"]}
                            className="formItemBottomMargin"
                            valuePropName="checked"
                        >
                            <Checkbox
                                style={{ width: "100%" }}
                                disabled={!disabled}
                                onChange={(e) =>
                                {

                                    if (!e.target.checked)
                                    {
                                        if (objectName === "rule1")
                                        {
                                            form.setFieldsValue({
                                                rule2: { isActive: false }, rule3: { isActive: false },
                                            })
                                        } else
                                        {
                                            form.setFieldsValue({ rule3: { isActive: false }, })
                                        }

                                    }
                                }}
                            >
                                {ruleName}
                            </Checkbox>
                        </Form.Item>
                    </div>
                }>
                {/* Rule 1 Content is Here */}
                {
                    (rule?.isActive && disabled) && (
                        <Row Row gutter={[16, 16]}>
                            <Col span={4}>
                                <Form.Item
                                    label="Duration"
                                    name={[objectName, "duration"]}
                                    className="formItemBottomMargin"
                                    rules={[{ required: true },]}
                                >
                                    <TimePicker
                                        defaultValue={moment('00:00:23', 'HH:mm')}
                                        showNow={false}
                                        {...timePickerProps}
                                        style={{ width: "100%" }}
                                        format={format} />
                                </Form.Item>
                            </Col>
                            <Col span={6} >
                                <Form.Item
                                    label="Choose Type"
                                    name={[objectName, "typeOfAssignee"]}
                                    className="formItemBottomMargin"
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        options={[
                                            {
                                                label: "Group",
                                                value: "group"
                                            },
                                            {
                                                label: "Agent",
                                                value: "agent"
                                            }
                                        ]}
                                        placeholder="Select Type Of Assignee"
                                    />
                                </Form.Item>
                            </Col>

                            {
                                rule?.typeOfAssignee === "group" &&
                                <Col span={6}>
                                    <Form.Item
                                        label="Group Only"
                                        name={[objectName, "group"]}
                                        className="formItemBottomMargin"
                                        rules={[{ required: true }]}
                                    >
                                        <Select

                                            options={groups}
                                            placeholder="Select Group"
                                        />
                                    </Form.Item>


                                </Col>
                            }  {
                                rule?.typeOfAssignee === "agent" &&
                                <Col span={8}>
                                    <Form.Item
                                        label="Group/Agent"
                                        name={[objectName, "assignTo"]}
                                        className="formItemBottomMargin"
                                        rules={[{ required: true }]}
                                    >
                                        <Cascader
                                            changeOnSelect
                                            options={division}
                                            placeholder="Please select"
                                        />
                                    </Form.Item>


                                </Col>
                            }
                        </Row>
                    )
                }
            </Card>
        </>
    )
}

export default RuleCardComponent
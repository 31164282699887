import { apiSlice } from "../../../apiSlice";
export const emailTriggersApi = apiSlice.injectEndpoints({
    // tagTypes: ["Role"],
    endpoints: (builder) => ({
      
      updateTriggerSetting: builder.mutation({
        query: ({ ...rest }) => ({
          url: "/emailtriggers/updatetriggersetting",
          method: "PATCH",
          body: rest,
        }),
        invalidatesTags: ["Triggers"],
      }),
      getTriggerSettings: builder.query({
        query: () => ({
          url: `/emailtriggers/gettriggersetting`,
          method: "GET",
        }),
        providesTags: ["Triggers"],
      }),
    }),
  });

  export const {
      useGetTriggerSettingsQuery,
      useUpdateTriggerSettingMutation
  } = emailTriggersApi
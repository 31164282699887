/** @format */

import { apiSlice } from "../../../apiSlice";
export const customFieldsOrderApi = apiSlice.injectEndpoints({
  //tagTypes: ["Field"],
  endpoints: (builder) => ({
    updateCustomFieldsOrder: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/setting/updateCustomFieldsOrder",
        method: "PATCH",
        body: rest,
      }),
      //   invalidatesTags: ["Field"],
    }),
  }),
});

export const { useUpdateCustomFieldsOrderMutation } = customFieldsOrderApi;

import React from "react";
import { List,  Row, } from "antd";
import AvatarComponent from "../../appComponents/AvatarComponent";
import moment from "moment";
import { useSlaHistoryForTicketQuery } from "../../../redux/services/settings/SLA/SLAapi";
import { toast } from "react-toastify";

const HistoryContentSla = ({id,closeModal}) =>
{
  const { data: logsData, isLoading: isFetchingHistory, isError, error } = useSlaHistoryForTicketQuery({ _id: id })      
  if (isError)
  { 
    toast.error("Some Error Occurred");
    console.log("Error in fetching SLA ", error)
    closeModal()
  }
  return (
   
       <List
          loading={isFetchingHistory}
          itemLayout="horizontal"          
          dataSource={logsData || []}
          renderItem={(log) => (
              
              <List.Item  className="capitalizeText">
              <List.Item.Meta
                title={
                  <Row gutter={16} >                 
          <Row justify="start" align="middle" gutter={10}>
    
      
    <div className="logMain" >
      <AvatarComponent
            size="medium"
            avatarStyle={{
              backgroundColor: "green",
              border: "1px solid darkGrey",
            }}
            user={{firstname:(log.createdBy?.firstName ? log.createdBy?.firstName : ""), lastname:(log?.createdBy?.lastName ? log?.createdBy?.lastName : "")}}
          />

                  <div>
                    <div className="date"><div
                        style={{
                          fontSize: "10px",
                          color: "#72abdb",
                          textTransform: "lowercase",
                        }}>
                        {moment(log.createdOn).fromNow()}
                        </div>
                       </div>
                      <div className="name">{((log.createdBy && Object.keys(log.createdBy).length ===0)?  "Anonymous" :log.createdBy?.firstName ? log.createdBy?.firstName : "" )+ " " + ((log.createdBy && Object.keys(log.createdBy).length ===0)? "":log.createdBy?.lastName ? log.createdBy?.lastName : "")} <span style={{color:"#72abdb"}}>{log.title}</span> </div>
                    </div>
                  </div>
  
                    </Row>
                    
                  </Row>
                }
                description={
                  <>
                    {log.description.split('<br/>').map((st,i)=>{
                      if(i==0){
                        return <p style={{"marginBottom": "6px",fontSize:"12px",marginLeft:"3.2rem"}}>{st}</p>
                      }
                      return <p style={{fontSize:"12px",marginLeft:"3.2rem"}}>{st}</p>
                    })}
                    
                  </>
                }
              />
            </List.Item>
          )}
        /> 
  
  )
}

export default HistoryContentSla
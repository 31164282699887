/** @format */

import { apiSlice } from "../apiSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/login",
        method: "POST",
        body: rest,
      }),
    }),
    logOut: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/logOut",
        method: "POST",
        body: rest,
      }),
    }),
  }),
});

export const {
  useLogOutMutation,
  useLoginMutation }
  = authApi;

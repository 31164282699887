/** @format */

import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Col,  Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import CreateTicketDrawer from "../../../../component/drawers/ticket/createTicket/CreateTicketDrawer";
import FilterDrawer from "../../../../component/drawers/ticket/filter/FilterDrawer";
import { selectCurrentUser } from "../../../../redux/features/auth/authSlice";
import {
  selectAllFilters,
  setAllFilters,
} from "../../../../redux/features/filter/filterSlice";
import useUserPermissions from "../../../../utils/customHooks/permissionHooks/useUserPermissions";
const TicketsBoardHeader = ({
  refetchTickets,
  allTicketsData,
  isSuccessGetAllTicketsData,
  isFetchingGetAllTicketsData,
}) => {
  const user = useSelector(selectCurrentUser);
  const [isOpenFilterDrawer, setIsOpenFilterDrawer] = useState(false);
  const [isUnassignedButtonDisabled,setIsUnassignedButtonDisabled] = useState(false)
  let {
    statusFilter,
    deploymentFilter,
    ticketNumberFilter,
    ticketTypeFilter,
    assigneeFilter,
    ownerFilter,
  } = useSelector(selectAllFilters);
  const dispatch = useDispatch();
  //! Don't delete this code - it is for create Ticket Drawer
  // const [isOpenCreateTicketDrawer, setIsCreateTicketDrawer] = useState(false);
  // const closeCreateTicketDrawer = () => {
  //   setIsCreateTicketDrawer(false);
  // };

  const closeFilterDrawer = () => {
    setIsOpenFilterDrawer(false);
  };
  const anyFilterAvailable = () => {
    if (
      statusFilter.length ||
      deploymentFilter.length ||
      ticketNumberFilter.length ||
      ticketTypeFilter.length ||
      assigneeFilter.length ||
      ownerFilter.length
    ) {
      return true;
    } else {
      return false;
    }
  };
  const clearAllFilters = () => {
    dispatch(
      setAllFilters({
        statusFilter: [],
        deploymentFilter: [],
        ticketNumberFilter: [],
        ticketTypeFilter: [],
        assigneeFilter: [],
        ownerFilter: [],
      })
    );
  };

  useEffect(() =>
  {
    
    let assignee = assigneeFilter.find((assignee) =>
    { console.log(assignee)
      return assignee.value === "{}"})
  
    let owner = ownerFilter.find((assignee) => assignee.value === "{}")
    console.log("assignee",assignee)
    if (assignee || owner)
    {
      setIsUnassignedButtonDisabled(true)
    }
    else
    {
      setIsUnassignedButtonDisabled(false)
    }
  }, [assigneeFilter,ownerFilter])
  
  const activeFiltersText = () => {
    let text = "";
    const addPipe = () => {
      if (text !== "") {
        text = text + " | ";
      }
    };
    if (assigneeFilter.length) {
      addPipe();
      text = text + "Assignee";
    }
    if (ownerFilter.length) {
      addPipe();
      text = text + "Owner";
    }
    if (deploymentFilter.length) {
      addPipe();
      text = text + "Deployment";
    }
    if (ticketTypeFilter.length) {
      addPipe();
      text = text + "Type";
    }
    if (ticketNumberFilter.length) {
      addPipe();
      text = text + "TicketNo";
    }
    if (statusFilter.length) {
      addPipe();
      text = text + "Status";
    }
    return text;
  };
  const { hasPermission,userBelongsToDefaultDivision} = useUserPermissions()
  
  return (
    <div style={{ marginBottom: "20px" }}>
      <div>
        <Row gutter={[16, 16]}>
          <Col>
            <Button
              onClick={() => {
                refetchTickets();
              }}
              type="primary"
              icon={<ReloadOutlined />}
              loading={isFetchingGetAllTicketsData}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                clearAllFilters();
              }}
              type="danger"
              // icon={<ReloadOutlined />}
              disabled={!anyFilterAvailable()}>
              Clear Filters
            </Button>
          </Col>

          <Col>
            <Button
              onClick={() => {
                setIsOpenFilterDrawer(true);
              }}
              type="primary"
              icon={<FilterOutlined />}>
              Filters
            </Button>
          </Col>
          {(isSuccessGetAllTicketsData && allTicketsData?.totalUnassignedTickets && hasPermission("view_all_tickets") && userBelongsToDefaultDivision()) ?
            <Col>
              <Button
                disabled={isUnassignedButtonDisabled}
              className="custom-button-orange"  
                type="primary"
                onClick={() =>
                {
                  dispatch(
                  setAllFilters({                   
                    assigneeFilter: [...assigneeFilter,{label:"unassigned",value:"{}"}],
                    ownerFilter: [...ownerFilter,{label:"unassigned",value:"{}"}],
                  })
                 );
                }}
              //icon={<FilterOutlined />}
            >
              Unassigned: {allTicketsData?.totalUnassignedTickets}
            </Button>
          </Col>:<></>}
          <Col className="capitalizeText">
            {anyFilterAvailable() && (
              <Card
                style={{ border: "1px dashed green" }}
                bodyStyle={{
                  padding: "4px 15px",
                }}>
                Active Filters :{activeFiltersText()}
              </Card>
            )}
          </Col>
        </Row>
      </div>
      <FilterDrawer
        isOpenFilterDrawer={isOpenFilterDrawer}
        closeFilterDrawer={closeFilterDrawer}
        user={user}
      />
      {/* <CreateTicketDrawer
        isOpenCreateTicketDrawer={isOpenCreateTicketDrawer}
        closeCreateTicketDrawer={closeCreateTicketDrawer}
        query={query}
        refetch={refetch}
        setQuery={setQuery}
        setData={setData}
        user={user}
      /> */}
    </div>
  );
};

export default TicketsBoardHeader;

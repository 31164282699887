/** @format */

import { apiSlice } from "../../apiSlice";

export const ticketAssignRequest = apiSlice.injectEndpoints({
  tagTypes: ["Ticket Assignment"],
  endpoints: (builder) => ({
    ticketAssign: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/ticketAssignRequest/",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Ticket Assignment"],
    }),
    getAllTicketAssignment: builder.query({
      query: (paramsForGetAllTicketAssignment) => ({
        url: `/ticketAssignRequest/getAll/${paramsForGetAllTicketAssignment}`,
        method: "GET",
      }),
    }),
    updateTicketAssignment: builder.mutation({
      query: (body) => ({
        url: `/ticketAssignRequest/updateTicketAssignment/${body._id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    rejectTicketAssignment: builder.mutation({
      query: (body) => ({
        url: `/ticketAssignRequest/rejectTicketAssignment/${body._id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    checkTicketAssign: builder.mutation({
      query: ({ ...body }) => ({
        url: `/ticketAssignRequest/verifyTicketAssignment`,
        method: "POST",
        body: body,
      }),
    }),
    assignToOwner: builder.mutation({
      query: ({ ...body }) => ({
        url: `/ticketAssignRequest/assignToOwner`,
        method: "POST",
        body: body,
      }),
    }),
    recallTicket: builder.mutation({
      query: ({ ...body }) => ({
        url: `/ticketAssignRequest/recalled`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useTicketAssignMutation,
  useGetAllTicketAssignmentQuery,
  useUpdateTicketAssignmentMutation,
  useRejectTicketAssignmentMutation,
  useCheckTicketAssignMutation,
  useAssignToOwnerMutation,
  useRecallTicketMutation,
} = ticketAssignRequest;

/** @format */

import { Form, Input, Select, Spin, Tooltip } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useGetCustomFieldQuery } from "../../../../redux/services/customField/customFieldApi";

const PickListWithApiSelectBox = ({
  fieldLabelGenerator,
  field,
  ticketForm,
  tenantId,
  deployment,
  clearRelatedCustomFieldValues
}) =>
{
  const { data: customFields, isSuccess, isLoading } = useGetCustomFieldQuery();
  const [data, setData] = useState(null);
  const [isLoadingPickListData, setIsLoadingPickListData] = useState(false);
  let responseDataType = field?.pickListWithApiDetails?.responseDataType || "";
  let keyOfObject = field?.pickListWithApiDetails?.keyOfObject || "";
  let isArrayOfObjects = field?.pickListWithApiDetails?.isArrayOfObjects || "";
  let arrayOfObjectsKey =
    field?.pickListWithApiDetails?.arrayOfObjectsKey || "";
  let dependentFieldsFromPickList =
    field?.pickListWithApiDetails?.dependentFields;
  let dependentFields;
  let availableDependentFields;

  if (isSuccess && field.pickListWithApiDetails.isDependentRequired)
  {
    dependentFields = customFields.filter((field) =>
    {
      if (dependentFieldsFromPickList.includes(field._id))
      {
        return field;
      }
    });
    availableDependentFields = dependentFields.filter((field) =>
    {
      if (field.isActive && field.alias && field.alias !== "")
      {
        return field;
      }
    });
  }

  const formatData = () =>
  {
    return responseDataType === "array"
      ? isArrayOfObjects
        ? data?.data?.map((value) =>
        {
          return {
            value: value[arrayOfObjectsKey],
            label: value[arrayOfObjectsKey],
            quantity: value["quantity"] || "",
            unit: value?.unit?.unitName || ""
          };
        })
        : data?.data?.map((value) =>
        {
          return {
            value: value,
            label: value,
          };
        })
      : isArrayOfObjects
        ? data?.data[keyOfObject]?.map((value) =>
        {
          return {
            value: value[arrayOfObjectsKey],
            label: value[arrayOfObjectsKey],
            quantity: value["quantity"] || "",
            unit: value?.unit?.unitName || ""
          };
        })
        : data?.data[keyOfObject]?.map((value) =>
        {
          return {
            value: value,
            label: value,
          };
        }) || [];
  };

  // For Further Options

  // const [selectedOptions, setSelectedOptions] = useState(() => {
  //   if (ticketForm.getFieldsValue([field._id])) {
  //     return ticketForm.getFieldsValue([field._id]);
  //   } else {
  //     return [];
  //   }
  // });

  let selectedOptions = Form.useWatch(field._id, ticketForm);
  console.log(data, selectedOptions);
  const withoutDependent = async () =>
  {
    let queryValues = {};
    if (field.pickListWithApiDetails.typeOfApi === "posistApi")
    {
      if (field?.pickListWithApiDetails?.searchBasedOn === "deploymentId")
      {
        queryValues["deployment_id"] = deployment._id;
      } else
      {
        queryValues["tenant_id"] = tenantId;
      }
      if (field?.pickListWithApiDetails?.projections?.length)
      {
        let projections = {};
        field?.pickListWithApiDetails?.projections?.forEach((projection) =>
        {
          projections[projection] = 1;
        });
        queryValues["projections"] = projections;
      }
    }
    try
    {
      setIsLoadingPickListData(true);
      let response = await axios({
        url: field.pickListWithApiDetails?.url,
        method: field.pickListWithApiDetails?.method,
        token: field.pickListWithApiDetails?.token || null,
        params: { ...queryValues },
      });
      setIsLoadingPickListData(false);
      setData(response);
    } catch (error)
    {
      setIsLoadingPickListData(false);
      if (error.response.status === 404)
      {
        toast.error("Custom Field - API is not working perfectly ERROR - 404", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        console.log(
          "Custom Field - API is not working perfectly or details are wrong",
          error
        );
      } else
      {
        toast.error(
          error?.message
            ? error?.message
            : "Custom Field API's internal Server error",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
        console.log(
          "Custom Field with API has some internal server error",
          error
        );
      }
    }
  };
  const withDependent = async () =>
  {
    let ticketValuesOfForm = ticketForm.getFieldsValue();
    let valueAvailabilityChecks = [];
    let queryValues = {};
    dependentFields.forEach((dependentField) =>
    {
      if (!ticketValuesOfForm[dependentField._id])
      {
        toast.error(`Please enter values in ${ dependentField.name }`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else
      {
        valueAvailabilityChecks.push(dependentField);
        if (dependentField.typeOfField === "pickList")
        {
          queryValues[dependentField?.alias] = JSON.parse(
            ticketValuesOfForm[dependentField._id]
          ).value;
        } else
        {
          queryValues[dependentField?.alias] =
            ticketValuesOfForm[dependentField._id];
        }
      }
    });
    if (field.pickListWithApiDetails.typeOfApi === "posistApi")
    {
      if (field?.pickListWithApiDetails?.searchBasedOn === "deploymentId")
      {
        queryValues["deployment_id"] = deployment._id;
      } else
      {
        queryValues["tenant_id"] = tenantId;
      }
      if (field?.pickListWithApiDetails?.projections?.length)
      {
        let projections = {};
        field?.pickListWithApiDetails?.projections?.forEach((projection) =>
        {
          projections[projection] = 1;
        });
        queryValues["projection"] = projections;
      }
    }
    if (valueAvailabilityChecks.length === dependentFieldsFromPickList.length)
    {
      try
      {
        setIsLoadingPickListData(true);
        let response = await axios({
          url: field.pickListWithApiDetails?.url,
          method: field.pickListWithApiDetails?.method,
          token: field.pickListWithApiDetails?.token || null,
          params: { ...queryValues },
        });
        setIsLoadingPickListData(false);

        setData(response);
      } catch (error)
      {
        setIsLoadingPickListData(false);
        setData(null);
        if (error.response.status === 404)
        {
          toast.error(
            "Custom Field - API is not working perfectly ERROR - 404",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );
          console.log(
            "Custom Field - API is not working perfectly or details are wrong",
            error
          );
        } else if (error?.response?.data?.message)
        {
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Custom Field API's internal Server error",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );
          console.log("Custom Field with API has error with message", error);
        } else
        {
          toast.error(
            error?.message
              ? error?.message
              : "Custom Field API's internal Server error",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );
          console.log(
            "Custom Field with API has some internal server error",
            error
          );
        }
        console.log("error", error);
      }
    } else
    {
      setData(null);
    }
  };
  const props = {
    mode: "multiple",
    // onChange: (values) => {
    //   setSelectedOptions(values);
    // },
    allowClear: true,
    placeholder: `Enter ${ field.name }`,
    filterOption: (input, option) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
    options: formatData(),
  };
  if (field?.pickListWithApiDetails?.isDependentRequired)
  {
    //!With dependent
    if (availableDependentFields.length === dependentFields.length)
    {
      return (
        <>
          <Form.Item
            name={field._id}
            label={fieldLabelGenerator(field)}
            rules={[
              {
                required: field.isRequired,
                message: `${ field.name } is required!`,
              },
            ]}>
            <Select
              disabled={field?.isVisibleAtWidget}
              {...props}
              onFocus={withDependent}
              loading={isLoadingPickListData}
            />
          </Form.Item>
          {field?.pickListWithApiDetails
            ?.isRequireMultipleInputsAsPerSelection &&
            selectedOptions?.map((selectedOption, key) =>
            { 
              let selectedItem = formatData()?.find((item) => { return item.value === selectedOption })
              let quantity = field?.pickListWithApiDetails?.isRequireQuantityAndUnitsForStockApi
                && selectedItem && selectedItem["quantity"] ? selectedItem["quantity"] : ""
              let unitValue = (field?.pickListWithApiDetails?.isRequireQuantityAndUnitsForStockApi && selectedItem?.unit ? ((selectedItem?.unit
                //  + " | "
              ) || "") : "")
              let addonAfterValue =
                //unitValue +
                (field?.pickListWithApiDetails?.selectedOptionsLabelName || "")
              let quantityForView = quantity
                + (!!unitValue && " : ") + unitValue
              return (
                <Form.Item
                  key={key}
                  name={selectedOption}
                  rules={[
                    {
                      whitespace: true,
                      message: "Enter characters",
                    },
                  ]}
                  initialValue={quantityForView}
                >
                  <Input
                    disabled={field.isVisibleAtWidget}
                    addonBefore={
                      <Tooltip title={selectedOption || ""}>
                        <div style={{ textOverflow: "ellipsis", maxWidth: "120px", overflow: "hidden" }}>{selectedOption || ""}</div>
                      </Tooltip>
                    }
                    addonAfter={
                      <Tooltip title={addonAfterValue || ""}>
                        <div style={{ textOverflow: "ellipsis", maxWidth: "120px", overflow: "hidden" }}>{addonAfterValue || ""}</div>
                      </Tooltip>
                    }
                  />
                </Form.Item>
              );
            })}
        </>
      );
    }
  } else
  {
    //!Without dependent
    return (
      <>
        <Form.Item
          name={field._id}
          label={fieldLabelGenerator(field)}
          rules={[
            {
              required: field.isRequired,
              message: `${ field.name } is required!`,
            },
          ]}>
          <Select
            disabled={field.isVisibleAtWidget}
            {...props}
            onFocus={withoutDependent}
            loading={isLoadingPickListData}
          />
        </Form.Item>
        {field?.pickListWithApiDetails?.isRequireMultipleInputsAsPerSelection &&
          selectedOptions?.map((selectedOption, key) =>
          {

            let selectedItem = formatData()?.find((item) => { return item.value === selectedOption })
            let quantity = field?.pickListWithApiDetails?.isRequireQuantityAndUnitsForStockApi
              && selectedItem && selectedItem["quantity"] ? selectedItem["quantity"] : ""
            let unitValue = (field?.pickListWithApiDetails?.isRequireQuantityAndUnitsForStockApi && selectedItem?.unit ? ((selectedItem?.unit
              //  + " | "
            ) || "") : "")
            let addonAfterValue =
              //unitValue +
              (field?.pickListWithApiDetails?.selectedOptionsLabelName || "")
            let quantityForView = quantity
              + (!!unitValue && " : ") + unitValue
            return (
              <Form.Item
                key={key}
                name={selectedOption}
                rules={[
                  {
                    whitespace: true,
                    message: "Enter characters",
                  },
                ]}
                initialValue={quantityForView}
              >
                <Input
                  disabled={field.isVisibleAtWidget}
                  addonBefore={
                    <Tooltip title={selectedOption || ""}>
                      <div style={{ textOverflow: "ellipsis", maxWidth: "120px", overflow: "hidden" }}>{selectedOption || ""}</div>
                    </Tooltip>
                  }
                  addonAfter={
                    <Tooltip title={addonAfterValue || ""}>
                      <div style={{ textOverflow: "ellipsis", maxWidth: "120px", overflow: "hidden" }}>{addonAfterValue || ""}</div>
                    </Tooltip>
                  }
                />
              </Form.Item>
            );
          })}
      </>
    );
  }
};

export default PickListWithApiSelectBox;

import { apiSlice } from "../../../apiSlice";
export const ticketAssignmentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateTicketAssignmentSetting: builder.mutation({
      query: (body) => ({
        url: `/setting/updateTicketAssignment`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["ticket assignment setting"],
    }),
    getTicketAssignment: builder.query({
      query: () => ({
        url: `/setting/getTicketAssignment`,
        method: "GET",
      }),
      invalidatesTags: ["ticket assignment setting"],
    }),
  }),
});

export const {
  useUpdateTicketAssignmentSettingMutation,
  useGetTicketAssignmentQuery,
} = ticketAssignmentApi;

/** @format */

import { apiSlice } from "../../apiSlice";

export const typesApi = apiSlice.injectEndpoints({
  tagTypes: ["Types"],
  endpoints: (builder) => ({
    createType: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/type/create",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Types"],
    }),
    updateType: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/type/update",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Types"],
    }),
    deleteType: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/type/delete",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Types"],
    }),
    getTypes: builder.query({
      query: () => ({
        url: `/type/getAll`,
        method: "GET",
      }),
      providesTags: ["Types"],
    }),
  }),
});

export const {
  useGetTypesQuery,
  useCreateTypeMutation,
  useUpdateTypeMutation,
  useDeleteTypeMutation,
} = typesApi;

/** @format */
import React, { useState } from "react";
import { Breadcrumb, Layout } from "antd";
import { Outlet } from "react-router-dom";
import { NavBar, SideBar, FooterBar } from "./";
import ProfileDrawer from "../../component/drawers/ProfileDrawer";

import { selectCurrentUser } from "../../redux/features/auth/authSlice";
import { useSelector } from "react-redux";
import useWindowSize from "../../utils/customHooks/general/useWindowSize";

const { Content } = Layout;

export const MainFrame = ({ logOut }) => {
  const windowSize = useWindowSize();
  // var ticketData

  const [collapsed, setCollapsed] = useState(false);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);

  const showProfileDrawer = () => {
    setOpenProfileDrawer(true);
  };

  const [leftMarginForContent, setLeftMarginForContent] = useState({
    marginLeft: "200px",
  });

  return (
    <div>
      <Layout
        style={{
          minHeight: "100vh",
        }}>
        <NavBar showProfileDrawer={showProfileDrawer} />
        <Layout
          style={{
            marginTop: "65px",
          }}>
          <SideBar
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            setLeftMarginForContent={setLeftMarginForContent}
          />
          {!collapsed && windowSize.width < 500 ? (
            <div></div>
          ) : (
            <Layout className="site-layout" style={leftMarginForContent}>
              <Content
                style={{
                  margin: "7px 7px",
                }}>
                <div
                  className="site-layout-background"
                  style={{
                    padding: 20,
                    minHeight: "100%",
                  }}>
                  <Outlet />
                </div>
              </Content>
            </Layout>
          )}
        </Layout>
        {/* <FooterBar /> */}
      </Layout>

      <ProfileDrawer
        openProfileDrawer={openProfileDrawer}
        setOpenProfileDrawer={setOpenProfileDrawer}
        logOut={logOut}
      />
    </div>
  );
};

export default MainFrame;

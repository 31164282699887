/** @format */

import { Avatar, Tooltip } from "antd";
import React from "react";
import stringToColour from "../../utils/colorWithFirstName";

const AvatarComponent = ({ user, avatarStyle, size, tooltip = true }) => {
  return (
    <div>
      {tooltip ? (
        <Tooltip
          overlayInnerStyle={{ textTransform: "capitalize" }}
          title={
            user?.firstname
              ? user?.firstname + " " + user?.lastname
              : "Dummy Name"
          }
          placement="top">
          <Avatar size={size} style={{...avatarStyle,backgroundColor:stringToColour(user?.firstname ? user.firstname : "random")}}>
            {user?.firstname
              ? user?.firstname[0]?.toUpperCase() +
                user?.lastname[0]?.toUpperCase()
              : user?.firstName
              ? user?.firstName[0]?.toUpperCase() +
                user?.lastName[0]?.toUpperCase()
              : "DM"}
          </Avatar>
        </Tooltip>
      ) : (
        <Avatar size={size} style={{...avatarStyle,backgroundColor:stringToColour(user?.firstname ? user.firstname : "random" )}}>
          {user?.firstname
            ? user?.firstname[0]?.toUpperCase() +
              user?.lastname[0]?.toUpperCase()
            : user?.firstName
            ? user?.firstName[0]?.toUpperCase() +
              user?.lastName[0]?.toUpperCase()
            : "DM"}
        </Avatar>
      )}
    </div>
  );
};

export default AvatarComponent;

/** @format */

import { apiSlice } from "../../apiSlice";

export const statusApi = apiSlice.injectEndpoints({
  tagTypes: ["Status"],
  endpoints: (builder) => ({
    createStatus: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/status/create",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Status"],
    }),
    updateStatus: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/status/update",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Status"],
    }),
    deleteStatus: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/status/delete",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Status"],
    }),
    getStatus: builder.query({
      query: () => ({
        url: `/status/getAll`,
        method: "GET",
      }),
      providesTags: ["Status"],
    }),
  }),
});

export const {
  useGetStatusQuery,
  useCreateStatusMutation,
  useUpdateStatusMutation,
  useDeleteStatusMutation,
} = statusApi;

/** @format */

import { useEffect, useState } from "react";
import useWindowSize from "../general/useWindowSize";

export default function useResponsiveProfileDrawerSize() {
  const [drawerSize, setDrawerSize] = useState(720);
  const screenSize = useWindowSize();
  useEffect(() => {
    const screenWidth = screenSize.width;
    if (screenWidth < 330) {
      setDrawerSize(280);
    } else if (screenWidth < 420) {
      setDrawerSize(320);
    } else if (screenWidth < 600) {
      setDrawerSize(400);
    } else if (screenWidth < 700) {
      setDrawerSize(400);
    } else {
      setDrawerSize(400);
    }
  }, [screenSize.width, drawerSize]);

  return drawerSize;
}

function getRandomColor(name) {
    // get first alphabet in upper case
    const firstAlphabet = name?.charAt(0).toLowerCase() || 'a';
  
    // get the ASCII code of the character
    const asciiCode = firstAlphabet.charCodeAt(0);
  
    // number that contains 3 times ASCII value of character -- unique for every alphabet
    const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();
  
    var num = Math.round(0xffffff * parseInt(colorNum));
    var r = num >> 16 & 255;
    var g = num >> 8 & 255;
    var b = num & 255;
    // return {
    //   backgroundColor: 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)',
    // //   character: firstAlphabet.toUpperCase()
    // }
    return 'rgb(' + r + ', ' + g + ', ' + b + ', 1)' ;
  }

  var stringToColour = function(str) {
    var hash = 0;
    var stringFull
    for (var i = 0; i < str.length; i++) {
        stringFull = str.charCodeAt(i) || "e".charCodeAt(i)
        hash = stringFull + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

  export default stringToColour;
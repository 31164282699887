/** @format */

import { apiSlice } from "../../apiSlice";

export const HistoryApi = apiSlice.injectEndpoints({
  tagTypes: ["History"],
  endpoints: (builder) => ({
    getHistory: builder.query({
      query: (query) => ({
        url: `/logs/getLogs`,
        method: "GET",
        params: query,
      }),
    }),
  }),
});

export const { useGetHistoryQuery, useLazyGetHistoryQuery } = HistoryApi;

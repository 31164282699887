/** @format */

import { apiSlice } from "../../apiSlice";

export const AnalysisApi = apiSlice.injectEndpoints({
  tagTypes: ["Analysis"],
  endpoints: (builder) => ({
    // createComment: builder.mutation({
    //   query: ({ ...rest }) => ({
    //     url: "/comment/create",
    //     method: "POST",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["Comment"],
    // }),
    getDeployments: builder.query({
      query: () => ({
        url: "/analysis/getDeployments",
        method: "GET",
      }),
      providesTags: ["Analysis"],
    }),

    getTypeWise: builder.mutation({
      query: (body) => ({
        url: `/analysis/ticketTypeWise`,
        method: "POST",
        body: body,
      }),
    }),
    getAgentWise: builder.mutation({
      query: (body) => ({
        url: `/analysis/agentWise`,
        method: "POST",
        body: body,
      }),
    }),
    getDeploymentWise: builder.mutation({
      query: ({ ...body }) => ({
        url: "/analysis/deploymentWise",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetDeploymentsQuery,
  useGetTypeWiseMutation,
  useGetAgentWiseMutation,
  useGetDeploymentWiseMutation,
} = AnalysisApi;

/** @format */

import { apiSlice } from "../../apiSlice";

export const ticketApi = apiSlice.injectEndpoints({
  tagTypes: ["Ticket", "Analysis"],
  endpoints: (builder) => ({
    createTicket: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/ticket/create",
        method: "POST",
        body: rest,
        // headers: {
        //   // "Content-Type": "multipart/form-data",
        // },
      }),
      invalidatesTags: ["Ticket"],
    }),
    updateTicket: builder.mutation({
      query: ({ ...body }) => ({
        url: `/ticket/update/${body.id}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["Ticket", "Analysis"],
    }),
    updateTicketStatus: builder.mutation({
      query: ({ ...body }) => ({
        url: `/ticket/updateStatus/${body._id}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["Ticket", "Analysis"],
    }),
    deleteTicket: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/ticket/delete",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Ticket"],
    }),

    getAllTickets: builder.query({
      query: (query) => ({
        url: `/ticket/getAllTickets`,
        method: "GET",
        params: query,
      }),
      providesTags: ["Ticket"],
    }),
    getTicket: builder.query({
      query: (id) => ({
        url: `/ticket/getTicket/${id}`,
        method: "GET",
      }),
      //providesTags: ["Ticket"],
    }),
    getAttachmentById: builder.query({
      query: (id, attachment) => ({
        url: `/ticket/getAttachmentById/${id}`,
        method: "GET",
        body: attachment,
      }),
    }),
    updateAttachment: builder.mutation({
      query: (body) => ({
        url: `/ticket/updateAttachment`,
        method: "PATCH",
        body: body,
      }),
    }),
    getTicketById: builder.query({
      query: ({ ...id }) => ({
        url: `/ticket/getSingle/${id.id}`,
        method: "GET",
      }),
    }),
    verifyTicketOwner: builder.mutation({
      query: ({ ...body }) => ({
        url: `/ticket/verifyTicketOwner?id=${body._id}`,
        method: "POST",
      }),
    }),
    getTicketAnalysis: builder.query({
      query: (body) => ({
        url: `/analysis/ticketAnalysis`,
        method: "GET",
      }),
      providesTags: ["Analysis"],
    }),
  }),
});

export const {
  useGetTicketQuery,
  useGetTicketsMutation,
  useGetAllTicketsQuery,
  useGetAttachmentByIdQuery,
  useCreateTicketMutation,
  useUpdateTicketMutation,
  useDeleteTicketMutation,
  useUpdateAttachmentMutation,
  useUpdateTicketStatusMutation,
  useGetTicketByIdQuery,
  useVerifyTicketOwnerMutation,
  useGetTicketAnalysisQuery,
} = ticketApi;

/** @format */

import { apiSlice } from "../../apiSlice";

export const divisionApi = apiSlice.injectEndpoints({
  tagTypes: ["Division"],
  endpoints: (builder) => ({
    createDivision: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/division/create",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Division"],
    }),
    updateDivision: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/division/update",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Division"],
    }),
    deleteDivision: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/division/delete",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Division"],
    }),
    getDivisions: builder.query({
      query: () => ({
        url: `/division/getAll`,
        method: "GET",
      }),
      providesTags: ["Division"],
    }),
  }),
});

export const {
  useGetDivisionsQuery,
  useCreateDivisionMutation,
  useUpdateDivisionMutation,
  useDeleteDivisionMutation,
} = divisionApi;

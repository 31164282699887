/** @format */

import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import
{
  HomeOutlined,
  PicRightOutlined,
  AppstoreAddOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/features/auth/authSlice";
import
{
  reportsPermissions,
  settingsPermissions,
} from "../../utils/permissions/permissionVariable";
import useWindowSize from "../../utils/customHooks/general/useWindowSize";
import useUserPermissions from "../../utils/customHooks/permissionHooks/useUserPermissions";
const { Sider } = Layout;

const getItem = (label, key, icon, children) =>
{
  return {
    label,
    key,
    icon,
    children,
  };
};

const sideBarMenuitems = [
  getItem("Home", "/", <HomeOutlined />),
  getItem("Tickets", "/tickets", <PicRightOutlined />),
];

const SideBar = ({ setLeftMarginForContent, collapsed, setCollapsed }) =>
{
  const [selectedKeys, setSelectedKeys] = useState([]);
  let windowSize = useWindowSize();
  let location = useLocation();
  const navigate = useNavigate();
  const { hasAnyPermissionToSettings, hasAnyPermissionToReports, hasPermission } = useUserPermissions()
  useEffect(() =>
  {
    setSelectedKeys([location.pathname]);
  }, [location]);
  let ticketForSideBarMenu = [];
  let reportMenu = [];

  if (hasAnyPermissionToSettings())
  {
    let settingsArray = [];
    if (hasPermission(settingsPermissions.settingsStatus))
      settingsArray.push(getItem("Status", "/settings/status"));

    if (hasPermission(settingsPermissions.settingsTypes))
      settingsArray.push(getItem("Ticket Types", "/settings/types"));

    if (hasPermission(settingsPermissions.settingsCustomFields))
      settingsArray.push(getItem("Custom Fields", "/settings/customField"));

    if (hasPermission(settingsPermissions.settingsEmailTriggers))
      settingsArray.push(getItem("Emails", "/settings/emailtriggers"));

    if (hasPermission(settingsPermissions.settingsSLA))
      settingsArray.push(getItem("SLA", "/settings/SLA"));

    if (hasPermission(settingsPermissions.settingsMiscellaneous))
      settingsArray.push(getItem("Miscellaneous", "/settings/miscellaneous"));
    ticketForSideBarMenu = [
      getItem(
        "Settings",
        "/settings",
        <AppstoreAddOutlined />,
        settingsArray
      ),
    ];
  }

  if (hasAnyPermissionToReports())
  {
    let reportsArray = [];
    if (hasPermission(reportsPermissions.reportTickettypewise))
      reportsArray.push(
        getItem("Ticket Type Wise", "/analysis/ticketTypeWise")
      );
    if (hasPermission(reportsPermissions.reportAgentwise))
      reportsArray.push(getItem("Agent Wise", "/analysis/agentWise"));
    if (hasPermission(reportsPermissions.reportDeploymentwise))
      reportsArray.push(
        getItem("Deployment Wise", "/analysis/deploymentwise")
      );

    reportMenu = [
      getItem(
        "Analysis",
        "/analysis",
        <RiseOutlined />,
        reportsArray
      ),
    ];
  }

  const changeUIOnCollapse = (value) =>
  {
    if (collapsed === true)
    {
      setLeftMarginForContent({
        marginLeft: "200px",
      });
    } else
    {
      setLeftMarginForContent(null);
    }
    setCollapsed(value);
  };


  return (
    <div>
      <Sider
        style={
          !collapsed && {
            position: "fixed",
            height: "100vh",
          }
        }
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={collapsed}
        onBreakpoint={() => { }}
        onCollapse={(value) =>
        {
          changeUIOnCollapse(value);
        }}
      >
        <Menu
          defaultOpenKeys={["/users"]}
          selectedKeys={selectedKeys}
          defaultSelectedKeys={[location.pathname]}
          onClick={({ key }) =>
          {
            if (windowSize.width < 991)
            {
              changeUIOnCollapse(true);
            }
            navigate(key);
          }}
          theme="dark"
          items={[...sideBarMenuitems, ...ticketForSideBarMenu, ...reportMenu]}
          mode="inline"
        />
      </Sider>
    </div>
  );
};

export default SideBar;

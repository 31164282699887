/** @format */

import React, { useState } from "react";
import { Button, Modal, Input } from "antd";
import { toast } from "react-toastify";
const { TextArea } = Input;

export default function TicketRejectCommentModal({
  isLoadingUpdateTicketAssignment,
  setIsModalOpen,
  isModalOpen,
  updateTicketAssignment,
  refetch,
  record,
}) {
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (!comment || comment.trim() === "")
      return toast.error("Add comment for reject");

    var data = record;
    data["comment"] = comment;

    const response = await toast.promise(updateTicketAssignment(data).unwrap, {
      pending: { render: "Please wait...", autoClose: 2000 },
      success: {
        render: "Rejected SuccessFully",
        autoClose: 3000,
      },
      error: {
        render({ data }) {
          if (data?.data?.message) {
            return data.data.message;
          } else {
            return "some error occurred";
          }
        },
        autoClose: 5000,
      },
    });

    setIsModalOpen(false);
    setComment("");
    refetch();
  };

  const handleCancel = () => {
    setComment("");
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Add comment"
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={isLoadingUpdateTicketAssignment && { disabled: true }}
        onCancel={handleCancel}>
        <TextArea
          value={comment}
          placeholder="Enter Comment"
          rows={4}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </Modal>
    </>
  );
}

import { apiSlice } from "../../../apiSlice";
export const slaApis = apiSlice.injectEndpoints({
  tagTypes: ["SLA"],
  endpoints: (builder) => ({
    createSLA: builder.mutation({
      query: ({ ...body }) => ({
        url: "/SLA/createSLA",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["SLA"],
    }),
    getAllSLA: builder.query({
      query: () => ({
        url: "/SLA/getAll",
        method: "GET",
      }),
      providesTags: ["SLA"],
    }),
    updateSLA: builder.mutation({
      query: ({ ...body }) => ({
        url: "/SLA/updateSLA",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["SLA"],
    }),
    slaHistoryForTicket: builder.query({
      query: (query) => ({
        url: "SLA/slaTickets",
        method: "GET",
        params:query
      })
    }),
    deleteSla: builder.mutation({
      query: ({ ...body }) => ({
        url: `/SLA/deleteSLA/${ body.id }`,
        method: "DELETE",
      }),
      invalidatesTags: ["SLA"],
    }),
  }),
});

export const {
  useCreateSLAMutation,
  useGetAllSLAQuery,
  useUpdateSLAMutation,
  useDeleteSlaMutation,
  useSlaHistoryForTicketQuery
} = slaApis;

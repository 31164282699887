/** @format */

import { useGetStatusQuery } from "../../../redux/services/status/statusApi";

export default function useGenerateStatusName() {
  const { data: status, isSuccess: isStatusSuccess } = useGetStatusQuery();
  const generateStatusName = (_id) => {
    let statusDetails = status?.find((status) => {
      return status?._id === _id;
    });
    if (statusDetails) {
      return statusDetails?.name;
    } else {
      return _id;
    }
  };
  return generateStatusName;
}

/** @format */

import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    statusFilter: [],
    deploymentFilter: [],
    ticketNumberFilter: [],
    ticketTypeFilter: [],
    assigneeFilter: [],
    ownerFilter: [],
  },
  reducers: {
    setStatusFilter: (state, action) => {
      state.statusFilter = action.payload;
    },
    setAllFilters: (state, action) => {
      let {
        statusFilter,
        deploymentFilter,
        ticketNumberFilter,
        ticketTypeFilter,
        assigneeFilter,
        ownerFilter,
      } = action.payload;

      state.statusFilter = statusFilter ? statusFilter : [];
      state.deploymentFilter = deploymentFilter ? deploymentFilter.map((ele)=>{
        if(typeof ele === 'string'){
          ele = JSON.parse(ele);
          return {
            label:ele.name,
            value:JSON.stringify(ele)
          }
        }else{
          return ele;
        }
      }) : [];
      state.ticketNumberFilter = ticketNumberFilter ? ticketNumberFilter : [];
      state.ticketTypeFilter = ticketTypeFilter ? ticketTypeFilter : [];
      state.assigneeFilter = assigneeFilter ? assigneeFilter.map((ele)=>{
        if(typeof ele === 'string'){
          ele = JSON.parse(ele);
          return {
            label:ele.name,
            value:JSON.stringify(ele)
          }
        }else{
          return ele;
        }
      }) : [];
      state.ownerFilter = ownerFilter ? ownerFilter.map((ele)=>{
        if(typeof ele === 'string'){
          ele = JSON.parse(ele);
          return {
            label:ele.name,
            value:JSON.stringify(ele)
          }
        }else{
          return ele;
        }
      }) : [];
    },
  },
});

export const { setStatusFilter, setAllFilters } = filterSlice.actions;

export default filterSlice.reducer;
export const selectStatusFilter = (state) => state.filter.statusFilter;
export const selectAllFilters = (state) => state.filter;

/** @format */

import { Col, Row, Typography } from "antd";
import TicketRequestTable from "../../../../component/tables/ticketRequestTable/TicketRequestTable";

const { Title } = Typography;

const Home = () => {
  return (
    <>
      <div className="site-drawer-render-in-current-wrapper">
        <Row
          id="my-menu-container"
          className="homePageAnalysis"
          justify="space-between"
          style={{ width: "100%" }}></Row>

        <Row>
          <Col span={24}>
            <Title style={{ color: "#6d6d6d" }} level={4}>
              Ticket Requests
            </Title>
          </Col>
          <Col span={24}>
            <TicketRequestTable />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Home;

/** @format */

import { Button } from "antd";
import debounce from "lodash/debounce";
import React, { Component, useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

function Editor({
  template,
  selectedType,
  setTemplate,
  setTemplateBody,
  templateBody,
  refetch,
  updateTrigger,
  quillRef,
  setDisableBtns
}) {
  const [isOpen, setIsOpen] = useState(true);
  let modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
  ];
  const sendUpdateData = async () => {
    let tmp = JSON.parse(JSON.stringify(template))
    var idx= tmp.types.findIndex((val)=>val.keyValue == selectedType.keyValue)
    tmp.types[idx].body = templateBody;
    let res = await updateTrigger({
      ...tmp
    });
    setTemplate(tmp);
    if (res?.data) toast.success("Email trigger Updated");
    refetch();
  };
  const updateBody = (e, i) => {
    setTemplateBody(e);
    setIsOpen(false);
  };
  return (
    <div>
      <ReactQuill
       onFocus={()=>setDisableBtns(false)}
       onBlur={()=>setDisableBtns(true)}
        formats={formats}
        modules={modules}
        ref={quillRef}
        style={{
          marginTop: "15px",
          height: "300px",
          width:"55vw",
          marginBottom: "10px",
        }}
        theme="snow"
        value={templateBody}
        onChange={updateBody}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "50px",
        }}
      >
        <Button
          disabled={isOpen}
          type="primary"
          onClick={debounce(sendUpdateData, 600)}
        >
          Update
        </Button>
      </div>
    </div>
  );
}

export default Editor;

/** @format */

import { apiSlice } from "../../apiSlice";

export const roleApi = apiSlice.injectEndpoints({
  tagTypes: ["Role"],
  endpoints: (builder) => ({
    createRole: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/role/create",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Role"],
    }),
    updateRole: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/role/update",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Role"],
    }),
    deleteRole: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/role/delete",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Role"],
    }),
    getRoles: builder.query({
      query: () => ({
        url: `/role/getAll`,
        method: "GET",
      }),
      providesTags: ["Role"],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleApi;

/** @format */

import { Button, Col, Drawer, Row, Space, Form, Select } from "antd";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllFilters,
  setAllFilters,
} from "../../../../redux/features/filter/filterSlice";
import {
  useGetAllAgentQuery,
  useLazyAssigneeSearchQuery,
  useLazyOwnerSearchQuery,
} from "../../../../redux/services/agent/Agent";
import { useSearchDeploymentsMutation } from "../../../../redux/services/pos/PosApis";
import { useGetStatusQuery } from "../../../../redux/services/status/statusApi";
import { useGetTypesQuery } from "../../../../redux/services/types/typesApi";
import useResponsiveProfileDrawerSize from "../../../../utils/customHooks/responsiveDesign/useResponsiveProfileDrawerSize";
import SearchBoxWithDebouncing from "../../../appComponents/searchBoxWithDebouncing/SearchBoxWithDebouncing";

const FilterDrawer = ({ isOpenFilterDrawer, closeFilterDrawer, user }) => {
  const responsiveDrawerSize = useResponsiveProfileDrawerSize();
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [searchOwner] = useLazyOwnerSearchQuery();
  const [searchAssignee] = useLazyAssigneeSearchQuery();
  const { data: agentsListData } = useGetAllAgentQuery();
  const { data: statusData } = useGetStatusQuery();
  const { data: typeData } = useGetTypesQuery();
  const [searchDeployments] = useSearchDeploymentsMutation();
  const allFilters = useSelector(selectAllFilters);
  let agentsList = [];
  let statusList = [];
  let types = [];

  if (agentsListData?.length) {
    agentsList = agentsListData.map((x) => {
      if (user._id === x._id) return { label: "ME", value: x._id };
      else return { label: x.firstname, value: x._id };
    });
    agentsList.push({ label: "Unassigned", value: "unassigned" });
  }
  if (statusData?.length) {
    statusList = statusData.map((x) => {
      return { label: x.name, value: x._id };
    });
  }
  if (typeData?.length) {
    types = typeData.map((x) => {
      return { label: x.name, value: x.name };
    });
  }

  const applyAllFilters = (values) => {
    dispatch(setAllFilters(values));
    closeFilterDrawer();
  };

  useEffect(() => {
    if (isOpenFilterDrawer) {
      console.log('allfilters',allFilters)
      filterForm.setFieldsValue({
        ...allFilters,
        deploymentFilter: allFilters?.deploymentFilter.map((ele)=>{
          return {
            label:ele.label,
            value:ele.value
          }
        }),
        assigneeFilter: allFilters?.assigneeFilter.map((ele)=>{
          return {
            label:ele.label,
            value:ele.value
          }
        }),
        ownerFilter: allFilters?.ownerFilter.map((ele)=>{
          return {
            label:ele.label,
            value:ele.value
          }
        }),
      });
    }
  }, [isOpenFilterDrawer]);

  return (
    <>
      <Drawer
        forceRender
        title="Filters for Tickets"
        width={responsiveDrawerSize}
        onClose={closeFilterDrawer}
        open={isOpenFilterDrawer}
        footer={
          <Row justify="right" gutter={16}>
            <Col
              xxs={{ span: 14, offset: 6 }}
              xs={{ span: 12, offset: 10 }}
              sm={{ span: 6, offset: 12 }}
              md={{ span: 6, offset: 12 }}
              lg={{ span: 6, offset: 12 }}
              xl={{ span: 6, offset: 12 }}
              xxl={{ span: 6, offset: 12 }}
            >
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    filterForm.submit();
                  }}
                >
                  Apply
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    console.log("Hello");
                    filterForm.resetFields();
                  }}
                >
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        }
      >
        <Form
          className="capitalizeText"
          form={filterForm}
          layout="vertical"
          onFinish={applyAllFilters}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Ticket Number" name="ticketNumberFilter">
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Tags Mode"
                  onChange={(values) => {
                    let length = values.length;
                    if (length) {
                      if (values[values.length - 1].trim() === "") {
                        filterForm.setFieldsValue({
                          ticketNumberFilter: values.slice(0, length - 1),
                        });
                      } else {
                        filterForm.setFieldsValue({
                          ticketNumberFilter: [
                            ...values.slice(0, length - 1),
                            values[values.length - 1].trim(),
                          ],
                        });
                      }
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Status" name="statusFilter">
                <Select
                  dropdownStyle={{ textTransform: "capitalize" }}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  options={statusList}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <SearchBoxWithDebouncing
                name="deploymentFilter"
                label="Deployments"
                placeholderValue="Enter deployment name (minium 3 Characters)"
                fetchFunction={searchDeployments}
                keyOfResponseToAccessDataOfArray="deployments"
                keyOfResponseForLabelOfOptions={"name"}
                keyOfResponseForValueOfOptions={"name"}
                searchOptionKeyName={"searchName"}
              />
            </Col>
            <Col span={24}>
              <Form.Item name="ticketTypeFilter" label="Ticket Type">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  options={types}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <SearchBoxWithDebouncing
                name="assigneeFilter"
                label="Assignees"
                placeholderValue="Enter assignee name (minium 3 characters)"
                fetchFunction={searchAssignee}
                keyOfResponseForLabelOfOptions={"name"}
                keyOfResponseForValueOfOptions={"name"}
                keyOfResponseToAccessDataOfArray="assignees"
              />
            </Col>
            <Col span={24}>
              <SearchBoxWithDebouncing
                name="ownerFilter"
                label="Owners"
                placeholderValue="Enter owner name (minium 3 characters)"
                fetchFunction={searchOwner}
                keyOfResponseForLabelOfOptions={"name"}
                keyOfResponseForValueOfOptions={"name"}
                keyOfResponseToAccessDataOfArray="owners"
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default FilterDrawer;

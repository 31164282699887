/** @format */

import { apiSlice } from "../../apiSlice";

export const customFieldApi = apiSlice.injectEndpoints({
  tagTypes: ["FIELD"],
  //just because of single word customField is being used as FIELD for tag name
  endpoints: (builder) => ({
    createCustomField: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/customField/create",
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["FIELD"],
    }),
    updateCustomFieldPickListValue: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/customField/updatePickListValue",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["FIELD"],
    }),
    updateCustomField: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/customField/update",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["FIELD"],
    }),
    updateStatusOfCustomField: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/customField/updateStatus",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["FIELD"],
    }),
    deleteCustomField: builder.mutation({
      query: ({ ...rest }) => ({
        url: "/customField/delete",
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["FIELD"],
    }),
    getCustomField: builder.query({
      query: () => ({
        url: `/customField/getAll`,
        method: "GET",
      }),
      providesTags: ["FIELD"],
    }),
    getSingleCustomField: builder.query({
      query: () => ({
        url: `/customField/getSingle`,
        method: "GET",
      }),
      providesTags: ["FIELD"],
    }),
  }),
});

export const {
  useGetCustomFieldQuery,
  useGetSingleCustomFieldQuery,
  useCreateCustomFieldMutation,
  useUpdateCustomFieldMutation,
  useDeleteCustomFieldMutation,
  useUpdateCustomFieldPickListValueMutation,
  useUpdateStatusOfCustomFieldMutation,
} = customFieldApi;

// const config = require("./config/environment");


let environmentDefaults = {
    //Environment
    baseUrl: process.env.baseUrl || "/api",
    //baseUrl: process.env.baseUrl || "http://localhost:3010/api",
   
};
  
export default environmentDefaults


// export const baseUrl = "http://localhost:3010";
//export const baseUrl = "http://18.140.214.202:3001";

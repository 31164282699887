/** @format */

import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, List, Row, Spin, Switch, Tooltip } from "antd";
import React, { useState } from "react";
import {
  useUpdateMiscellaneousMutation,
  useGetMiscellaneousQuery,
} from "../../../../redux/services/settings/miscellaneous/Miscellaneous";
import { toast } from "react-toastify";

const MiscellaneousSetting = () => {
  const { data: miscellaneousSettings, isSuccess,isLoading } = useGetMiscellaneousQuery();

  const [updateMiscellaneous] = useUpdateMiscellaneousMutation();
  // const [update,setUpdate] = useState({})
  const updateSetting = async (values) => {
    // if (!value) return;

    let updateValues = {
      _id: values._id,
      value: values.value,
      settingValue: values.settingValue,
    };
    let res = await updateMiscellaneous(updateValues);
    
    if (res?.data) return toast.success("Setting updated");
    else return toast.error("error in setting update");
  };
  if (isLoading)
  {
    return (<div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        width: "100%",
      }}
    >
      <Spin size="default"/>
    </div>)
  }
  else
  return (
    <div>
      <Row gutter={(16, 16)}>
        {miscellaneousSettings?.map((setting, i) => {
          return (
            <Col
              key={i}
              style={{ marginBottom: "20px" }}
              // span={8}
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <List
                header={
                  <div style={{ fontWeight: "bolder" }}>Widget Settings</div>
                }
                bordered
              >
                <List.Item>
                  <Row style={{ height: "100%", width: "100%" }}>
                    <Col span={18}>
                      <span style={{ textTransform: "capitalize" }}>
                        {setting.name}
                      </span>
                      <Tooltip
                        // trigger="click"
                        title={setting.value.info ||""}
                      >
                        <InfoCircleOutlined
                          style={{ marginLeft: "4px", color: "gray" }}
                        />
                      </Tooltip>
                    </Col>
                    <Col span={6}>                      
                      <Switch
                        defaultChecked={setting.value.isMandatory}
                        onChange={(value) =>
                        { 
                
                          let updatedValues = JSON.parse(JSON.stringify(setting));
                          updatedValues.value.isMandatory = value
                          updateSetting({...updatedValues});
                        }}
                      />
                    </Col>
                  </Row>
                </List.Item>
              </List>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default MiscellaneousSetting;

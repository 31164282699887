/** @format */

import { Form, Select } from "antd";
import React from "react";

const PickListSelectBox = ({ field, fieldLabelGenerator, form, clearRelatedCustomFieldValues }) =>
{
  return (
    <Form.Item
      name={field?._id}
      label={fieldLabelGenerator(field)}
      rules={[
        {
          required: field?.isRequired,
          message: `${ field.name } is required!`,
        },
      ]}>
      <Select
        disabled={field.isVisibleAtWidget}
        className="capitalizeText"
        placeholder={"Enter " + field?.name}
        allowClear
        onChange={() =>
        {
          clearRelatedCustomFieldValues(field)
        }}
        options={field?.pickListValues.map((value) =>
        {
          return {
            value: field.isOptionWithValue
              ? JSON.stringify({
                _id: value._id,
                value: value.value,
              })
              : value._id,
            label: value.name,
          };
        })}
      />
    </Form.Item>
  );
};

export default PickListSelectBox;

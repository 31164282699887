/** @format */

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import {
  useGetAllTicketAssignmentQuery,
  useUpdateTicketAssignmentMutation,
  useRejectTicketAssignmentMutation,
  useRecallTicketMutation,
} from "../../../redux/services/ticketAssignRequest/TicketAssignRequest";
import TicketRejectCommentModal from "../../modals/TicketRejectCommentModal";
import { toast } from "react-toastify";
import ViewTicketModal from "../../modals/ViewTicketModal";
import { useNavigate } from "react-router-dom";
import { useVerifyTicketOwnerMutation } from "../../../redux/services/ticket/ticketApi";
import { selectCurrentUser } from "../../../redux/features/auth/authSlice";
import { useSelector } from "react-redux";

const TicketRequestTable = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  let paramsForGetAllTicketAssignment = {
    pageSize: pageSize,
    pageNo: pageNo,
  };

  const {
    data: ticketAssignRequestsData,
    isSuccess,
    refetch,
    isLoading,
  } = useGetAllTicketAssignmentQuery(
    JSON.stringify(paramsForGetAllTicketAssignment)
  );
  const [
    updateTicketAssignment,
    { isLoading: isLoadingUpdateTicketAssignment },
  ] = useUpdateTicketAssignmentMutation();
  const [recalledTicket, { isLoading: isLoadingRecallTicket }] =
    useRecallTicketMutation();
  const [verifyTicket] = useVerifyTicketOwnerMutation();
  const [disableAccept, setDisableAccept] = useState(false);
  const [record, setRecord] = useState("");
  const [ticket, setTicket] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(ticketAssignRequestsData, "get all ticket assign ");
  const navigate = useNavigate();
  var user = useSelector(selectCurrentUser);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  if (ticketAssignRequestsData) {
    var ticketAssignRequests =
      ticketAssignRequestsData.ticketAssignRequests.map(
        (ticketAssignRequest, key) => {
          return {
            key: key,
            title: ticketAssignRequest.ticketDetails.ticketTitle,
            status: ticketAssignRequest.ticketDetails?.status,
            ticketNumber: ticketAssignRequest.ticketDetails.ticketNumber,
            ticketId: ticketAssignRequest.ticketDetails.ticketId,
            deployment: ticketAssignRequest.deployment.name,
            requestFrom: ticketAssignRequest.assignedFrom,
            requestFromName: ticketAssignRequest.assignedFrom.firstName +" "+ ticketAssignRequest.assignedFrom.lastName,
            requestToName: ticketAssignRequest.assignedTo.firstName +" "+ ticketAssignRequest.assignedTo.lastName,
            requestTo:ticketAssignRequest.assignedTo,
            _id: ticketAssignRequest._id,
            ticketOwnerName:
              ticketAssignRequest.ticketDetails?.ticketOwner?.firstName +" "+ ticketAssignRequest.ticketDetails?.ticketOwner?.lastName,
            ticketOwner: ticketAssignRequest.ticketDetails?.ticketOwner,
            owner: ticketAssignRequest.owner,
            accepted: ticketAssignRequest.accepted,
            actionTaken: ticketAssignRequest.actionTaken,
            recalled: ticketAssignRequest?.recalled,
            onRejectComment: ticketAssignRequest?.onRejectComment,
          };
        }
      );
  }
  console.log(ticketAssignRequestsData, "ticketAssignRequests");
  const acceptTicket = async (record) => {
    setDisableAccept(true);
    // update ticket on call
    record.accepted = true;
    // console.log(record, "accepted record");
    const response = await toast.promise(
      updateTicketAssignment(record).unwrap,
      {
        //pending: { render: "Please wait...", autoClose: 2000 },
        success: {
          render: "Accepted SuccessFully",
          autoClose: 3000,
        },
        error: {
          render({ data }) {
            if (data?.data?.msg) {
              return data.data.msg;
            } else {
              return "some error occurred";
            }
          },
          autoClose: 5000,
        },
      }
    );
    setDisableAccept(false);

    refetch();
  };
  const rejectTicket = (record) => {
    console.log(record, "record");
    record.accepted = false;

    setRecord(record);
    setIsModalOpen(true);
    // return;
    // rejectTicketAssignment(record);
    // refetch();
  };
  const verifyTicketOwner = async (data, record) => {
    let res = await verifyTicket({ _id: data.id });

    if (!res?.data) return toast.error("something went wrong");
    // console.log(
    //   res?.data.owner._id === user._id,
    //   res?.data.assignee._id,
    //   "chkk"
    // );
    // return;
    if (
      res?.data.owner._id === user._id ||
      res?.data.assignee._id == user._id
    ) {
      setTicket({ id: data.id });
      if (record.accepted) return navigate(`/tickets/${data.id}`);
    } else if (record.actionTaken) {
      return toast.error("You can't access this ticket");
    } else return setIsTicketModalOpen(true);
    console.log("heree");
  };
  const recallTicket = async (record) => {
    if (!record) return toast.error("Record not found");
    let res = await recalledTicket({ _id: record._id });
    refetch();
    if (res?.data) return toast.success("Ticket recalled");

    return toast.error(res?.error?.data?.message);
  };
  const columns = [
    {
      title: "Ticket No.",
      dataIndex: "ticketNumber",
      align: "center",
    },
    {
      title: "Deployment",
      dataIndex: "deployment",
      align: "center",
    },
    {
      title: "Request Type",
      dataIndex: "owner",
      align: "center",
      render: (record) => (
        <>{record?.firstName ? <p>Owner</p> : <p>Assignee</p>}</>
      ),
    },
    {
      title: "Owner",
      dataIndex: "ticketOwnerName",
      align: "center",
    },
    {
      title: "Request From",
      dataIndex: "requestFromName",
      align: "center",
    },
    {
      title: "Request To",
      dataIndex: "requestToName",
      align: "center",
    },

    {
      title: "status",
      key: "status",
      align: "center",
      render: (record) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            {record.accepted ? (
              <p
                style={{
                  background: "Green",
                  width: "fit-content",
                  padding: "3px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  color: "white",
                }}>
                Accepted
              </p>
            ) : (
              ""
            )}
            {!record.accepted && !record.recalled && record.actionTaken ? (
              <p
                style={{
                  background: "red",
                  width: "fit-content",
                  padding: "3px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  color: "white",
                }}>
                Rejected
              </p>
            ) : (
              ""
            )}
            {!record.actionTaken ? (
              <p
                style={{
                  background: "red",
                  width: "fit-content",
                  padding: "3px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  color: "white",
                }}>
                Pending
              </p>
            ) : (
              ""
            )}
            {record.actionTaken && record.recalled ? (
              <p
                style={{
                  background: "red",
                  width: "fit-content",
                  padding: "3px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  color: "white",
                }}>
                Recalled
              </p>
            ) : (
              ""
            )}
          </div>
          {/* ) : (
            <Space size="small">
              <Tooltip title="Accept">
                <Button
                  type="primary"
                  onClick={(e) => acceptTicket(record)}
                  icon={<CheckOutlined />}
                ></Button>
              </Tooltip>
              <Tooltip title="Reject">
                <Button
                  type="danger"
                  onClick={(e) => rejectTicket(record)}
                  icon={<CloseOutlined />}
                ></Button>
              </Tooltip>
            </Space>
          )} */}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <>
          {record.requestFrom.userId === user?._id ? (
            <Space size="small">
              <Tooltip>
                <Button
                  disabled={
                    record.recalled ||
                    record.recalled === undefined ||
                    record.actionTaken
                  }
                  type="primary"
                  onClick={(e) => recallTicket(record)}
                  // icon={<CheckOutlined />}
                >
                  Recall
                </Button>
              </Tooltip>
            </Space>
          ) : (
            <Space size="small">
              <Tooltip>
                <Button
                  disabled={record.actionTaken }
                  type="primary"
                  onClick={(e) => acceptTicket(record)}
                  icon={<CheckOutlined />}></Button>
              </Tooltip>
              <Tooltip>
                <Button
                  disabled={record.actionTaken }
                  type="danger"
                  onClick={(e) => rejectTicket(record)}
                  icon={<CloseOutlined />}></Button>
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
    {
      title: "Description",
      key: "show",
      align: "center",
      render: (record) => (
        <>
          <Button
            disabled={!record.accepted && record.actionTaken}
            onClick={() => {
              console.log(record, "on click show");
              if (record.accepted)
                verifyTicketOwner({ id: record.ticketId }, record);
              else {
                console.log(record, "record in else in showbutton");
                setTicket({ id: record.ticketId });
                setIsTicketModalOpen(true);
              }
            }}>
            Show
          </Button>
        </>
      ),
    },
  ];
  const tableProps = {
    size: "middle",
    showHeader: true,
  };
  return (
    <>
      <Table
        {...tableProps}
        size="small"
        className="capitalizeText"
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: false,
          defaultPageSize: pageSize,
          onChange: (page) => {
            setPageNo(page);
          },
          defaultCurrent: pageNo,
          total: isSuccess
            ? ticketAssignRequestsData?.ticketAssignRequestsCount
            : pageSize,
          hideOnSinglePage: true,
        }}
        showHeader={true}
        loading={isLoading ||  isLoadingRecallTicket ||disableAccept}
        columns={columns}
        dataSource={isSuccess ? ticketAssignRequests : []}
        scroll={{ y: 400, x: 1024 }}
        rowClassName={(record) => record.isDeleted && "user-table-disabled-row"}
      />
      <TicketRejectCommentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // rejectTicketAssignment={rejectTicketAssignment}
        isLoadingUpdateTicketAssignment={isLoadingUpdateTicketAssignment}
        updateTicketAssignment={updateTicketAssignment}
        refetch={refetch}
        record={record}
      />
      <ViewTicketModal
        isTicketModalOpen={isTicketModalOpen}
        setIsTicketModalOpen={setIsTicketModalOpen}
        ticket={ticket}
      />
    </>
  );
};

export default TicketRequestTable;
